import React from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Link,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';

import Foto from '../Component/allCard/498Card';
import MiniFoto from '../Component/allCard/100Card';

export default function detailVideoLiveStreaming() {
  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundColor: '#d6261d',
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              <Link href='/Video & Live Streaming' color='inherit'>
                {' '}
                Video{' '}
              </Link>
              / Lemkari
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          spacing={3}
          style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}
        >
          <Grid item md={8}>
            <Foto
              tanggal='09 Juni 2020'
              judul='Lemkari Gelar Ghasuku dan Ujian Tingkatan Sabuk Hitam Se-Kalimantan di Kapuas'
            />
          </Grid>
          <Grid item md={4}>
            <Typography
              style={{ fontSize: 24, fontWeight: 'bold', paddingBottom: 20 }}
            >
              Video & Live Streaming Lainnya
            </Typography>
            <MiniFoto
              judul='Mempora Membuka Kejuaraan Karate di Bali'
              tanggal='27 Jan 2018'
            />
            <MiniFoto
              judul='Mempora Membuka Kejuaraan Karate di Bali'
              tanggal='27 Jan 2018'
            />
            <MiniFoto
              judul='Mempora Membuka Kejuaraan Karate di Bali'
              tanggal='27 Jan 2018'
            />
            <MiniFoto
              judul='Mempora Membuka Kejuaraan Karate di Bali'
              tanggal='27 Jan 2018'
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
