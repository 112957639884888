import React, { Component,Fragment } from "react";

/* Material UI import*/
import {
  Breadcrumbs,
  Link
} from "@material-ui/core";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { NavLink } from "react-router-dom";

class BreadCrumbs extends Component {

  constructor() {
    super();
    this.state = {
      
    };
  }

  render() {
    const { classes } = this.props
    
    return (
      <Fragment>
        <Breadcrumbs aria-label="breadcrumb" style={{marginBottom:20}}>
          <Link color={this.props.colorFirstLink} className={classes.txtTitle} component={NavLink} to={`${this.props.firstLink}`}>
            {this.props.firstName}
          </Link>
          <Link color={this.props.activeLink} className={classes.txtTitle} component={NavLink} to={`${this.props.secondLink}`}>
            {this.props.secondName}
          </Link>
          {/* <Link color={this.props.activeSecondLink} style={{fontSize:15}} aria-current="page" component={NavLink} to={`${this.props.thirdLink}`}>
            {this.props.thirdName}
          </Link> */}
        </Breadcrumbs>
      </Fragment>
    );
  }
}

BreadCrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 27,
    },
}
})

export default withStyles(styles)(BreadCrumbs);