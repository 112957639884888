import React, { useEffect, useState } from 'react';
// import './App.css';
import indonesiasvg from '../../indonesia.svg';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import _ from 'lodash';
import {
  Card,
  Typography,
  Button,
  Grid,
  Hidden,
  IconButton,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// Icon
import CloseIcon from '@material-ui/icons/Close';

// Gambar
import gambar from '../../Assets/Images/logo_papua.png';
import putih from '../../Assets/Images/putih.png';
import kuning from '../../Assets/Images/kuning.png';
import hijau from '../../Assets/Images/hijau.png';
import biru from '../../Assets/Images/biru.png';
import coklat from '../../Assets/Images/coklat.png';
import DAN1 from '../../Assets/Images/DAN 1.png';
import DAN2 from '../../Assets/Images/DAN 2.png';
import DAN3 from '../../Assets/Images/DAN 3.png';
import DAN4 from '../../Assets/Images/DAN 4.png';
import DAN5 from '../../Assets/Images/DAN 5.png';
import DAN6 from '../../Assets/Images/DAN 6.png';
import DAN7 from '../../Assets/Images/DAN 7.png';
import MSH from '../../Assets/Images/MSH.png';
import Karate from '../../Assets/Images/Karateka.png';

export default function Indonesia() {
  const [total_permohoan, settotal_permohoan] = useState(0);
  const [datamaps, setdatamaps] = useState([]);
  const [detailProvinsi, setdetailProvinsi] = useState({});
  const dataPermohonan = [
    {
      tag: 'ID-JA',
      name: 'Jambi',
      // value: Math.floor(Math.random() * Math.floor(100)),
      value: 50,
    },
    {
      tag: 'ID-JB',
      name: 'Jawa Barat',
      //   value: Math.floor(Math.random() * Math.floor(100)),
      value: 150,
    },
    {
      tag: 'ID-KI',
      name: 'Kalimantan Timur',
      //   value: Math.floor(Math.random() * Math.floor(100)),
      value: 70,
    },
    {
      tag: 'ID-KB',
      name: 'Kalimantan Barat',
      //   value: Math.floor(Math.random() * Math.floor(100)),
      value: 90,
    },
    {
      tag: 'ID-SN',
      name: 'Sulawesi Selatan',
      //   value: Math.floor(Math.random() * Math.floor(100)),
      value: 200,
    },
    {
      tag: 'ID-SB',
      name: 'Sumatera Barat',
      //   value: Math.floor(Math.random() * Math.floor(100)),
      value: 45,
    },
    {
      tag: 'ID-NT',
      name: 'Nusa Tenggara Timur',
      //   value: Math.floor(Math.random() * Math.floor(100)),
      value: 50,
    },
    {
      tag: 'ID-KB',
      name: 'Kalimantan Barat',
      //   value: Math.floor(Math.random() * Math.floor(100)),
      value: 170,
    },
    {
      tag: 'ID-LA',
      name: 'Lampung',
      //   value: Math.floor(Math.random() * Math.floor(100)),
      value: 165,
    },
  ];

  const colorFillSelector = [
    { value: 0, color: '#D6261D' }, // merah
    { value: 5, color: '#D6261D' },
    { value: 10, color: '#D6261D' },
    { value: 15, color: '#D6261D' },
    { value: 20, color: '#D6261D' },
    { value: 25, color: '#D6261D' },
    { value: 30, color: '#D6261D' },
    { value: 35, color: '#D6261D' },
    { value: 40, color: '#FCF305' }, // kuning
    { value: 45, color: '#FCF305' },
    { value: 50, color: '#FCF305' },
    { value: 55, color: '#FCF305' },
    { value: 60, color: '#FCF305' },
    { value: 65, color: '#FCF305' },
    { value: 70, color: '#FCF305' },
    { value: 75, color: '#219653' }, // hijau
    { value: 80, color: '#219653' },
    { value: 85, color: '#219653' },
    { value: 90, color: '#219653' },
    { value: 95, color: '#219653' },
    { value: 100, color: '#219653' },
  ];

  useEffect(() => {
    // dataPermohonan.map(item=>total_data = total_data+ item.value)
    var valuemap = _.map(dataPermohonan, 'value');
    var maxdata = _.max(valuemap);
    var totaldata = _.sum(valuemap);
    settotal_permohoan(totaldata);
    var data = dataPermohonan.map(item => {
      var rasio = parseInt((item.value / maxdata) * 100); // value / valueTertinggi * 100
      // 0 >= 30 > 5
      var color = colorFillSelector
        .filter(x => x.value >= rasio && rasio + 5 > x.value)
        .map(y => y.color)[0];
      console.log('color', rasio, color);
      return {
        tag: item.tag,
        name: item.name,
        value: item.value,
        persentage: parseInt((item.value / totaldata) * 100),
        rasio: rasio,
        color: color,
      };
    });
    console.log('total data', totaldata);
    console.log('max data', maxdata);
    setdatamaps(_.sortBy(data, 'persentage').reverse());
  }, []);

  function hideTooltip() {
    var tooltip = document.getElementById('tooltipProvinsi');
    tooltip.style.display = 'none';
  }

  function showTooltip(evt, text) {
    let tooltip = document.getElementById('tooltipProvinsi');

    var datadetail = datamaps.filter(x => x.tag === text).map(item => item);
    console.log('text', text, datadetail.length > 0 ? datadetail[0] : {});
    setdetailProvinsi(datadetail.length > 0 ? datadetail[0] : {});
    if (datadetail.length > 0) {
      tooltip.style.display = 'block';
      tooltip.style.left = evt.pageX + 10 + 'px';
      tooltip.style.top = evt.pageY + 10 + 'px';
    }
  }
  return (
    <>
      <Hidden mdDown>
        <div
          id='tooltipProvinsi'
          class='tooltip'
          display='none'
          style={{ position: 'absolute', display: 'none', width: '30%' }}
        >
          <Card>
            <CardHeader
              action={
                <IconButton onClick={hideTooltip} size='small'>
                  <CloseIcon style={{ color: 'white', paddingBottom: 6 }} />
                </IconButton>
              }
              title='PROFIL'
              style={{
                backgroundColor: '#D6261D',
                color: 'white',
                height: 40,
                fontSize: 12,
              }}
            />
            <CardContent>
              <Grid container>
                <Grid item md={6}>
                  <div style={{ paddingLeft: 30, paddingBottom: 3 }}>
                    <img src={gambar} style={{ width: '40%', height: 65 }} />
                  </div>

                  <Typography>{detailProvinsi.name}</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography style={{ fontWeight: 'bold' }}>MSH</Typography>
                  <Typography style={{ fontWeight: 'bold' }}>Cabang</Typography>
                  <Typography style={{ fontWeight: 'bold' }}>Dojo</Typography>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Karateka
                  </Typography>
                </Grid>
                <Grid item md={1}>
                  <Typography>: 50</Typography>
                  <Typography>: 50</Typography>
                  <Typography>: 50</Typography>
                  <Typography>: 50</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  paddingTop: 20,
                  textAlign: 'center',
                  marginBottom: -10,
                }}
              >
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md>
                  <img src={DAN1} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={DAN2} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={DAN3} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={DAN4} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={DAN5} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={DAN6} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={DAN7} style={{ width: 50, height: 50 }} />
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  paddingTop: 5,
                  textAlign: 'center',
                  marginBottom: -10,
                }}
              >
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
                <Grid item md>
                  <Typography>50</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  textAlign: 'center',
                  marginBottom: -20,
                }}
              >
                <Grid item md>
                  <img src={putih} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={kuning} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={hijau} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={biru} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item md>
                  <img src={coklat} style={{ width: 50, height: 50 }} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        {/* <div
          id='tooltipProvinsi'
          class='tooltip'
          display='none'
          style={{ position: 'absolute', display: 'none' }}
        >
          <div style={{ backgroundColor: '#CFCFCF' }}>
            <span style={{ padding: 5, fontSize: 18, fontWeight: 'bold' }}>
              {detailProvinsi.name}
            </span>
            <button onClick={hideTooltip}>Tutup</button>
          </div>
          <div style={{ padding: 5 }}>
            <p>Jumlah Karateka: {detailProvinsi.value} orang</p>
            <p>Sedang diproses : {detailProvinsi.persentage} orang</p>
            <p>Ditolak : 10 orang</p>rxr
                <p>Diterima :  10 orang</p>
                <p>Dalam perjalanan : 10 orang</p>
          </div>
        </div> */}
        <center>
          <SvgLoader path={indonesiasvg}>
            <SvgProxy selector={'path'} fill='#e4eff7' stroke='black' />
            {datamaps.map(item => {
              return (
                <SvgProxy
                  selector={'#' + item.tag}
                  fill={item.color}
                  stroke='black'
                />
              );
            })}
            <SvgProxy
              key={'path'}
              selector={'path'}
              onClick={e => {
                showTooltip(e, e.srcElement.id);
              }}
            />
          </SvgLoader>
        </center>
        <div
          style={{
            // paddingLeft: 50,
            paddingTop: 30,
            // paddingRight: 50,
          }}
        >
          <Grid container>
            <Grid item md={3} />
            <Grid item md={2}>
              <Grid container>
                <Grid item md={2}>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: '#219653',
                      borderRadius: 50,
                    }}
                  ></div>
                </Grid>
                <Grid item md={10}>
                  <Typography>Sangat Aktif</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2}>
              <Grid container>
                <Grid item md={2}>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: '#FCF305',
                      borderRadius: 50,
                    }}
                  ></div>
                </Grid>
                <Grid item md={10}>
                  <Typography>Aktif</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2}>
              <Grid container>
                <Grid item md={2}>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: '#D6261D',
                      borderRadius: 50,
                    }}
                  ></div>
                </Grid>
                <Grid item md={10}>
                  <Typography>Tidak Aktif</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3}></Grid>
          </Grid>
        </div>
      </Hidden>

      {/* // Map Mobile */}
      <Hidden smUp>
        <div
          style={{
            paddingBottom: 50,
            // marginTop: 80,
            width: '100%',
            backgroundColor: 'white',
            height: 630,
          }}
        >
          <div
            id='tooltipProvinsi'
            class='tooltip'
            display='none'
            style={{ position: 'absolute', display: 'none' }}
          >
            <div style={{ backgroundColor: '#CFCFCF' }}>
              <span style={{ padding: 5, fontSize: 18, fontWeight: 'bold' }}>
                {detailProvinsi.name}
              </span>
              <button onClick={hideTooltip}>Tutup</button>
            </div>
            <div style={{ padding: 5 }}>
              <p>Jumlah Karateka: {detailProvinsi.value} orang</p>
              {/* <p>Sedang diproses : {detailProvinsi.persentage} orang</p> */}
              {/* <p>Ditolak : 10 orang</p>
                <p>Diterima :  10 orang</p>
                <p>Dalam perjalanan : 10 orang</p> */}
            </div>
          </div>
          <center>
            <SvgLoader path={indonesiasvg} width='100' height='100'>
              <SvgProxy selector={'path'} fill='#e4eff7' stroke='black' />
              {datamaps.map(item => {
                return (
                  <SvgProxy
                    selector={'#' + item.tag}
                    fill={item.color}
                    stroke='black'
                  />
                );
              })}
              <SvgProxy
                key={'path'}
                selector={'path'}
                onClick={e => {
                  showTooltip(e, e.srcElement.id);
                }}
              />
            </SvgLoader>
          </center>
          <Grid container>
            <Grid item md={10}>
              <div style={{ paddingLeft: 40, textAlign: 'left' }}>
                <Card
                  style={{
                    padding: 20,
                    width: 500,
                    height: 228,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 24,
                      fontWeight: 'bold',
                      paddingBottom: 10,
                    }}
                  >
                    Pengurus Provinsi (PengProv) Lemkari di Indonesia
                  </Typography>
                  <Typography style={{ fontSize: 14, paddingBottom: 20 }}>
                    Lemkari terdapat dibeberapa Provinsi di Indonesia. Lihat
                    data lengkap dan perkembangannya.
                  </Typography>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      style={{
                        width: 328,
                        height: 48,
                        backgroundColor: '#F58634',
                        color: 'white',
                      }}
                      href='/Statistik'
                    >
                      <Grid container style={{ paddingTop: 5 }}>
                        <Grid md={10}>
                          <Typography>Lihat Statistik</Typography>
                        </Grid>
                        <Grid md={2}>
                          <NavigateNextIcon />
                        </Grid>
                      </Grid>
                    </Button>
                  </div>
                </Card>
              </div>
            </Grid>
            <Grid item md={2}>
              <div style={{ paddingRight: 40, paddingTop: 50 }}>
                <Grid container>
                  <Grid item md={2}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: '#219653',
                        borderRadius: 50,
                      }}
                    ></div>
                  </Grid>
                  <Grid item md={10} style={{ paddingBottom: 10 }}>
                    <Typography>Sangat Aktif</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: '#FCF305',
                        borderRadius: 50,
                      }}
                    ></div>
                  </Grid>
                  <Grid item md={10} style={{ paddingBottom: 10 }}>
                    <Typography>Aktif</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: '#D6261D',
                        borderRadius: 50,
                      }}
                    ></div>
                  </Grid>
                  <Grid item md={10}>
                    <Typography>Tidak Aktif</Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Hidden>
    </>
  );
}
