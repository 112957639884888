import React, { Component, Fragment } from "react";

// * Material UI import
import {
  Typography,
  Grid,
  Button,
  TextField,
  CssBaseline
} from "@material-ui/core";

import { NavLink } from "react-router-dom";

// Component
import Header from '../Component/Header';
import Footer from '../Component/Footer';

// Image

import "../../../node_modules/video-react/dist/video-react.css";

import { Player, PosterImage, BigPlayButton } from 'video-react';

// Icons
import SearchIcon from '@material-ui/icons/Search';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Menu from "../Component/Menu";

import AbstractImg from "../../Assets/Images/background_abstract.png";
import Background from "../../Assets/Images/bg_KK_PK.png";

import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"

class VideoLiveStreaming extends Component {

  constructor() {
    super();
    this.state = {
      dataVideo: [],
      dataVideoCategory: [],
      checkEmpty: false,
      search:""
    };
  }

  getData() {
    Api.getVideoRequest().then(res=>{
      this.setState({ dataVideo: res.data });
    }).catch(err=>{
      console.log("error",err)
    })
  }

  handleChange(e) {
    this.getData();
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = async () => {
    var data = await this.state.dataVideo;
    if (this.state.search !== '') {
      var txtsearch = this.state.search;
      var dataSearch = data.filter(function (item) {
        return item.title.toString().toLowerCase().indexOf(txtsearch.toString().toLowerCase()) > -1;
      })
      data = dataSearch
      this.setState({ dataVideo: data });
    }
    else if (this.state.search === '') {
      this.getData();
    }
    else {
      this.setState({ dataVideo: [] });
    }
  }

  getDataCategory() {
    Api.getVideoCategoryRequest().then(res=>{
      this.setState({ dataVideoCategory: res.data });
    }).catch(err=>{
      console.log("error",err)
    })
  }

  async fetchData(){
    await this.getData();
    await this.getDataCategory();
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Menu 
          titleMenu={
            <Typography className={classes.txtTitle}>Video dan Live Streaming</Typography>
          }
          searchBar={
            <div>
              <TextField
                margin="normal"
                variant="outlined"
                label={false}
                placeholder="Cari video apa?"
                type="search"
                name="search"
                value={this.state.search}
                className={classes.txtSearch}
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  className: classes.multilineColor
                }}
              />
              <Button onClick={this.handleSubmit} className={classes.btnSearch}><SearchIcon style={{ color: '#ffffff' }} /></Button>
            </div>
          }
        />
        {/* menu unit */}
        <main style={{ backgroundImage: `url(${Background})`, height: "100%" }}>

          <div className={classes.container}>

            <Grid container className={classes.containerResponsive}>

              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Typography className={classes.txtStyle}>Terbaru</Typography>

                <Grid container spacing={2}>
                  {
                    this.state.dataVideo.map((item, index) => {
                      return (
                        <Grid item key={index} lg={2} md={2} sm={4} xs={12} component={NavLink} to={"/video/video-detail/" + item.category + "/" + item.title + "/#"}>
                          <div style={{ border: '1px solid #b3adad' }}>
                            <Player
                              playsInline
                              poster={StaticVar.URL_PORTAL + "/repo/" + item.thumbnail}
                              fluid={true}
                              src={StaticVar.URL_PORTAL + "/repo/"}
                              height={220}
                            >
                              <BigPlayButton position="center" />
                            </Player>
                          </div>
                          <div style={{ zIndex: 10, width: '100%', marginTop: 10 }}>
                            <Typography className={classes.txtTitleVideo} numberOfLines={1} ellipsizeMode='head'>{item.title}</Typography>
                            {/* <Typography className={classes.txtVideo}>Sepakbola</Typography>
                            <Typography className={classes.txtVideo}>73rb diputar</Typography>   */}
                          </div>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>

              {

                this.state.dataVideoCategory.map((category, index) => {

                  return (
                    <Grid container key={index}>
                      <Grid item xs={12} style={{ marginTop: 30, paddingBottom: 0 }}>
                        <Typography className={classes.txtStyle}>{category.name}</Typography>
                      </Grid>
                      <Grid container spacing={2}>
                        {
                          this.state.dataVideo.map((item, index) => {
                            if (item.category_id === category._id) {
                              return (
                                <Grid item key={index} lg={2} md={2} sm={4} xs={12} component={NavLink} to={"/video/video-detail/" + item.category + "/" + item.title + "/#"}>
                                  <div style={{ border: '1px solid #b3adad' }}>
                                    <Player
                                      poster={StaticVar.URL_PORTAL + '/repo/' + item.thumbnail}
                                      fluid={true}
                                      src={StaticVar.URL_PORTAL + "/repo/"}
                                      height={220}
                                    >
                                      <BigPlayButton position="center" />
                                    </Player>
                                  </div>
                                  <div style={{ zIndex: 10,  width: '100%', marginTop: 10 }}>
                                    <Typography className={classes.txtTitleVideo} numberOfLines={1} ellipsizeMode='head'>{item.title}</Typography>
                                  </div>
                                </Grid>
                              )
                            }
                          })
                        }
                      </Grid>
                      <Grid item xs={12} style={{ width: '100%', height: 2, backgroundColor: '#b3adad', marginTop: 20, marginBottom: 20 }} />
                    </Grid>
                  )

                })

              }


              <Grid item xs={12} style={{ marginTop: 40, marginBottom: 40 }}>
                {/* <center>
                  <Button className={classes.btnLainnya}>
                    <Typography className={classes.txtLainnya}>Jelajahi Berita Lainnya</Typography>
                  </Button>
                </center> */}
              </Grid>

            </Grid>
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

VideoLiveStreaming.propTypes = {
  classes: PropTypes.object.isRequired,
};

PosterImage.propTypes = {
  poster: PropTypes.string,
}

const useStyles = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      background: 'none'
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%'
    }
  },
  containerResponsive: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 30,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 40,
      paddingRight: 50,
    },
    paddingTop: 20,
  },
  txtStyle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    color: '#000000',
    fontWeight: 300,
    marginBottom: 10
  },
  multilineColor: {
    color: '#000'
  },
  txtVideo: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 12,
    },
    color: '#dbdbdb',
    fontWeight: 200
  },
  txtSearch: {
    [theme.breakpoints.down('xs')]: {
      width: '75%',
      marginBottom: 10
    },
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      marginBottom: 10
    },
    [theme.breakpoints.up('md')]: {
      width: '85%',
      marginBottom: 30
    },
    backgroundColor: '#fff',
  },

  txtTitleVideo: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 15,
    },
    color: '#000000',
    lineHeight: 1,
    fontWeight: 200
  },

  btnLainnya: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    border: '2px solid #bf272b', borderRadius: 5,
    height: 48
  },

  btnSearch: {
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
    [theme.breakpoints.up('md')]: {
      width: '15%',
    },
    backgroundColor: "#7d7d7d",
    height: 57
  },

  txtLainnya: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 15
    },
    textTransform: 'none', color: '#bf272b'
  },
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 25,
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 30,
    },
}
})

export default withStyles(useStyles)(VideoLiveStreaming);