import React, { useState } from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Link,
  Avatar,
  Dialog,
  Divider,
} from '@material-ui/core';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import logo from '../../Assets/Images/logo_papua.png';
import karate from '../../Assets/Images/karate.jpg';
import createTypography from '@material-ui/core/styles/createTypography';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import CloseIcon from '@material-ui/icons/Close';

import iconAvatar from '../../Assets/Images/man.png';
import Emas from '../../Assets/Images/gold-medal.png';
import Perak from '../../Assets/Images/silver-medal.png';
import Perunggu from '../../Assets/Images/bronze-medal.png';

function DetailRangking() {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [dialogTurnament, setDialogTurnament] = useState(false);
  const [dialogRank, setDialogRank] = useState(false);
  const [dialogWon, setDialogWon] = useState(false);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDialogTurnament = () => {
    setDialogTurnament(true);
  };
  const handleCloseDialogTurnament = () => {
    setDialogTurnament(false);
  };
  const handleDialogRank = () => {
    setDialogRank(true);
  };
  const handleCloseDialogRank = () => {
    setDialogRank(false);
  };
  const handleDialogWon = () => {
    setDialogWon(true);
  };
  const handleCloseDialogWon = () => {
    setDialogWon(false);
  };

  return (
    <>
      <div style={{ paddingBottom: 50, marginTop: 80 }}>
        <div
          style={{
            //   width: '100%',
            height: 80,
            backgroundColor: '#d6261d',
            padding: 20,
          }}
        >
          <Grid container>
            <Grid item md={7}>
              <Typography
                style={{
                  fontSize: 24,
                  color: '#ffffff',
                  fontWeight: 'bold',
                }}
              >
                <Link href='/Rangking' color='inherit'>
                  Rangking Nasional
                </Link>{' '}
                / Rank 1
              </Typography>
            </Grid>
            <Grid item={1}>
              <TextField
                variant='outlined'
                label='Searching'
                size='small'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item md={2} style={{ textAlign: 'right' }}>
              <IconButton>
                <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
              </IconButton>
              <IconButton>
                <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
              </IconButton>
              <IconButton>
                <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
              </IconButton>
              <IconButton>
                <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 20 }}>
          <Grid container>
            <Grid item md={3} style={{ textAlign: 'center' }}>
              <div
                style={{
                  width: 250,
                  height: 255,
                  backgroundColor: 'white',
                  borderRadius: 10,
                  paddingTop: 35,
                }}
              >
                <Avatar
                  className={classes.large}
                  variant='square'
                  src={iconAvatar}
                />
              </div>
            </Grid>
            <Grid item md>
              <div>
                <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>
                  Ahmad Maulana Syarif Hidayatullah
                </Typography>
              </div>
              <div>
                <Typography style={{ fontSize: 18, paddingBottom: 20 }}>
                  Cadet Putra Kumite-57 Kg
                </Typography>
              </div>
              <div style={{ paddingBottom: 20 }}>
                <Grid container>
                  <Grid item md={1}>
                    <img src={logo} style={{ width: 38, height: 38 }} />
                  </Grid>
                  <Grid item md style={{ paddingTop: 7 }}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Jawa Barat
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container>
                  <Grid item md={2}>
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        backgroundColor: '#D6261D',
                        textAlign: 'center',
                        borderRadius: 10,
                      }}
                    >
                      <Typography
                        style={{ fontSize: 18, color: 'white', paddingTop: 12 }}
                      >
                        Rangking
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 36,
                          color: 'white',
                          fontWeight: 'bold',
                        }}
                      >
                        1
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md style={{ paddingTop: 10 }}>
                    <div
                      style={{
                        width: 200,
                        height: 70,
                        backgroundColor: '#ffffff',
                        //   textAlign: 'center',
                        paddingTop: 15,
                        paddingLeft: 40,
                        paddingBottom: 10,
                        borderRadius: 5,
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          md={4}
                          style={{ textAlign: 'center', paddingRight: 50 }}
                        >
                          <Typography style={{ fontSize: 12 }}>
                            Point
                          </Typography>
                          <Typography style={{ fontWeight: 'bold' }}>
                            1234
                          </Typography>
                        </Grid>
                        <Grid item md={6} style={{ textAlign: 'center' }}>
                          <Typography style={{ fontSize: 12 }}>
                            Total menang
                          </Typography>
                          <Typography style={{ fontWeight: 'bold' }}>
                            123
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingLeft: 50, paddingTop: 20 }}>
          <Grid container>
            <Grid item md={1} style={{ paddingTop: 40 }}>
              <Typography>Filter:</Typography>
            </Grid>
            <Grid item md={2} style={{ paddingRight: 30 }}>
              <Typography>Tipe Turnament</Typography>
              <TextField
                variant='outlined'
                label='Semua Tipe'
                size='small'
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingTop: 20, paddingLeft: 40, paddingRight: 40 }}>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead style={{ backgroundColor: '#D6261D' }}>
                <TableRow style={{ height: 60 }}>
                  <StyledTableCell>Tanggal</StyledTableCell>
                  <StyledTableCell>Nama Turnament</StyledTableCell>
                  <StyledTableCell>Tipe Turnament</StyledTableCell>
                  <StyledTableCell>Event Factor</StyledTableCell>
                  <StyledTableCell>Rank</StyledTableCell>
                  <StyledTableCell>Matchens Won</StyledTableCell>
                  <StyledTableCell>Poin</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>09/12/2020</StyledTableCell>
                  <StyledTableCell>
                    <Link
                      component='button'
                      color='inherit'
                      onClick={() => {
                        handleDialogTurnament();
                      }}
                    >
                      {' '}
                      <Typography>Karate Championship</Typography>
                    </Link>{' '}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>Nasional</Typography>
                  </StyledTableCell>
                  <StyledTableCell>123</StyledTableCell>
                  <StyledTableCell>
                    <Link
                      component='button'
                      color='inherit'
                      onClick={() => {
                        handleDialogRank();
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          md={3}
                          style={{ paddingTop: 10, paddingRight: 15 }}
                        >
                          <img src={Emas} style={{ width: 20, height: 20 }} />
                        </Grid>
                        <Grid item md style={{ paddingTop: 7 }}>
                          <Typography>Juara I</Typography>
                        </Grid>
                      </Grid>
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Link
                      component='button'
                      color='inherit'
                      onClick={() => {
                        handleDialogWon();
                      }}
                    >
                      <Typography>4</Typography>
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>112</Typography>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>23/09/2018</StyledTableCell>
                  <StyledTableCell>
                    <Typography>Jabar Open Warrior Karate</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>Provinsi</Typography>
                  </StyledTableCell>
                  <StyledTableCell>123</StyledTableCell>
                  <StyledTableCell>
                    <Grid container>
                      <Grid
                        item
                        md={3}
                        style={{ paddingTop: 10, paddingRight: 15 }}
                      >
                        <img src={Perak} style={{ width: 20, height: 20 }} />
                      </Grid>
                      <Grid item md style={{ paddingTop: 7 }}>
                        <Typography>Juara II</Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>3</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>105</Typography>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>09/09/2015</StyledTableCell>
                  <StyledTableCell>
                    <Typography> Karate Championship</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>Asian Chanpioships</Typography>
                  </StyledTableCell>
                  <StyledTableCell>123</StyledTableCell>
                  <StyledTableCell>
                    <Typography>Participant</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>2</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>50</Typography>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>20/08/2014</StyledTableCell>
                  <StyledTableCell>
                    <Typography> Bekasi Open Karate</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>Cabang</Typography>
                  </StyledTableCell>
                  <StyledTableCell>123</StyledTableCell>
                  <StyledTableCell>
                    <Grid container>
                      <Grid
                        item
                        md={3}
                        style={{ paddingTop: 10, paddingRight: 15 }}
                      >
                        <img src={Emas} style={{ width: 20, height: 20 }} />
                      </Grid>
                      <Grid item md style={{ paddingTop: 7 }}>
                        <Typography>Juara I</Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>6</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>90</Typography>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>23/09/2013</StyledTableCell>
                  <StyledTableCell>
                    <Typography> Karate Championship</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>Nasional</Typography>
                  </StyledTableCell>
                  <StyledTableCell>123</StyledTableCell>
                  <StyledTableCell>
                    <Grid container>
                      <Grid
                        item
                        md={3}
                        style={{ paddingTop: 10, paddingRight: 15 }}
                      >
                        <img src={Emas} style={{ width: 20, height: 20 }} />
                      </Grid>
                      <Grid item md style={{ paddingTop: 7 }}>
                        <Typography>Juara I</Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>6</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>112</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              //   count={hotel.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </div>

      <Dialog
        open={dialogTurnament}
        onClose={handleCloseDialogTurnament}
        fullWidth='true'
      >
        <Card>
          <CardHeader
            action={
              <IconButton onClick={handleCloseDialogTurnament}>
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            }
            title='Turnament'
            style={{ backgroundColor: '#D6261D', color: 'white', height: 60 }}
          />
          <CardMedia>
            <img src={karate} style={{ height: 300, width: '100%' }} />
          </CardMedia>

          <CardContent style={{ paddingLeft: 20, paddingRight: 20 }}>
            <div style={{ paddingBottom: 10 }}>
              <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
                Warrior Open Karate Chanpioship 2020
              </Typography>
            </div>
            <div style={{ paddingBottom: 10 }}>
              <Grid container>
                <Grid item md={1}>
                  <DateRangeIcon color='primary' fontSize='small' />
                </Grid>
                <Grid item md={3}>
                  <Typography style={{ fontSize: 14 }}>
                    Hari/Tanggal :
                  </Typography>
                </Grid>
                {/* <Grid item md={1}>
                  <Typography>:</Typography>
                </Grid> */}
                <Grid item md={7}>
                  <Typography style={{ fontSize: 14 }}>
                    Jum'at-Minggu, 01-09 Desember 2020
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ paddingBottom: 10 }}>
              <Grid container>
                <Grid item md={1}>
                  <RoomIcon color='secondary' fontSize='small' />
                </Grid>
                <Grid item md={3}>
                  <Typography style={{ fontSize: 14 }}>Tempat :</Typography>
                </Grid>
                {/* <Grid item md={1}>
                  <Typography>:</Typography>
                </Grid> */}
                <Grid item md={7}>
                  <Typography style={{ fontSize: 14 }}>
                    GOR Mini DISPORASU, Jl.Williem Iskandar No.209, Kenangan
                    Baru, Kec.Percut Sei Tuan, Kabupaten Deli Serdang, Sumatera
                    Utara 20371
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container>
                <Grid item md={1}>
                  <PersonIcon fontSize='small' />
                </Grid>
                <Grid item md={3}>
                  <Typography style={{ fontSize: 14 }}>PIC :</Typography>
                </Grid>
                {/* <Grid item md={1}>
                  <Typography>:</Typography>
                </Grid> */}
                <Grid item md={7}>
                  <Typography style={{ fontSize: 14 }}>
                    Michael Stevan William
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </Dialog>

      <Dialog
        open={dialogRank}
        // onClose={handleCloseDialogRank}
        fullWidth='true'
      >
        <Card>
          <CardHeader
            action={
              <IconButton onClick={handleCloseDialogRank}>
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            }
            title='Rank'
            style={{ backgroundColor: '#D6261D', color: 'white', height: 60 }}
          />
          <CardContent>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    className={classes.normal}
                    variant='square'
                    src={iconAvatar}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>
                    Ahmad Maulana Syarif Hidayatullah
                  </Typography>
                  <div style={{ paddingTop: 10 }}>
                    <Grid container>
                      <Grid item md={1}>
                        <img src={Emas} style={{ width: 20, height: 20 }} />
                      </Grid>
                      <Grid item md>
                        <Typography>Juara I</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    className={classes.normal}
                    variant='square'
                    src={iconAvatar}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>
                    Akbar Tanjung
                  </Typography>
                  <div style={{ paddingTop: 10 }}>
                    <Grid container>
                      <Grid item md={1}>
                        <img src={Perak} style={{ width: 20, height: 20 }} />
                      </Grid>
                      <Grid item md>
                        <Typography>Juara II</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    className={classes.normal}
                    variant='square'
                    src={iconAvatar}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>
                    Rezky Putra Wijaya
                  </Typography>
                  <div style={{ paddingTop: 10 }}>
                    <Grid container>
                      <Grid item md={1}>
                        <img src={Perunggu} style={{ width: 20, height: 20 }} />
                      </Grid>
                      <Grid item md>
                        <Typography>Juara III</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Dialog>

      <Dialog
        open={dialogWon}
        onClose={handleCloseDialogWon}
        fullWidth='true'
        maxWidth='md'
      >
        <Card>
          <CardHeader
            action={
              <IconButton onClick={handleCloseDialogWon}>
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            }
            title='Matches Won'
            style={{ backgroundColor: '#D6261D', color: 'white', height: 60 }}
          />
          <CardContent>
            <List className={classes.listStyle}>
              <ListItem>
                <Grid container>
                  <Grid item md={2}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>
                      Won 1
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: 24,
                        color: '#828282',
                      }}
                    >
                      Perdelapan Final
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <List>
                      <div
                        style={{
                          height: 30,
                          backgroundColor: '#D6261D',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        <Typography>Kamis, 14 Otk 2020 11:30</Typography>
                      </div>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.small}
                            // variant='square'
                            src={iconAvatar}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            Ahmad Maulana Syarif Hidayatullah
                          </Typography>
                          <Typography>Jawa Barat</Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.small}
                            // variant='square'
                            src={iconAvatar}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            Akbar Tanjung
                          </Typography>
                          <Typography>Jawa Barat</Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container>
                  <Grid item md={2}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>
                      Won 2
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: 24,
                        color: '#828282',
                      }}
                    >
                      Perempat Final
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <List>
                      <div
                        style={{
                          height: 30,
                          backgroundColor: '#D6261D',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        <Typography>Kamis, 14 Otk 2020 11:30</Typography>
                      </div>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.small}
                            // variant='square'
                            src={iconAvatar}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            Ahmad Maulana Syarif Hidayatullah
                          </Typography>
                          <Typography>Jawa Barat</Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.small}
                            // variant='square'
                            src={iconAvatar}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            Akbar Tanjung
                          </Typography>
                          <Typography>Jawa Barat</Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container>
                  <Grid item md={2}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>
                      Won 3
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: 24,
                        color: '#828282',
                      }}
                    >
                      Semi Final
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <List>
                      <div
                        style={{
                          height: 30,
                          backgroundColor: '#D6261D',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        <Typography>Kamis, 14 Otk 2020 11:30</Typography>
                      </div>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.small}
                            // variant='square'
                            src={iconAvatar}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            Ahmad Maulana Syarif Hidayatullah
                          </Typography>
                          <Typography>Jawa Barat</Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.small}
                            // variant='square'
                            src={iconAvatar}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            Akbar Tanjung
                          </Typography>
                          <Typography>Jawa Barat</Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container>
                  <Grid item md={2}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>
                      Won 4
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: 24,
                        color: '#828282',
                      }}
                    >
                      Final
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <List>
                      <div
                        style={{
                          height: 30,
                          backgroundColor: '#D6261D',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        <Typography>Kamis, 14 Otk 2020 11:30</Typography>
                      </div>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.small}
                            // variant='square'
                            src={iconAvatar}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            Ahmad Maulana Syarif Hidayatullah
                          </Typography>
                          <Typography>Jawa Barat</Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.small}
                            // variant='square'
                            src={iconAvatar}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            Akbar Tanjung
                          </Typography>
                          <Typography>Jawa Barat</Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  large: {
    width: 200,
    height: 180,
    paddingLeft: 50,
  },
  normal: {
    width: '80px',
    height: '80px',
    // paddingTop: 10,
    paddingRight: 10,
  },
  small: {
    width: '50px',
    height: '50px',
    // paddingTop: 10,
    paddingRight: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  listStyle: {
    // width: '100%',
    // maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 400,
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default DetailRangking;
