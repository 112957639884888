import React, { useState } from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Input,
  Card,
  Link,
  Button,
  CardContent,
  Dialog,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Gambars from '../Component/listCard/400Foto';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />);

export default function Daftar() {
  const [dialogSebagai, setDialogSebagai] = useState(false);

  const dialogHandleSebagai = () => {
    setDialogSebagai(true);
  };
  const dialogHandleCloseSebagai = () => {
    setDialogSebagai(false);
  };

  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundColor: '#d6261d',
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              <Link href='/Event' color='inherit'>
                Event
              </Link>{' '}
              /{' '}
              <Link href='/Event Selesai' color='inherit'>
                Event yang sudah selesai
              </Link>
              / Daftar
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}>
        <Grid container spacing={3}>
          <Grid item md={8}>
            <Gambars />
          </Grid>
          <Grid item md={4}>
            <div style={{ textAlign: 'center' }}>
              <AccountCircleIcon color='secondary' style={{ fontSize: 80 }} />
            </div>
            <Card style={{ width: 388, height: 343, marginTop: -50 }}>
              <CardContent>
                <div
                  style={{
                    textAlign: 'center',
                    paddingTop: 40,
                    textAlign: 'center',
                    paddingLeft: 20,
                  }}
                >
                  <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                    Login
                  </Typography>
                </div>
                <div
                  style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
                >
                  <TextField
                    placeholder='Lemkari ID'
                    style={{ width: '100%' }}
                  />
                  <FormControl style={{ width: '100%', paddingTop: 20 }}>
                    <Input
                      placeholder='Kata Sandi'
                      // type={values.showPassword ? 'text' : 'password'}
                      // value={values.password}
                      // onChange={handleChange('password')}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            //   onClick={handleClickShowPassword}
                            //   onMouseDown={handleMouseDownPassword}
                          >
                            <Visibility />
                            {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{ width: 328, height: 48, marginTop: 40 }}
                    onClick={dialogHandleSebagai}
                  >
                    <Typography>Login</Typography>
                  </Button>
                  <Typography
                    style={{ fontSize: 12, marginTop: 30, textAlign: 'center' }}
                  >
                    Belum punya akun ? <Link>Daftar Sekarang</Link>
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <Dialog open={dialogSebagai} onClose={dialogHandleCloseSebagai}>
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            textAlign: 'center',
            paddingTop: 20,
          }}
        >
          <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
            Daftar Sebagai
          </Typography>
          <Grid container style={{ paddingTop: 20 }}>
            <Grid item md={6} style={{ textAlign: 'left' }}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    // checked={state.checkedG}
                    // onChange={handleChange}
                    name='checkedG'
                  />
                }
                label='Karate'
              />
            </Grid>
            <Grid item md={6} style={{ textAlign: 'right' }}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    // checked={state.checkedG}
                    // onChange={handleChange}
                    name='checkedG'
                  />
                }
                label='Umum'
              />
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color='primary'
            style={{ width: 328, height: 48, marginTop: 20, marginBottom: 15 }}
            href='/Terima Kasih'
          >
            <Typography>Lanjut</Typography>
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
