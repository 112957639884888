// const BASE_URL_PORTAL = 'http://202.83.121.246:305';
const BASE_URL_PORTAL = 'https://apiportalkarate.ponxx2021papua.com/';
const BASE_URL_GAMES = 'https://apigames.ponxx2020papua.com';
// const BASE_URL_KARATE = 'http://localhost:4500';
//'http://api.efrmfreeport.com' //

export default {
  URL_PORTAL: BASE_URL_PORTAL,
  URL_GAMES: BASE_URL_GAMES,
  // URL_KARATE: BASE_URL_KARATE,
  IMAGE_PORTAL: `${BASE_URL_PORTAL}/repo/`,
  IMAGE_GAMES: `${BASE_URL_GAMES}/repo/`,
  PING_TARGET: BASE_URL_PORTAL,
  COMPILE_DATE: '29 Maret 2020',
  REV: '9',
};
