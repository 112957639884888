import React, { useState } from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Link,
  Avatar,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import karate from '../../Assets/Images/karate.jpg';
import Background from "../../Assets/Images/bg_KK_PK.png";

import Dewancard from '../Component/CardDewan';

function DewanGuru() {
  const classes = useStyles();

  const [dialogDetail, setDialogDetail] = useState(false);

  const dialogHandleDetail = () => {
    setDialogDetail(true);
  };
  const dialogHandleCloseDetail = () => {
    setDialogDetail(false);
  };

  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={9}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              Dewan Guru
            </Typography>
          </Grid>
          {/* <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid> */}
          <Grid item md={3} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: 20, paddingLeft: 40, paddingRight: 40 }}>
        <Grid container>
          <Grid item md={9} style={{ paddingRight: 50 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Masa Bakti 2016-2020
            </Typography>
            <div style={{ paddingTop: 20 }}>
              <Grid container spacing={5}>
                <Grid item md={4}>
                  <Link onClick={dialogHandleDetail} color='inherit'>
                    <Dewancard
                      nama=' Harried Taning'
                      sabuk='DAN VIII'
                      jabatan='Ketua Dewan Guru'
                    />
                  </Link>
                </Grid>
                <Grid item md={4}>
                  <Dewancard
                    nama=' Ir.Rudy Gunorto,MM'
                    sabuk='DAN VI'
                    jabatan='Sekretaris Dewan Guru'
                  />
                </Grid>
                <Grid item md={4}>
                  <Dewancard
                    nama=' H.Imron Rosyadi'
                    sabuk='DAN VII'
                    jabatan='Anggota Dewan Guru'
                  />
                </Grid>
                <Grid item md={3} />
                <Grid item md={6} style={{ textAlign: 'center' }}>
                  <div>
                    <Pagination
                      count={10}
                      // variant='outlined'
                      shape='rounded'
                      color='primary'
                    />
                  </div>
                </Grid>
                <Grid item md={3} />
              </Grid>
            </div>
          </Grid>
          <Grid item md={3}>
            <div>
              <List style={{ marginTop: -20 }}>
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Sejarah Karate' color='inherit'>
                      <Typography>Sejarah Karate</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Sejarah Lemkari' color='inherit'>
                      <Typography>Sejarah Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Arti Lambang Lemkari' color='inherit'>
                      <Typography>Arti Lambang Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/AD & ART' color='inherit'>
                      <Typography>AD/ART</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Visi & Misi' color='inherit'>
                      <Typography>Visi & Misi</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Pengurus Besar' color='inherit'>
                      <Typography>Pengurus Besar</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Dewan Guru' color='inherit'>
                      <Typography>Dewan Guru</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Pengurus Provinsi' color='inherit'>
                      <Typography>Pengurus Provinsi</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
              </List>
            </div>
          </Grid>
        </Grid>
      </div>

      <Dialog
        open={dialogDetail}
        onClose={dialogHandleCloseDetail}
        maxWidth='md'
        fullWidth='true'
      >
        <Card>
          <Grid container>
            <Grid item md={3}>
              <img src={karate} style={{ width: 270, height: '100%' }} />
            </Grid>
            <Grid item md={9} style={{ paddingLeft: 30 }}>
              <CardHeader
                action={
                  <IconButton onClick={dialogHandleCloseDetail}>
                    <CloseIcon style={{ color: 'white' }} />
                  </IconButton>
                }
                title='Harried Taning'
                style={{
                  color: 'white',
                  height: 60,
                  backgroundColor: '#D6261D',
                }}
              />
              <CardContent>
                <Grid container>
                  <Grid item md={4}>
                    <Typography>Tingkat DAN</Typography>
                    <Typography>Jabatan</Typography>
                    <Typography>TTL</Typography>
                    <Typography>Mulai Latihan Karate</Typography>
                    <Typography>No. Telepon</Typography>
                    <Typography>Alamat</Typography>
                    <Typography style={{ paddingTop: 25 }}>
                      Motto Hidup
                    </Typography>
                  </Grid>
                  <Grid item md>
                    <Typography>: Dan VIII</Typography>
                    <Typography>: Ketua Dewan Guru</Typography>
                    <Typography>: Makassar, 27/9/1951</Typography>
                    <Typography>: Tahun 1965 di Makassar</Typography>
                    <Typography>: 982344567890</Typography>
                    <Typography>
                      : Perumahan Depok Indah 2 Blok J No.9 Beji Depok 16421
                      Jawa Barat
                    </Typography>
                    <Typography>
                      : Tiada kesuksesan tanpa keberanian mengambil resiko
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  normal: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

export default DewanGuru;
