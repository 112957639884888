import React, { Component, Fragment } from "react";

import SwipeableViews from 'react-swipeable-views';
// * Material UI import
import {
  Typography,
  Grid,
  CssBaseline,
  Modal,
  Fade,
  Backdrop,
  Fab,
} from "@material-ui/core";

import _ from "lodash";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// Component
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Menu from '../Component/Menu';

import PropTypes from 'prop-types';

import AbstractImg from "../../Assets/Images/background_abstract.png";
import { withStyles } from '@material-ui/core/styles';

import BreadCrumbs from "../Component/BreadCrumbs";

import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"

import Background from "../../Assets/Images/bg_KK_PK.png";

class GalleryPhoto extends Component {

  constructor() {
    super();
    this.state = {
      dataFoto: [],
      setDialogDetailPhoto: null,
      open: false,
      dataDetail: [],
      activeStep: 0,
    };
  }

  getDataPhotos() {
    const { category } = this.props.match.params;
    Api.getPhotoCategoryByNameRequest(category).then(res=>{
      this.setState({ dataFoto: res.data })
    }).catch(err=>{
      console.log("error",err)
    })
  }

  getDataDetail(id) {
    const dataView = _.sortBy(this.state.dataFoto, function (item) {
      return item._id === id ? 0 : 1;
    });

    this.setState({ dataDetail: dataView });
  }

  componentDidMount() {
    this.getDataPhotos();
  }

  openModal(id) {
    this.setState({ open: true });
    this.getDataDetail(id);
  }

  closeModal() {
    this.setState({ open: false })
  }

  handleNext() {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }))
  }

  handleBack() {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }))
  }

  handleStepChange(step) {
    this.setState({ activeStep: step });
  }

  render() {
    const { classes } = this.props;
    const { category } = this.props.match.params;
    const maxSteps = this.state.dataDetail.length;

    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Menu 
          titleMenu={
            <div>
              <BreadCrumbs
                firstName="Galeri Foto"
                secondName={category}
                colorFirstLink="inherit"
                activeLink="textPrimary"
                firstLink="/photos"
                secondLink={"/photos/category/" + category}
              />
              <div style={{ marginBottom: 40 }} />
            </div>
          }
        />
        {/* menu unit */}
        <main style={{ backgroundImage: `url(${Background})`, height: "100%" }}>

          <div className={classes.container}>
            <div className={classes.containerResponsive}>
              <Grid container spacing={2}>
                {
                  this.state.dataFoto.map((item, index) => {
                    return (
                      <Grid item key={index} xs={12} sm={3} md={3} lg={3} onClick={() => this.openModal(item._id)} style={{ marginBottom: 25, textDecoration: 'none', cursor: 'pointer' }}>
                        <div className={classes.containerBoxImg}>
                          <div style={{ backgroundImage: `url("${StaticVar.URL_PORTAL + "/repo/" + item.picture}")`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', height: 'auto', width: '100%', backgroundPosition: 'center', paddingTop: 180, borderRadius: 10 }}>

                            <div style={{ background: 'rgba(0, 0, 0, 0.3)', zIndex: 2, width: '100%', height: '100%', borderBottomLeftRadius:8,borderBottomRightRadius:8, paddingTop: 10 }}>
                              <Typography style={{ color: '#ffffff', textAlign: 'center', textShadow: '1px 2px 1px #1c1c1c' }}>{item.title}</Typography>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    )
                  })
                }
              </Grid>

              <Modal
                className={classes.modal}
                open={this.state.open}
                onClose={() => this.closeModal()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={this.state.open}>
                  <div className={classes.paper}>
                    <SwipeableViews
                      enableMouseEvents
                      index={this.state.activeStep}
                      onChangeIndex={(e) => this.handleStepChange(e)}
                    >
                      {
                        this.state.dataDetail.map((item, index) => {
                          return (
                            <Grid container key={index}>
                              <Grid item xs={12}>
                                <div className={classes.BoxDetailImg}>
                                  <div style={{ backgroundImage: `url("${StaticVar.URL_PORTAL + "/repo/" + item.picture}")`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', height: '100%', width: '100%', backgroundPosition: 'center' }} />
                                </div>
                              </Grid>
                              <Grid item xs={12} style={{ backgroundColor: '#ffffff', paddingLeft: 20, paddingTop: 10, paddingBottom: 10 }}>
                                <Typography style={{ fontSize: 18, fontWeight: 300 }}>{item.title}</Typography>
                                <Typography style={{ fontSize: 15, fontWeight: 300, color: '#3d3d3d' }}>Kategori Foto : {item.photos_category[0].name}</Typography>
                              </Grid>
                            </Grid>
                          )
                        })
                      }
                    </SwipeableViews>
                    <Fab className={classes.buttonPreviousModal} onClick={() => this.handleBack()} disabled={this.state.activeStep === 0}>
                      <ArrowBackIosIcon style={{ color: '#ffffff', fontSize: 18, fontWeight: 'bold' }} />
                    </Fab>
                    <Fab className={classes.buttonNextModal} onClick={() => this.handleNext()} disabled={this.state.activeStep === maxSteps - 1}>
                      <ArrowForwardIosIcon style={{ color: '#ffffff', fontSize: 18, fontWeight: 'bold' }} />
                    </Fab>
                  </div>
                </Fade>
              </Modal>
            </div>
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

GalleryPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      background: 'none'
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%'
    }
  },
  containerResponsive: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 10,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 40,
    },
    paddingTop: 30,
  },
  containerBoxImg: {
    width: '100%',
    height: 210,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.up('md')]: {
      width: 600
    },
    backgroundColor: '#262626',
    borderRadius: 3,
    padding: 0,
  },
  BoxDetailImg: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 260
    },
    [theme.breakpoints.up('sm')]: {
      height: 280
    },
    [theme.breakpoints.up('md')]: {
      height: 310
    },
  },
  containerBoxDetail: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 35,
      paddingRight: 35
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 60,
      paddingRight: 60
    },
  },
  buttonPreviousModal: {
    width: 50,
    height: 50,
    paddingLeft: 4,
    backgroundColor: '#f58634',
    position: 'absolute',

    [theme.breakpoints.down('xs')]: {
      top: '40%',
      left: '12%'
    },
    [theme.breakpoints.up('sm')]: {
      top: '40%',
      left: '22%'
    },
    [theme.breakpoints.up('md')]: {
      top: '40%',
      left: '22%'
    },
    [theme.breakpoints.up('lg')]: {
      top: '42%',
      left: '32%'
    },
    [theme.breakpoints.up('xl')]: {
      top: '45%',
      left: '36%'
    },
    "&:disabled": {
      backgroundColor: "#f5cb90"
    }
  },
  buttonNextModal: {
    width: 50,
    height: 50,
    backgroundColor: '#f58634',
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      top: '40%',
      right: '12%'
    },
    [theme.breakpoints.up('sm')]: {
      top: '40%',
      right: '22%'
    },
    [theme.breakpoints.up('md')]: {
      top: '40%',
      right: '22%'
    },
    [theme.breakpoints.up('lg')]: {
      top: '42%',
      right: '32%'
    },
    [theme.breakpoints.up('xl')]: {
      top: '45%',
      right: '36%'
    },
    "&:disabled": {
      backgroundColor: "#f5cb90"
    }
  },
  btnSearch: {
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
    [theme.breakpoints.up('md')]: {
      width: '15%',
    },
    backgroundColor: "#7d7d7d",
    height: 57
  },
  txtSearch: {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginBottom: 10
    },
    [theme.breakpoints.up('md')]: {
      width: '85%',
      marginBottom: 30
    },
    backgroundColor: '#bfbfbf',
  },
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 27,
    },
  }
})
export default withStyles(useStyles)(GalleryPhoto);