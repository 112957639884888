import React from 'react';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';

// component
import Footers from './Pages/Component/Footer';
import Headers from './Pages/Component/Header';
import Menus from './Pages/Component/Menu';
import Breads from './Pages/Component/BreadCrumbs';
import SubHeader from './Pages/Component/subHeader';

// * Screen
import Home from './Pages/Home/Home';
import News from './Pages/NewsAndGallery/News';
import GalleryPhoto from './Pages/NewsAndGallery/GalleryPhoto';
import VideoLiveStreaming from './Pages/NewsAndGallery/VideoLiveStreaming';
import DetailVideoLiveStreaming from './Pages/NewsAndGallery/DetailVideoLiveStreaming';
import CategoryPhoto from './Pages/NewsAndGallery/CategoryPhoto';
import NewsDetail from './Pages/NewsAndGallery/NewsDetail';
import Pages from './Pages/Pages/Pages';

import Rangking from './Pages/RangkingNasional/rangkingNasional';
import DetailRangking from './Pages/RangkingNasional/detailRangking';

import SejarahKarate from './Pages/TengtangLemkari/sejarahKarate';
import SejarahLemkari from './Pages/TengtangLemkari/sejarahLemkari';
import LambangLemkari from './Pages/TengtangLemkari/artiLambangLemkari';
import AdArt from './Pages/TengtangLemkari/AD&ART';
import VisiMisi from './Pages/TengtangLemkari/visiMisi';
import PengurusBesar from './Pages/TengtangLemkari/pengurusBesar';
import DewanGuru from './Pages/TengtangLemkari/dewanGuru';
import PengurusProvinsi from './Pages/TengtangLemkari/pengurusProvinsi';

import SemuaBerita from './Pages/BeritaGalery/semuaBerita';
import DetailBerita from './Pages/BeritaGalery/detailBerita';
import GaleryFoto from './Pages/BeritaGalery/galeriFoto';
import DetailGaleryFoto from './Pages/BeritaGalery/detaiGaleriFoto';
import Video from './Pages/BeritaGalery/videoLiveStreaming';
import DetailVideo from './Pages/BeritaGalery/detailVideoLiveStreaming';

import Events from './Pages/Event/event';
import EventSelesai from './Pages/Event/eventSelesai';
import Daftar from './Pages/Event/daftar';
import UcapanThanks from './Pages/Event/ucapanTerimaKasih';
import SeminarEvent from './Pages/Event/seminarEvent';

import Statistik from './Pages/Statistik/statistik';
import SRangking from './Pages/Statistik/rangking';

import CacheBuster from './CacheBuster';

import CssBaseline from '@material-ui/core/CssBaseline';

import './App.css';
import KontakKami from './Pages/KontakKami/Kontak';
import ProgramKerja from './Pages/ProgramKerja/ProgramKerja';

import AbstractImg from './Assets/Images/bg-images.png';

export default function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <div>
            <Router>
              <Headers />
              <div className='bg-image'>
                <div
                  style={{
                    backgroundImage: `url(${AbstractImg})`,
                    backgroundColor: '#e5e5e5',
                  }}
                >
                  {/* <SubHeader /> */}
                  <Route exact path='/' component={Home} />
                  <Route path='/Rangking' component={Rangking} />
                  <Route path='/Detail Rangking' component={DetailRangking} />
                  <Route path='/Kontak Kami' component={KontakKami} />
                  <Route path='/Sejarah Karate' component={SejarahKarate} />
                  <Route path='/Sejarah Lemkari' component={SejarahLemkari} />
                  <Route path='/AD & ART' component={AdArt} />
                  <Route path='/Visi & Misi' component={VisiMisi} />
                  <Route path='/Pengurus Besar' component={PengurusBesar} />
                  <Route path='/Dewan Guru' component={DewanGuru} />
                  <Route path='/Program Kerja' component={ProgramKerja} />
                  <Route
                    path='/Pengurus Provinsi'
                    component={PengurusProvinsi}
                  />
                  <Route
                    path='/Arti Lambang Lemkari'
                    component={LambangLemkari}
                  />
                  <Route path='/Semua Berita' component={SemuaBerita} />
                  <Route path='/Detail Berita' component={DetailBerita} />
                  <Route path='/Galery Foto' component={GaleryFoto} />
                  <Route
                    path='/Detail Galery Foto'
                    component={DetailGaleryFoto}
                  />
                  <Route path='/Video & Live Streaming' component={Video} />
                  <Route
                    path='/Detail Video & Live Streaming'
                    component={DetailVideo}
                  />

                  <Route path='/Event' component={Events} />
                  <Route path='/Event Selesai' component={EventSelesai} />
                  <Route path='/Daftar' component={Daftar} />
                  <Route path='/Terima Kasih' component={UcapanThanks} />
                  <Route path='/Seminar Event' component={SeminarEvent} />
                  <Route path='/Statistik' component={Statistik} />
                  <Route path='/Statistik Ranting' component={SRangking} />
                  {/* <Route exact path='/news' component={News} />
            <Route exact path='/news/:category' component={News} />
            <Route
              exact
              path='/news/news-detail/:category/:title'
              component={NewsDetail}
            />
            <Route
              exact
              path='/photos/photo-detail/:category/:title'
              component={GalleryPhoto}
            />
            <Route exact path='/photo/gallery' component={GalleryPhoto} />
            <Route exact path='/video' component={VideoLiveStreaming} />
            <Route
              exact
              path='/video/video-detail/:category/:title'
              component={DetailVideoLiveStreaming}
            />
            <Route exact path='/photos' component={CategoryPhoto} />
            <Route
              exact
              path='/photos/category/:category'
              component={GalleryPhoto}
            />
            <Route path='/pages/:name' component={Pages} /> */}
                </div>
              </div>

              <Footers />
            </Router>
          </div>
        );
      }}
    </CacheBuster>
  );
}
