import React, { Component, Fragment } from "react";

// * Material UI import
import {
  Typography,
  Grid,
  Button,
  CssBaseline,
} from "@material-ui/core";

import _ from 'lodash';

// Component
import Header from '../Component/Header';
import Footer from '../Component/Footer';

// Image
import AbstractImg from "../../Assets/Images/background_abstract.png";

// Icons

import BreadCrumbs from "../Component/BreadCrumbs";

import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import Menu from '../Component/Menu'

import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"

class NewsDetail extends Component {

  constructor() {
    super();
    this.state = {

      news: [],
      content: { tags: [] },
      news_tag: ["PON XX", "Papua", "Menpora", "Kabupate Jayapura", "Venue", "Persiapan PON"],
      contentBerita: {
        title: '',
        content: ''
      }
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  newsdetail(category, title) {
    const data = {
      category:category,
      title:title
    }
    Api.getNewsContentRequest(data).then(res=>{
      this.setState({ content: res.data[0] });
      this.props.history.push("/news/news-detail/" + category + "/" + title + "/#");
    }).catch(err=>{
      console.log("err",err)
    })
  }

  async fetchData(){
    const { title, category } = this.props.match.params;
    const data = {
      category:category,
      title:title
    }

    await Api.getNewsRequest().then(res=>{
      const limitDataNews = _.take(res.data, 10)
      this.setState({ news: limitDataNews });
    }).catch(err=>{
      console.log("err",err)
    })

    await Api.getNewsContentRequest(data).then(res=>{
      this.setState({ content: res.data[0] });
    }).catch(err=>{
      console.log("err",err)
    })

    
    

  }

  render() {
    const { classes } = this.props;
    const { title, category } = this.props.match.params;
    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Menu
          titleMenu={
            <div>
            <BreadCrumbs
              firstName="Berita"
              secondName={category}
              colorFirstLink="inherit"
              activeLink="textPrimary"
              firstLink="/news"
              secondLink={"/news/"+category}
            />
            <div style={{marginBottom:40}}/>
            </div>
          }
        />
        {/* menu unit */}
        <main style={{ backgroundImage: 'linear-gradient(to right, #ffffff , #c4c4c4 )' }}>

          <div className={classes.container}>
            <Grid container className={classes.containerResponsive}>
              <Grid item md={7} sm={12} xs={12}>

                <Grid container style={{ marginTop: 10 }}>
                  <Grid item xs={12} className={classes.BeritaImg}>
                    {/* <div style={{ backgroundImage: `url("${url + "/repo/" + this.state.content.picture}")`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: 'auto', minHeight: '98%', width: '100%', backgroundPosition: 'center' }} /> */}
                    <img src={StaticVar.URL_PORTAL + "/repo/" + this.state.content.picture} style={{width:'100%', objectFit:"cover",height:"auto"}}/>
                    <Typography style={{ float: 'left', fontSize: 14, fontWeight: 300, color: '#595959' }}>{this.state.content.create_date}</Typography>
                    <Typography style={{ float: 'right', fontSize: 14, fontWeight: 300, color: '#595959' }}>Penulis : {this.state.content.creator}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 style={{ paddingBottom: 0 }}>{category}</h2>
                    <Typography className={classes.titleBeritaTxt}>{title}</Typography>
                    <Typography className={classes.contentBeritaTxt}>
                      <div dangerouslySetInnerHTML={{
                        __html: this.state.content.content
                      }}></div>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} />
              <Grid item md={4} sm={12}>
                <Typography style={{ fontSize: 18, fontWeight: 600 }}>Tag Berita </Typography>
                <div style={{ marginTop: 10, marginBottom: 30 }}>
                  {
                    this.state.content.tags.map((item, index) => {
                      return (
                        <Button key={index} style={{ backgroundColor: "#ED3237", color: 'white', margin: 2, borderRadius: 20 }}>{item}</Button>
                      )
                    })
                  }
                </div>
                <Typography style={{ fontSize: 18, fontWeight: 600 }}>Berita Lainnya</Typography>
                <Grid container style={{ marginBottom: 120, marginTop: 10 }}>
                  {

                    this.state.news.map((item, index) => {
                      return (
                        <Grid item key={index} xs={12} className={classes.spacingBottom} style={{cursor:'pointer'}} onClick={() => { this.newsdetail(item.category, item.title) }}>
                          <Grid container spacing={2}>
                            <Grid item xs={5} style={{ height: 110 }}><div style={{ borderRadius: 5, backgroundImage: `url("${StaticVar.URL_PORTAL + "/repo/" + item.picture}")`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: 'auto', minHeight: '100%', width: '100%', backgroundPosition: 'center' }} /></Grid>
                            <Grid item xs={7}>
                              <Typography style={{ fontSize: 12, fontWeight: 200, color: '#000000' }}>{item.category}</Typography>
                              <Typography style={{ fontSize: 12, fontWeight: 200, textAlign: 'left', color: '#000000' }}>{item.create_date}</Typography>
                              <Typography style={{ fontSize: 14, fontWeight: 300, color: '#000000' }}>{item.title}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            </Grid>

          </div>

        </main>
        {/* Footer */}
        <footer>
          <Footer />
        </footer>
        {/* End footer */}
      </Fragment>
    );
  }
}

NewsDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      background: 'none'
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%'
    }
  },
  BeritaImg: {
    width: '100%',
    // [theme.breakpoints.down('xs')]: {
    //   height: 260,
    // },
    // [theme.breakpoints.up('sm')]: {
    //   height: 350,
    // },
    // [theme.breakpoints.up('md')]: {
    //   height: 420,
    // },
    height:'auto'
  },
  titleBeritaTxt: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 16
  },
  contentBeritaTxt: {
    fontSize: 14,
    fontWeight: 200,
    marginBottom: 20,
  },
  containerResponsive: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 10,
      paddingTop: 10,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 40,
      paddingTop: 30,
    },
    
  },
  spacingBottom: {
    marginBottom: 20
  },
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
    },
  }
})

export default withStyles(useStyles)(NewsDetail);