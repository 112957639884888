import React from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Divider,
  Card,
  Button,
  Link,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';

export default function SeminarEvent() {
  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundColor: '#d6261d',
          padding: 25,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              <Link href='/Event' color='inherit'>
                Event
              </Link>{' '}
              /{' '}
              <Link href='/Event Selesai' color='inherit'>
                Event yang akan datang
              </Link>{' '}
              / Daftar
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid item md={8} style={{ paddingTop: 20, paddingLeft: 55 }}>
          <Card
            style={{
              width: 770,
              padding: 20,
            }}
          >
            <Typography
              style={{ textAlign: 'center', fontSize: 48, fontWeight: 'bold' }}
            >
              Seminar Event
            </Typography>
            <Typography
              style={{ textAlign: 'center', fontSize: 18, paddingTop: 10 }}
            >
              Silahkan isi data dibawah ini dengan LENGKAP dan BENAR. Supaya
              data Anda bisa dihubungi setelah malakukan pendaftaran.
            </Typography>
            <div style={{ paddingTop: 50 }}>
              <Grid container>
                <Grid item md={3}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                    Petunjuk Daftar:
                  </Typography>
                </Grid>
                <Grid item md={9} style={{ paddingTop: 15, paddingLeft: 0 }}>
                  <Divider
                    style={{
                      backgroundColor: '#F2C94C',
                      paddingBottom: 2,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ paddingTop: 10 }}>
              <Grid container>
                <Grid item md={1}>
                  <div>
                    <CheckIcon color='secondary' />
                  </div>
                  <div>
                    <CheckIcon color='secondary' />
                  </div>
                  <div>
                    <CheckIcon color='secondary' />
                  </div>
                </Grid>
                <Grid item md={11}>
                  <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                    Isi data pendaftaran di bawah ini dengan lengkap
                  </Typography>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 'bold', paddingTop: 5 }}
                  >
                    Klik tombol 'DAFTAR SEKARANG'
                  </Typography>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 'bold', paddingTop: 5 }}
                  >
                    Registrasi dikenakan biaya pendaftaran dan tidak dapat
                    dikembalikan
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ paddingTop: 40 }}>
              <Grid container>
                <Grid item md={2}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                    Data Diri:
                  </Typography>
                </Grid>
                <Grid item md style={{ paddingTop: 15, paddingLeft: 0 }}>
                  <Divider
                    style={{
                      backgroundColor: '#F2C94C',
                      paddingBottom: 2,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ paddingTop: 20 }}>
              <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                Nama
              </Typography>
              <TextField
                placeholder='Nama'
                variant='outlined'
                size='small'
                style={{ width: '100%', paddingTop: 5 }}
              />
            </div>

            <div style={{ paddingTop: 20 }}>
              <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                Email
              </Typography>
              <TextField
                placeholder='Email aktif yang sering dibuka'
                variant='outlined'
                size='small'
                style={{ width: '100%', paddingTop: 5 }}
              />
            </div>
            <div style={{ paddingTop: 20 }}>
              <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                No.Telepon / WhatsApp
              </Typography>
              <TextField
                placeholder='No.Telepon / WhatsApp aktif'
                variant='outlined'
                size='small'
                style={{ width: '100%', paddingTop: 5 }}
              />
            </div>
            <div style={{ paddingTop: 20 }}>
              <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                Domisil
              </Typography>
              <TextField
                placeholder='Nama daerah anda'
                variant='outlined'
                size='small'
                style={{ width: '100%', paddingTop: 5 }}
              />
            </div>
            <div style={{ paddingTop: 20 }}>
              <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                Usia
              </Typography>
              <TextField
                placeholder='Usia anda'
                variant='outlined'
                size='small'
                style={{ width: '100%', paddingTop: 5 }}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                variant='contained'
                color='primary'
                style={{
                  width: 328,
                  height: 48,
                  marginTop: 50,
                  marginBottom: 25,
                }}
                href='/Terima Kasih'
              >
                <Typography>Daftar</Typography>
              </Button>
            </div>
          </Card>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
    </div>
  );
}
