import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import gambars from '../../../Assets/Images/kerja.jpg';

export default function miniCard(props) {
  return (
    <div>
      <Grid container spacing={2} style={{ paddingBottom: 20 }}>
        <Grid item md={5}>
          <img
            src={gambars}
            style={{ width: '100%', height: 100, borderRadius: 10 }}
          />
        </Grid>
        <Grid item md={7}>
          <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
            {props.judul}
          </Typography>
          <Typography style={{ fontSize: 10, color: '#4F4F4F', paddingTop: 5 }}>
            {props.tanggal}
          </Typography>
          <Typography style={{ fontSize: 10, color: '#4F4F4F', paddingTop: 5 }}>
            {props.penerbit}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
