import React, { Component, Fragment } from "react";
import axios from "axios";

// * Material UI import
import {
  Typography,
  Grid,
  CssBaseline
} from "@material-ui/core";

// import { NavLink } from "react-router-dom";

// Component
import Header from '../Component/Header';
import Footer from '../Component/Footer';

import Menu from "../Component/Menu";
// Image
import AbstractImg from "../../Assets/Images/background_abstract.png";

// Icons
// import SearchIcon from '@material-ui/icons/Search';

import { withStyles } from '@material-ui/core/styles';


import PropTypes from 'prop-types';
import BreadCrumbs from "../Component/BreadCrumbs";
import "../../../node_modules/video-react/dist/video-react.css";

import { Player, PosterImage, BigPlayButton, LoadingSpinner } from 'video-react';

import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"

class DetailVideoLiveStreaming extends Component {

  constructor() {
    super();
    this.state = {
      dataVideo: [],
      dataVideoDetail: [],
    };
  }

  getDataAll() {
    const { category } = this.props.match.params;
    Api.getVideoCategoryByNameRequest(category).then(res=>{
      this.setState({ dataVideo: res.data })
    }).catch(err=>{
      console.log("error",err)
    })
  }

  changeDataDetail(category, title) {
    this.props.history.push("/video/video-detail/" + category + "/" + title + "/#");
    
    const data = {
      category:category,
      title:title
    }

    Api.getVideoContentRequest(data).then(res=>{
      this.setState({ dataVideoDetail: res.data[0] })
    }).catch(err=>{
      console.log("err",err)
    })

  }

  getDataDetail() {
    const { title, category } = this.props.match.params;
    const data = {
      category:category,
      title:title
    }

    console.log("Data",data)

    Api.getVideoContentRequest(data).then(res=>{
      this.setState({ dataVideoDetail: res.data[0] })
    }).catch(err=>{
      console.log("err",err)
    })
  }

  async fetchData(){
    await this.getDataAll();
    await this.getDataDetail();
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    const { classes } = this.props;
    const { title, category } = this.props.match.params;

    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Menu
          titleMenu={
            <div>
              <BreadCrumbs
                firstName="Video"
                secondName={category}
                colorFirstLink="inherit"
                activeLink="textPrimary"
                firstLink="/video"
                secondLink={"/video/video-detail/" + category + "/" + title + "/#"}
              />
              <div style={{ marginBottom: 40 }} />
            </div>
          }
        />
        {/* menu unit */}
        <main style={{ backgroundImage: 'linear-gradient(to right, #ffffff , #c4c4c4 )', height: "100%" }}>
          <div className={classes.container}>
            <Grid container className={classes.containerResponsive}>
              <Grid container spacing={4}>
                <Grid item lg={8} md={8} sm={7} xs={12}>
                  <Player
                    poster={StaticVar.URL_PORTAL + "/repo/" + this.state.dataVideoDetail.thumbnail}
                    fluid={true}
                    playsInline
                    src={StaticVar.URL_PORTAL + "/repo/" + this.state.dataVideoDetail.video}
                  >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                  </Player>
                  <div className={classes.containerTitleVideoDetail}>
                    <Typography className={classes.txtTitleVideoDetail}>{title}</Typography>
                  </div>
                </Grid>

                <Grid item lg={4} md={4} sm={5} xs={12} className={classes.containerVideoLainnya}>
                  <Grid item xs={12} style={{marginBottom:20}}>
                    <Typography>Video dan Live Streaming Lainnya</Typography>
                  </Grid>
                  {
                    this.state.dataVideo.map((item, index) => (
                      <Grid container spacing={3} key={index} style={{ cursor: 'pointer' }} onClick={() => { this.changeDataDetail(item.videos_category[0].name, item.title) }}>

                        <Grid item xs={6}>
                          <div
                            style={{backgroundImage: `url("${StaticVar.URL_PORTAL + "/repo/" + item.thumbnail}")`, backgroundRepeat: 'no-repeat', backgroundSize: '100%',height:'auto',minHeight:'100%',width:'100%',backgroundPosition:'center'}}
                          >
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ zIndex: 10, marginTop: 8, height: 67, width: '100%' }}>
                            <Typography className={classes.txtTitleVideo}>{item.title}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                    )
                    )
                  }
                  {/* <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 40, marginBottom: 40 }}>
                  <center>
                    <Button className={classes.btnLainnya}>
                      <Typography className={classes.txtLainnya}>Jelajahi Berita Lainnya</Typography>
                    </Button>
                  </center>
                </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

DetailVideoLiveStreaming.propTypes = {
  classes: PropTypes.object.isRequired,
};

PosterImage.propTypes = {
  poster: PropTypes.string,
}

const useStyles = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      background: 'none'
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%'
    }
  },
  containerResponsive: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 30,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 40,
      paddingRight: 50,
    },
    paddingTop: 40,
  },
  containerTitleVideoDetail:{
    [theme.breakpoints.down('xs')]: {
      marginTop:20,
      height: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop:20,
      height: 20,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 40,
      height: 67,
    },
    zIndex: 10,  width: '100%'
  },
  breadCrumbs: {
    backgroundColor: '#ffffff',
    height: 30,
    paddingTop: 2,
    paddingLeft: 10,
    borderRadius: 5,
    paddingRight: 10,
  },
  txtVideoDetail: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      top: -10,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 13,
      top: -30,
    },
    position: 'relative',
    color: '#dbdbdb',
    fontWeight: 200
  },
  containerVideoLainnya:{
    [theme.breakpoints.down('sm')]: {
      marginBottom:30
    },
    [theme.breakpoints.up('md')]: {
      marginBottom:10
    },
  },

  txtTitleVideoDetail: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      top: -10,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 17,
      top: -30,
    },
    color: '#000000',
    lineHeight: 1.1,
    fontWeight: 200,
    position: 'relative',
  },
  txtVideo: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 11,
    },
    color: '#dbdbdb',
    fontWeight: 200
  },

  txtTitleVideo: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginTop: 10,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
    color: '#000000',
    lineHeight: 1.1,
    fontWeight: 200,
    marginBottom: 5
  },
  btnLainnya: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    border: '2px solid #bf272b', borderRadius: 5,
    height: 48
  },

  txtLainnya: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 15
    },
    textTransform: 'none', color: '#bf272b'
  },
  btnSearch: {
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
    [theme.breakpoints.up('md')]: {
      width: '15%',
    },
    backgroundColor: "#7d7d7d",
    height: 57
  },
  txtSearch: {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginBottom: 10
    },
    [theme.breakpoints.up('md')]: {
      width: '85%',
      marginBottom: 30
    },
    backgroundColor: '#bfbfbf',
  },
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 27,
    },
  }
})

export default withStyles(useStyles)(DetailVideoLiveStreaming);