import React, { useState, useEffect } from 'react';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import Api from '../../Services/Api';
import kontak from '../../Assets/Images/Contact-us2.png';
import Background from '../../Assets/Images/bg_KK_PK.png';

export default function Kontak() {
  const [content, setContent] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    Api.getPagesRequest(localStorage.pages).then(res => {
      setContent(res.data);
      console.log('Data Content', res.data);
    });
    Api.getPagesRequest().then(res => {
      setPages(res.data);
      console.log('Data Pages', res.data);
    });
  }, []);

  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            {pages
              .filter(x => x.name === 'Kontak Kami')
              .map(item => (
                <Typography
                  style={{
                    fontSize: 24,
                    color: '#ffffff',
                    fontWeight: 'bold',
                  }}
                >
                  {item.name}
                </Typography>
              ))}
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}>
        {content
          .filter(x => x.name === 'Kontak Kami')
          .map(item => (
            <div
              dangerouslySetInnerHTML={{
                __html: item.content,
              }}
            ></div>
          ))}
        {/* <div style={{ height: 300, backgroundColor: 'yellow' }}>
          <Typography>Google Maps</Typography>
        </div> */}
        <div>
          <Grid container>
            <Grid item md>
              <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
                Tanyakan Sesuatu
              </Typography>
              <Grid container style={{ paddingTop: 10 }}>
                <Grid item md={6}>
                  <Typography
                    style={{ fontSize: 14, fontWeight: 'bold', paddingTop: 5 }}
                  >
                    Nama Lengkap
                  </Typography>
                  <TextField
                    variant='outlined'
                    placeholder='Nama Lengkap'
                    style={{
                      backgroundColor: 'white',
                      width: '90%',
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography
                    style={{ fontSize: 14, fontWeight: 'bold', paddingTop: 5 }}
                  >
                    Nama Email
                  </Typography>
                  <TextField
                    variant='outlined'
                    placeholder='Nama Email'
                    style={{
                      backgroundColor: 'white',
                      width: '100%',
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Typography
                    style={{ fontSize: 14, fontWeight: 'bold', paddingTop: 5 }}
                  >
                    Pesan
                  </Typography>
                  <TextField
                    variant='outlined'
                    size='large'
                    style={{
                      backgroundColor: 'white',
                      width: '100%',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md>
              <img src={kontak} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
