import React, { Component } from 'react';

// * Material UI import
import {
  Typography,
  Grid,
  Hidden,
  Button,
  withStyles,
} from '@material-ui/core';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import logoFooterIcon from '../../Assets/Images/Forki2.png';
import logo from '../../Assets/Images/LEMKARITransparent.png';
import KemenPemudaOlahragaIcon from '../../Assets/Images/kemenpemuda_olahraga.png';
import LogoBrilyanIcon from '../../Assets/Images/logo_brilyan.png';
import LogoPapuaIcon from '../../Assets/Images/logo_papua.png';
import NPCIcon from '../../Assets/Images/national-paralympic.png';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Api from '../../Services/Api';

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      event_support: [],
      sitemap: [],
    };
  }

  getDataSiteMap() {
    Api.getSitemapRequest()
      .then(res => {
        this.setState({ sitemap: res.data });
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  fetchData() {
    this.getDataSiteMap();
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { sitemap } = this.state;
    const { classes } = this.props;

    return (
      <div className='bg-image'>
        <div>
          <Grid container style={{ padding: 25 }}>
            <Hidden smDown>

              <Grid item md={1}>
                <img
                  src={logoFooterIcon}
                  alt='logoFooter'
                  style={{ height: 65, width: 58, marginLeft: 3 }}
                />
              </Grid>

              <Grid item md={1}>
                <img
                  src={logo}
                  alt='logoFooter'
                  style={{ height: 65, width: 65 }}
                />
              </Grid>
                    
              
              <Grid
                item
                md={10}
                style={{
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: 24,
                  color: 'white',
                  marginTop: '1%',
                }}
              >
                Lembaga Karate - Do Indonesia (Lemkari)
              </Grid>
              
                    
              {sitemap.map((item, index) => (
                item.position === "Header & Footer" ? (
                  item.type === "Parent" ? (
                    item.category === "Hanya Menu" ? (
                    
                      
                     <Grid item className={classes.spacing} style={{marginTop: 30}}  key={index} 
                     >
                        <Typography className={classes.titleFooterTxt} style={{marginTop: 30}}>
                          {item.title}
                        </Typography>
                        {sitemap.map((val, index) => (
                          val.type === "Child" ? (
                            val.parent === item._id ? (
                              val.link === "" ? (
                                <Grid
                                  key={index}
                                  component={NavLink}
                                  to={val.base_URL}
                                >
                                  <Typography className={classes.listTxt}>
                                    {val.title}
                                  </Typography>
                                </Grid>
                                
                              ):(
                                <a href={val.link} key={index} style={{textDecoration: 'none'}}>
                                  <Typography className={classes.listTxt}>
                                    {val.title}
                                  </Typography>
                                </a>
                              )
                            ):null
                          ):null
                        ))}
                      </Grid>
                    ) : item.link === "" ? (
                      <Grid
                        item
                        key={index}
                        className={classes.spacing}
                        component={NavLink}
                        to={item.base_URL}
                        style={{marginTop: 30}}
                      >
                        <Typography className={classes.titleFooterTxt}>
                          {item.title}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item key={index} className={classes.spacing} style={{marginTop: 60}}>
                        <a href={item.link} style={{textDecoration: 'none'}}>
                          <Typography className={classes.titleFooterTxt} >
                            {item.title}
                          </Typography>
                        </a>
                      </Grid>
                    )
                  ) : null ):
                item.position === "Footer" ? ( 
                  item.type === "Parent" ? (
                    item.category === "Hanya Menu" ? (
                      <Grid item className={classes.spacing} key={index} style={{marginTop: 60}}>
                        <Typography className={classes.titleFooterTxt}>
                          {item.title}
                        </Typography>
                        {sitemap.map((val, index) => (
                          val.type === "Child" ? (
                            val.parent === item._id ? (
                              val.link === "" ? (
                                <Grid
                                  key={index}
                                  component={NavLink}
                                  to={val.base_URL}
                                  style={{marginTop: 30}}
                                >
                                  <Typography className={classes.listTxt}>
                                    {val.title}
                                  </Typography>
                                </Grid>
                              ):(
                                <a href={val.link} key={index} style={{textDecoration: 'none'}}>
                                  <Typography className={classes.listTxt}>
                                    {val.title}
                                  </Typography>
                                </a>
                              )
                            ):null
                          ):null
                        ))}
                      </Grid>
                    ) : item.link === "" ? (
                      <Grid
                        item
                        key={index}
                        className={classes.spacing}
                        component={NavLink}
                        to={item.base_URL}
                        style={{marginTop: 30}}
                      >
                        <Typography className={classes.titleFooterTxt}>
                          {item.title}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item key={index} className={classes.spacing} style={{marginTop: 30}}>
                        <a href={item.link} style={{textDecoration: 'none'}}>
                          <Typography className={classes.titleFooterTxt}>
                            {item.title}
                          </Typography>
                        </a>
                      </Grid>
                    )
                  ) : null
                ): null
                
              ))}
              

              
              

              {/* <Grid item md={2} spacing={2} style={{ marginTop: 20 }}>
                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Tentang Lemkari
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://accreditation.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Sejarah Karate
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://sports.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Sejarah Lemkari
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://volunteer.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Arti Lambang Lemkari
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>AD/ART</Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Visi & Misi
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Pengurus Besar
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Dewan Guru
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Pengurus Provinsi
                  </Typography>
                </a>
              </Grid>
              <Grid item md={2} spacing={3} style={{ marginTop: 20 }}>
                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Berita & Galeri
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>Berita</Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Galeri Foto
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Video dan Live Streaming
                  </Typography>
                </a>
              </Grid>
              <Grid item md={1} spacing={3} style={{ marginTop: 20 }}>
                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>Event</Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>Event</Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Tournament
                  </Typography>
                </a>

                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>K-Exam</Typography>
                </a>
              </Grid>

              <Grid item md={1} spacing={3} style={{ marginTop: 20 }}>
                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Program Kerja
                  </Typography>
                </a>
              </Grid>

              <Grid item md={1} spacing={3} style={{ marginTop: 20 }}>
                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>Statistik</Typography>
                </a>
              </Grid>

              <Grid item md={2} spacing={3} style={{ marginTop: 20 }}>
                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Rangking Nasional
                  </Typography>
                </a>
              </Grid>

              <Grid
                item
                md={1}
                spacing={3}
                style={{ marginTop: 20, marginRight: 30 }}
              >
                <a
                  style={{ textTransform: 'none', textDecoration: 'none' }}
                  href='https://entries.ponxx2020papua.com'
                >
                  <Typography className={classes.listTxt}>
                    Kontak Kami
                  </Typography>
                </a>
              </Grid> */}

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 300,
                    color: '#ffffff',
                    marginTop: 20,
                  }}
                >
                  Ikuti kami di
                </Typography>
              </Grid>

              <Grid item md={12} style={{ zIndex: 10, marginTop: 20 }}>
                <Button style={{ position: 'absolute' }}>
                  <FacebookIcon className={classes.iconFollowUs} />
                </Button>
                <Button style={{ position: 'absolute', marginLeft: 45 }}>
                  <InstagramIcon className={classes.iconFollowUs} />
                </Button>
                <Button style={{ position: 'absolute', marginLeft: 90 }}>
                  <YouTubeIcon className={classes.iconFollowUs} />
                </Button>
                <Button style={{ position: 'absolute', marginLeft: 135 }}>
                  <TwitterIcon className={classes.iconFollowUs} />
                </Button>
              </Grid>

              <Grid item md={9}>
                <div></div>
              </Grid>

              <Grid item xs={12} style={{ paddingLeft: '37%' }}>
                <Typography
                  style={{
                    fontFamily: 'Barlow',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    color: '#ffffff',
                  }}
                >
                  ©Copyright Lemkari Powered by Brilyan Trimatra Utama
                </Typography>
              </Grid>
            </Hidden>

            {/* Menu Mobile */}

            <Hidden mdUp>
            <Grid
              container
              style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 10 }}
            >
               <Grid container style={{marginBottom: 20}}
            >
             <Grid item sm={1} xs={1}>
                <img
                  src={logoFooterIcon}
                  alt='logoFooter'
                  style={{ height: 35, width: 28}}
                />
              </Grid>

              <Grid item sm={2} xs={2}>
                <img
                  src={logo}
                  alt='logoFooter'
                  style={{ height: 35, width: 35, marginLeft: 10 }}
                />
              </Grid>
                    
              
              <Grid
                item
                sm={9}
                xs={9}
                style={{
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: 'white',
                  marginTop: -1
                  
                  
                }}
              >
                Lembaga Karate - Do Indonesia (Lemkari)
              </Grid>
              </Grid>
              {sitemap.map((item, index) => (
                item.position === "Header & Footer" ? (
                  item.type === "Parent" ? (
                    item.category === "Hanya Menu" ? (
                      <Grid item xs={12} key={index}>
                        <ExpansionPanel>
                          <ExpansionPanelSummary
                            expandIcon={
                              <KeyboardArrowRightIcon
                                style={{ color: "white" }}
                              />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              className={classes.txtMenuMobileExpandSummary}
                            >
                              {item.title}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Grid container>
                              {
                              sitemap.map((val, index) => (
                                val.type === "Child" ? (
                                  val.parent === item._id ? (
                                    val.link === "" ? (
                                      <Button
                                          key={index}
                                          style={btnMenuMobile}
                                          component={NavLink}
                                          to={val.base_URL}
                                        >
                                          <Typography
                                            className={
                                              classes.txtMenuMobileExpandDetail
                                            }
                                          >
                                            {val.title}
                                          </Typography>
                                        </Button>
                                    ):(
                                      <Grid item xs={12} key={index}>
                                          <a href={val.link}>
                                            <Button style={btnMenuMobile}>
                                              <Typography
                                                className={
                                                  classes.txtMenuMobileExpandDetail
                                                }
                                              >
                                                {val.title}
                                              </Typography>
                                            </Button>
                                          </a>
                                        </Grid>
                                    )
                                  ):null
                                ):null
                              ))
                              }
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </Grid>
                    ) : item.link === "" ? (
                      <Grid item xs={12} key={index}>
                        <Button
                          className={classes.btnMenuMobile}
                          component={NavLink}
                          to={item.base_URL}
                        >
                          <Typography className={classes.txtMenuMobile}>
                            {item.title}
                          </Typography>
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={12} key={index}>
                        <a href={item.link}>
                          <Button className={classes.btnMenuMobile}>
                            <Typography className={classes.txtMenuMobile}>
                              {item.title}
                            </Typography>
                          </Button>
                        </a>
                      </Grid>
                    )
                  ) : null
                ) :
                item.position === "Footer" ? (
                item.type === "Parent" ? (
                    item.category === "Hanya Menu" ? (
                      <Grid item xs={12} key={index}>
                        <ExpansionPanel>
                          <ExpansionPanelSummary
                            expandIcon={
                              <KeyboardArrowRightIcon
                                style={{ color: "white" }}
                              />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              className={classes.txtMenuMobileExpandSummary}
                            >
                              {item.title}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Grid container>
                              {
                              sitemap.map((val, index) => (
                                val.type === "Child" ? (
                                  val.parent === item._id ? (
                                    val.link === "" ? (
                                      <Button
                                          key={index}
                                          style={btnMenuMobile}
                                          component={NavLink}
                                          to={val.base_URL}
                                        >
                                          <Typography
                                            className={
                                              classes.txtMenuMobileExpandDetail
                                            }
                                          >
                                            {val.title}
                                          </Typography>
                                        </Button>
                                    ):(
                                      <Grid item xs={12} key={index}>
                                          <a href={val.link}>
                                            <Button style={btnMenuMobile}>
                                              <Typography
                                                className={
                                                  classes.txtMenuMobileExpandDetail
                                                }
                                              >
                                                {val.title}
                                              </Typography>
                                            </Button>
                                          </a>
                                        </Grid>
                                    )
                                  ):null
                                ):null
                              ))
                              }
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </Grid>
                    ) : item.link === "" ? (
                      <Grid item xs={12} key={index}>
                        <Button
                          className={classes.btnMenuMobile}
                          component={NavLink}
                          to={item.base_URL}
                        >
                          <Typography className={classes.txtMenuMobile}>
                            {item.title}
                          </Typography>
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={12} key={index}>
                        <a href={item.link}>
                          <Button className={classes.btnMenuMobile}>
                            <Typography className={classes.txtMenuMobile}>
                              {item.title}
                            </Typography>
                          </Button>
                        </a>
                      </Grid>
                    )
                  ) : null
                ):null
              ))}

           
              {/* <Grid item xs={12}/> */}
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography
                  style={{ fontSize: 13, fontWeight: 300, color: "#ffffff" }}
                >
                  Ikuti kami di
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ zIndex: 10 }}>
                <div>
                  <FacebookIcon className={classes.iconFollowUs} />
                  <InstagramIcon className={classes.iconFollowUs} />
                  <YouTubeIcon className={classes.iconFollowUs} />
                  <TwitterIcon className={classes.iconFollowUs} />
                </div>
              </Grid>
              <Grid item xs={12}>
              <Typography
                  style={{
                    fontFamily: 'Barlow',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: 10,
                    color: '#ffffff',
                  }}
                >
                  ©Copyright Lemkari Powered by Brilyan Trimatra Utama
                </Typography>
             </Grid>
             
            </Grid>

          </Hidden>
          </Grid>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

// ====================> Style Mobile Responsive <==========================

const styles = theme => ({
  titleFooterTxt: {
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
    color: '#ffffff',
    fontWeight: 200,
    marginBottom: 18,
  },

  listTxt: {
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
    },
    color: '#ffffff',
    fontWeight: 100,
    marginBottom: 10,
    textTransform: 'none',
  },

  spacing: {
    [theme.breakpoints.down('md')]: {
      marginRight: 20,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 30,
    },
  },

  spacing1: {
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 40,
    },
  },

  spacing2: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 40,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
  },

  btnMenuMobile: {
    [theme.breakpoints.down('xs')]: {
      height: 35,
    },
    [theme.breakpoints.up('sm')]: {
      height: 35,
    },
    width: '100%',
    zIndex: 10,
    borderBottom: '2px solid #ffffff',
    borderRadius: 0,
    height: 47,
  },
  txtMenuMobile: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    position: 'absolute',
    left: 0,
    textTransform: 'none',
    fontWeight: 300,
    color: '#ffffff',
  },

  txtMenuMobileExpandSummary: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    position: 'absolute',
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 300,
    color: '#ffffff',
    bottom: 10,
  },

  txtMenuMobileExpandDetail: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
    position: 'absolute',
    left: 0,
    textTransform: 'none',
    fontWeight: 200,
    color: '#ffffff',
  },

  logoFooterIconStyle: {
    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 60,
    },
    [theme.breakpoints.up('sm')]: {
      width: 50,
      height: 60,
    },
    [theme.breakpoints.up('md')]: {
      width: 50,
      height: 60,
    },
  },
  iconFollowUs: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 20
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 25
    },
    color: "white",
    marginRight: 20,
    zIndex: 10
  },
  // containerFooter: {
  //   height: 650,
  //   paddingLeft: 24,
  //   paddingRight: 24,
  // },
});

const ExpansionPanel = withStyles({
  root: {
    zIndex: 10,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '2px solid #ffffff',
    height: 35,
    padding: 0,
    minHeight: 0,
    '&$expanded': {
      height: 35,
      minHeight: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

const btnMenuMobile = {
  width: '100%',
  height: 35,
};

export default withStyles(styles)(Footer);
