import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import gambars from '../../../Assets/Images/kerja.jpg';
import { Link } from 'react-router-dom';

export default function FotoBerita(props) {
  return (
    <div>
      <Grid container spacing={2} style={{ paddingBottom: 20 }}>
        <Grid item md={12}>
          <div>
            <img
              src={gambars}
              style={{ width: '100%', height: 200, borderRadius: 10 }}
            />
            <Typography
              style={{
                color: 'yellow',
                fontSize: 18,
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: -50,
              }}
            >
              {props.nama}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
