import React from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Divider,
  Card,
  Link,
  Button,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';

import DateRangeIcon from '@material-ui/icons/DateRange';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonIcon from '@material-ui/icons/Person';
import CallIcon from '@material-ui/icons/Call';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import FotoCard from '../Component/listCard/498Foto';
import FotoDokumentasi from '../Component/listCard/200Berita';

export default function EventSelesai() {
  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundColor: '#d6261d',
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              <Link href='/Event' color='inherit'>
                Event
              </Link>{' '}
              / Event yang sudah selesai
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div>
        <FotoCard />
      </div>
      <div style={{ paddingLeft: 40, paddingRight: 40 }}>
        <Grid container>
          <Grid item md={11}>
            <Typography style={{ fontSize: 36, fontWeight: 'bold' }}>
              Pelatihan berbasis sport-science untuk pelatih
            </Typography>
          </Grid>
          <Grid item md={1} style={{ paddingTop: 20 }}>
            <div
              style={{
                width: 94,
                height: 20,
                backgroundColor: '#51C273',
                textAlign: 'center',
                borderRadius: 4,
              }}
            >
              <Typography
                style={{ fontSize: 10.5, color: 'white', paddingTop: 2 }}
              >
                Selesai
              </Typography>
            </div>
          </Grid>
        </Grid>
        <div>
          <Typography
            style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
          >
            Waktu Pelaksana
          </Typography>
          <Grid container>
            <Grid item md={1} style={{ marginRight: -70 }}>
              <div style={{ paddingTop: 10 }}>
                <DateRangeIcon />
              </div>
              <div style={{ paddingTop: 10 }}>
                <AccessTimeIcon />
              </div>
            </Grid>
            <Grid item md={11}>
              <Typography style={{ fontSize: 18, paddingTop: 10 }}>
                Selasa-Minggu, 03-08 Sep 2019
              </Typography>
              <Typography style={{ fontSize: 18, paddingTop: 10 }}>
                15.00 WIB s/d selesai
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div>
          <Typography
            style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
          >
            Kontak Person
          </Typography>
          <Grid container>
            <Grid item md={1} style={{ marginRight: -70 }}>
              <div style={{ paddingTop: 10 }}>
                <PersonIcon />
              </div>
              <div style={{ paddingTop: 10 }}>
                <CallIcon />
              </div>
            </Grid>
            <Grid item md={11}>
              <Typography style={{ fontSize: 18, paddingTop: 10 }}>
                Reski Damayanti
              </Typography>
              <Typography style={{ fontSize: 18, paddingTop: 10 }}>
                087456789001
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div>
          <Typography
            style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
          >
            Deskripsi
          </Typography>
          <Typography style={{ fontSize: 18, paddingTop: 10 }}>
            Pelatihan ini bertujuan untuk menawarkan dan memperkenalkan para
            pelatih sebuah pendekataan ...
          </Typography>
        </div>
        <div>
          <Typography
            style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
          >
            Jadwal Acara
          </Typography>
          <div style={{ paddingTop: 20, textAlign: 'center' }}>
            <Button
              variant='contained'
              color='primary'
              style={{ width: 328, height: 48 }}
            >
              <Typography>Lihat Jadwal Acara</Typography>
            </Button>
          </div>
        </div>
        <div>
          <Typography
            style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
          >
            Keterangan Peserta
          </Typography>
          <Typography style={{ fontSize: 18, paddingTop: 10 }}>
            Para pelatih Lemkari
          </Typography>
        </div>
        <div>
          <Typography
            style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
          >
            Info Lokasi
          </Typography>
          <Grid container>
            <Grid item md={1} style={{ marginRight: -70 }}>
              <div style={{ paddingTop: 10 }}>
                <LocationOnIcon color='secondary' />
              </div>
            </Grid>
            <Grid item md={11}>
              <Typography style={{ fontSize: 18, paddingTop: 10 }}>
                Gedung Sarbini Wiladatika-Cibubur, Depok - Jawa Barat
              </Typography>
              <Typography
                style={{ fontSize: 16, paddingTop: 5, color: '#828282' }}
              >
                Jl.Pusdika Raya No.6B Harjamukti Kec.Cimanggis kota Depok Jawa
                Barat 16454
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            width: '100%',
            height: 300,
            paddingTop: 30,
            textAlign: 'center',
            backgroundColor: 'white',
            marginTop: 20,
          }}
        >
          <Typography>Google Maps Disini</Typography>
        </div>
        <div>
          <Typography
            style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
          >
            Dokumentasi Event
          </Typography>
          <Grid container spacing={3} style={{ paddingTop: 10 }}>
            <Grid item md={3}>
              <FotoDokumentasi />
            </Grid>
            <Grid item md={3}>
              <FotoDokumentasi />
            </Grid>
            <Grid item md={3}>
              <FotoDokumentasi />
            </Grid>
            <Grid item md={3}>
              <FotoDokumentasi />
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingTop: 60, paddingBottom: 60 }}>
          <Divider />
        </div>
        <div>
          <Typography
            style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
          >
            Berita
          </Typography>
          <Grid container spacing={3} style={{ paddingTop: 10 }}>
            <Grid item md={3}>
              <FotoDokumentasi
                tanggal='08 Juni 2020'
                penerbit='Lemkari Post'
                judul='Seminar Karate Perdana di Kota Bekasi'
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
