import React, { useState, useEffect } from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Link,
  Avatar,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import images from '../../Assets/Images/man.png';
import Background from '../../Assets/Images/bg_KK_PK.png';

import Profilecard from '../Component/CardProfile';
import ApiPertama from '../../Services/Api1';

function PengurusProvinsi() {
  const classes = useStyles();

  const [pengurusProv, setPengurusProv] = useState([]);
  const [IDProvinsi, setIDProvinsi] = useState('');
  const [nama, setNama] = useState('');
  const [id, setId] = useState('');

  // useEffect(() => {
  //   ApiPertama.getPengProv().then(res => {
  //     setPengurusProv(res.data);
  //     console.log('Data Pengprov', res.data);
  //   });
  // }, []);

  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={9}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              Pengurus Provinsi
            </Typography>
          </Grid>
          {/* <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid> */}
          <Grid item md={3} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: 20, paddingLeft: 40, paddingRight: 40 }}>
        <Grid container>
          <Grid item md={9} style={{ paddingRight: 50 }}>
            <div>
              <Grid container>
                <Grid item md={3}>
                  <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
                    Pilih Provinsi
                  </Typography>
                </Grid>
                <Grid item md>
                  <TextField
                    variant='outlined'
                    size='small'
                    select
                    SelectProps={{
                      native: true,
                    }}
                    style={{ backgroundColor: 'white', width: 400 }}
                    // value={IDProvinsi}
                    // onChange={e => {
                    //   setIDProvinsi(e.target.value);
                    //   console.log('ID', e.target.value);
                    //   pengurusProv
                    //     .filter(x => x._id === e.target.value)
                    //     .map(
                    //       item => setNama(item.NAMA_MANAGER)
                    //       // setId(item_id)
                    //       // console.log('nama', item._id)
                    //     );
                    // }}
                    nama='pengurusProv'
                  >
                    <option value=''>Pilih Provinsi</option>
                    <option value=''>Aceh</option>
                    {/* {pengurusProv.map(x => (
                      <option value={x._id}>{x.Nama_Organisasi}</option>
                    ))} */}
                  </TextField>
                </Grid>
              </Grid>
            </div>
            <Typography
              style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
            >
              Susunan Pengurus Lemkari Provinsi Bangka Belitung Masa Bakti
              2016-2020
            </Typography>

            <div style={{ paddingTop: 20 }}>
              <Grid container spacing={5}>
                {/* {pengurusProv
                  .filter(x => x.Nama_Organisasi === IDProvinsi)
                  .map(item => {
                    console.log('cek data', IDProvinsi);
                    return (
                      <Grid item md={4}>
                        <Profilecard
                          nama={item.NAMA_MANAGER}
                          jabatan='Ketua '
                        />
                      </Grid>
                    );
                  })} */}
                <Grid item md={4}>
                  <Profilecard
                    nama=' Rosi Saputra, ST'
                    jabatan='Ketua Harian'
                  />
                </Grid>
                <Grid item md={4}>
                  <Profilecard
                    nama=' Rosi Saputra, ST'
                    jabatan='Ketua Harian'
                  />
                </Grid>
                <Grid item md={4}>
                  <Profilecard
                    nama=' Zilman, S.Pd.I.'
                    jabatan='Ketua MSH/Komisi Teknik'
                  />
                </Grid>
                <Grid item md={3} />
                <Grid item md={6} style={{ textAlign: 'center' }}>
                  <div>
                    <Pagination
                      count={10}
                      // variant='outlined'
                      shape='rounded'
                      color='primary'
                    />
                  </div>
                </Grid>
                <Grid item md={3} />
              </Grid>
            </div>
          </Grid>
          <Grid item md={3}>
            <div>
              <List style={{ marginTop: -20 }}>
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Sejarah Karate' color='inherit'>
                      <Typography>Sejarah Karate</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Sejarah Lemkari' color='inherit'>
                      <Typography>Sejarah Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Arti Lambang Lemkari' color='inherit'>
                      <Typography>Arti Lambang Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/AD & ART' color='inherit'>
                      <Typography>AD/ART</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Visi & Misi' color='inherit'>
                      <Typography>Visi & Misi</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Pengurus Besar' color='inherit'>
                      <Typography>Pengurus Besar</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Dewan Guru' color='inherit'>
                      <Typography>Dewan Guru</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Pengurus Provinsi' color='inherit'>
                      <Typography>Pengurus Provinsi</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
              </List>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  normal: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

export default PengurusProvinsi;
