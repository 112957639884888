import React, { useState, Fragment } from 'react';

import image from '../../Assets/Images/image-menu.png';
import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Link,
} from '@material-ui/core';
// import { Link } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import TablePagination from '@material-ui/core/TablePagination';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Background from "../../Assets/Images/bg_KK_PK.png";
import logo from '../../Assets/Images/logo_papua.png';

import Header from '../Component/Header';
import CssBaseline from '@material-ui/core/CssBaseline';

function RangkingNasional() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              Rangking Nasional
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingLeft: 40, paddingTop: 20 }}>
        <Grid container>
          <Grid item md={1} style={{ paddingTop: 40 }}>
            <Typography>Filter:</Typography>
          </Grid>
          <Grid item md={2} style={{ paddingRight: 30 }}>
            <Typography>Kategori Usia</Typography>
            <TextField
              variant='outlined'
              label='Cadet Putra'
              size='small'
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ paddingRight: 30 }}>
            <Typography>Kelas Pertandingan</Typography>
            <TextField
              variant='outlined'
              label='Kumite-57 Kg'
              size='small'
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2}>
            <Typography>Provinsi</Typography>
            <TextField
              variant='outlined'
              label='Semua Provinsi'
              size='small'
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: 20, paddingLeft: 40, paddingRight: 40 }}>
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead style={{ backgroundColor: '#D6261D' }}>
              <TableRow style={{ height: 60 }}>
                <StyledTableCell>Rank</StyledTableCell>
                <StyledTableCell>Nama</StyledTableCell>
                <StyledTableCell>Provinsi</StyledTableCell>
                <StyledTableCell>Poin</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>1</StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={1} style={{ paddingRight: 50 }}>
                      <Avatar>A</Avatar>
                    </Grid>
                    <Grid item md={7} style={{ paddingTop: 7 }}>
                      <Typography>
                        {' '}
                        Ahmad Maulana Syarif Hidayatullah
                      </Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={2} style={{ paddingRight: 50 }}>
                      <img src={logo} style={{ width: 38, height: 38 }} />
                    </Grid>
                    <Grid item style={{ paddingTop: 7 }}>
                      <Typography>Jawa Barat</Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>1234</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<KeyboardArrowRightIcon />}
                    href='/Detail Rangking'
                  >
                    Lihat
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>2</StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={1} style={{ paddingRight: 50 }}>
                      <Avatar>Y</Avatar>
                    </Grid>
                    <Grid item md={7} style={{ paddingTop: 7 }}>
                      <Typography> Yusuf Kefin</Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={2} style={{ paddingRight: 50 }}>
                      <img src={logo} style={{ width: 38, height: 38 }} />
                    </Grid>
                    <Grid item style={{ paddingTop: 7 }}>
                      <Typography>Bali</Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>1234</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<KeyboardArrowRightIcon />}
                  >
                    Lihat
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>3</StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={1} style={{ paddingRight: 50 }}>
                      <Avatar>N</Avatar>
                    </Grid>
                    <Grid item md={7} style={{ paddingTop: 7 }}>
                      <Typography> Nandi Sujani</Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={2} style={{ paddingRight: 50 }}>
                      <img src={logo} style={{ width: 38, height: 38 }} />
                    </Grid>
                    <Grid item style={{ paddingTop: 7 }}>
                      <Typography>Sulawesi Selatan</Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>1234</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<KeyboardArrowRightIcon />}
                  >
                    Lihat
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>4</StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={1} style={{ paddingRight: 50 }}>
                      <Avatar>S</Avatar>
                    </Grid>
                    <Grid item md={7} style={{ paddingTop: 7 }}>
                      <Typography> Supriatno</Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={2} style={{ paddingRight: 50 }}>
                      <img src={logo} style={{ width: 38, height: 38 }} />
                    </Grid>
                    <Grid item style={{ paddingTop: 7 }}>
                      <Typography>Jawa Timur</Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>1234</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<KeyboardArrowRightIcon />}
                  >
                    Lihat
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>5</StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={1} style={{ paddingRight: 50 }}>
                      <Avatar>M</Avatar>
                    </Grid>
                    <Grid item md={7} style={{ paddingTop: 7 }}>
                      <Typography> Muhammad Fajar</Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>
                  <Grid container>
                    <Grid item md={2} style={{ paddingRight: 50 }}>
                      <img src={logo} style={{ width: 38, height: 38 }} />
                    </Grid>
                    <Grid item style={{ paddingTop: 7 }}>
                      <Typography>Kalimantan Barat</Typography>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>1234</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<KeyboardArrowRightIcon />}
                  >
                    Lihat
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            //   count={hotel.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  );
}

const StyledTableCell = withStyles(theme => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default RangkingNasional;
