import React from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Link,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import NormalVideo from '../Component/allCard/230Card';
import MiniVideo from '../Component/allCard/150Card';

import Background from "../../Assets/Images/bg_KK_PK.png";

export default function videoLiveStreaming() {
  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              Video & Live Streaming
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}
        >
          <Grid item md={9} style={{ paddingRight: 50 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Video Terbaru
            </Typography>

            <div style={{ paddingTop: 10 }}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <NormalVideo
                    tanggal='9 Juni 2020'
                    judul='Sejarah Karate di Indonesia'
                  />
                </Grid>
                <Grid item md={6}>
                  <NormalVideo
                    tanggal='9 Juni 2020'
                    judul='Kejurnas Karate Do Lemkari 2019'
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ paddingTop: 20 }}>
                <Grid item md={4}>
                  <MiniVideo
                    tanggal='09 Juni 2020'
                    judul='Kejuaraan Karate Virtual Direstui Paman Birin'
                  />
                </Grid>
                <Grid item md={4}>
                  <MiniVideo
                    tanggal='9 Juni 2020'
                    judul='Kejuaraan Karate Virtual Direstui Paman Birin'
                  />
                </Grid>
                <Grid item md={4}>
                  <MiniVideo
                    tanggal='9 Juni 2020'
                    judul='Kejuaraan Karate Virtual Direstui Paman Birin'
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={3}>
            <div>
              <List style={{ marginTop: -10 }}>
                <div>
                  <Typography
                    style={{
                      fontSize: 24,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    Kategori Berita
                  </Typography>
                </div>
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Lemkari' color='inherit'>
                      <Typography>Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='' color='inherit'>
                      <Typography>Karate</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='' color='inherit'>
                      <Typography>Umum</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
              </List>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
