import React from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Link,
  Avatar,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import karate from '../../Assets/Images/karate.jpg';
import Background from '../../Assets/Images/bg_KK_PK.png';

export default function MenuUtama() {
  return (
    <>
      <div style={{ paddingBottom: 50, marginTop: 80 }}>
        <div
          style={{
            //   width: '100%',
            height: 80,
            backgroundImage: `url(${Background})`,
            padding: 20,
          }}
        >
          <Grid container>
            <Grid item md={7}>
              <Typography
                style={{
                  fontSize: 24,
                  color: '#ffffff',
                  fontWeight: 'bold',
                }}
              >
                Sejarah Karate di Indonesia
              </Typography>
            </Grid>
            <Grid item={1}>
              <TextField
                variant='outlined'
                label='Searching'
                size='small'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ backgroundColor: 'white' }}
              />
            </Grid>

            <Grid item md={2} style={{ textAlign: 'right' }}>
              <IconButton>
                <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
              </IconButton>
              <IconButton>
                <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
              </IconButton>
              <IconButton>
                <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
              </IconButton>
              <IconButton>
                <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}
          >
            <Grid item md={9}>
              <div>
                <img
                  src={karate}
                  style={{
                    width: '100%',
                    height: 380,
                    paddingRight: 50,
                    //   borderRadius: 20,
                  }}
                />
              </div>
            </Grid>
            <Grid item md={3}>
              <div>
                <List style={{ marginTop: -20 }}>
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Sejarah Karate' color='inherit'>
                        <Typography>Sejarah Karate</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Sejarah Lemkari' color='inherit'>
                        <Typography>Sejarah Lemkari</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Arti Lambang Lemkari' color='inherit'>
                        <Typography>Arti Lambang Lemkari</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/AD & ART' color='inherit'>
                        <Typography>AD/ART</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Visi & Misi' color='inherit'>
                        <Typography>Visi & Misi</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Pengurus Besar' color='inherit'>
                        <Typography>Pengurus Besar</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Dewan Guru' color='inherit'>
                        <Typography>Dewan Guru</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Pengurus Provinsi' color='inherit'>
                        <Typography>Pengurus Provinsi</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </List>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Mobile menu */}

      {/* <div style={{ paddingBottom: 50, marginTop: 0 }}>
        <div
          style={{
            //   width: '100%',
            height: 80,
            backgroundImage: `url(${Background})`,
            padding: 20,
          }}
        >
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography
                style={{
                  fontSize: 19,
                  color: '#ffffff',
                  fontWeight: 'bold',
                  marginTop: 2,
                }}
              >
                Sejarah Karate di Indonesia
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}
          >
            <Grid item md={9}>
              <div>
                <img
                  src={karate}
                  style={{
                    width: '100%',
                    height: 380,
                    paddingRight: 50,
                    //   borderRadius: 20,
                  }}
                />
              </div>
            </Grid>
            <Grid item md={3}>
              <div>
                <List style={{ marginTop: -20 }}>
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Sejarah Karate' color='inherit'>
                        <Typography>Sejarah Karate</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Sejarah Lemkari' color='inherit'>
                        <Typography>Sejarah Lemkari</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Arti Lambang Lemkari' color='inherit'>
                        <Typography>Arti Lambang Lemkari</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/AD & ART' color='inherit'>
                        <Typography>AD/ART</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Visi & Misi' color='inherit'>
                        <Typography>Visi & Misi</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Pengurus Besar' color='inherit'>
                        <Typography>Pengurus Besar</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Dewan Guru' color='inherit'>
                        <Typography>Dewan Guru</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='/Pengurus Provinsi' color='inherit'>
                        <Typography>Pengurus Provinsi</Typography>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </List>
              </div>
            </Grid>
          </Grid>
        </div>
      </div> */}
    </>
  );
}
