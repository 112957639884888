import React from 'react';

import { Grid, Typography, Link } from '@material-ui/core';

import gambars from '../../../Assets/Images/kerja.jpg';

export default function Foto190(props) {
  return (
    <div>
      <Grid container>
        <Grid item md={12}>
          <img
            src={props.gambar}
            style={{ width: '100%', height: 230, borderRadius: 10 }}
          />
        </Grid>
        <Grid item md={6}>
          <Typography style={{ fontSize: 14, color: '#4F4F4F' }}>
            {props.tanggal}
          </Typography>
        </Grid>
        <Grid item md={6} style={{ textAlign: 'right' }}>
          <Typography style={{ fontSize: 14, color: '#4F4F4F' }}>
            {props.penerbit}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography
            style={{ fontSize: 18, fontWeight: 'bold', paddingTop: 10 }}
          >
            {props.judul}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
