import React, { useEffect, useState } from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Link,
  Avatar,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { makeStyles } from '@material-ui/core/styles';

import Logo from '../../Assets/Images/Lemkari.png';
import Background from '../../Assets/Images/bg_KK_PK.png';
import Api from '../../Services/Api';

function ArtiLambangLemkari() {
  const classes = useStyles();

  const [content, setContent] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    Api.getPagesRequest(localStorage.pages).then(res => {
      setContent(res.data);
      console.log('Data Content', res.data);
    });
    Api.getPagesRequest().then(res => {
      setPages(res.data);
      console.log('Data Pages', res.data);
    });
  }, []);

  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            {pages
              .filter(x => x.name === 'Lambang Lemkari')
              .map(item => (
                <Typography
                  style={{
                    fontSize: 24,
                    color: '#ffffff',
                    fontWeight: 'bold',
                  }}
                >
                  {item.name}
                </Typography>
              ))}
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: 20, paddingLeft: 40, paddingRight: 40 }}>
        <Grid container>
          <Grid item md={9}>
            {content
              .filter(x => x.name === 'Lambang Lemkari')
              .map(item => (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                ></div>
              ))}
          </Grid>
          <Grid item md={3}>
            <div>
              <List style={{ marginTop: -20 }}>
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Sejarah Karate' color='inherit'>
                      <Typography>Sejarah Karate</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Sejarah Lemkari' color='inherit'>
                      <Typography>Sejarah Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Arti Lambang Lemkari' color='inherit'>
                      <Typography>Arti Lambang Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/AD & ART' color='inherit'>
                      <Typography>AD/ART</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Visi & Misi' color='inherit'>
                      <Typography>Visi & Misi</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Pengurus Besar' color='inherit'>
                      <Typography>Pengurus Besar</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Dewan Guru' color='inherit'>
                      <Typography>Dewan Guru</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Pengurus Provinsi' color='inherit'>
                      <Typography>Pengurus Provinsi</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
              </List>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
}));

export default ArtiLambangLemkari;
