import React, { Component, Fragment } from "react";
import _ from "lodash";
import ContentLoader from 'react-content-loader'

import '../../App.css'

import {
  Button,
  Hidden,
  Fab,
  CssBaseline,
  Grid,
  Typography,
  IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import moment from "moment";

import PropTypes from "prop-types";

import Axios from "axios"

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Paper from '@material-ui/core/Paper';
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


// Assest Image and Icon
import ponTaglineImg from "../../Assets/Images/tag-line-peparnas.png";
import nationalParalympicImg from "../../Assets/Images/national-paralympic.png";
import haraImg from "../../Assets/Images/HARA.png";
import Background from "../../Assets/Images/bg_KK_PK.png";
import AbstractImg from "../../Assets/Images/background_abstract.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import "../../../node_modules/video-react/dist/video-react.css";

import { Player, PosterImage } from "video-react";
import { NavLink } from "react-router-dom";

import Header from "../Component/Header";
import Footer from "../Component/Footer";
import logo from "../../Assets/Images/LEMKARITransparent.png";
import Profile from "../../Assets/Images/Profile.png";
import Phone from "../../Assets/Images/Phone.png";
import Mail from "../../Assets/Images/Mail.png";
import ContactUs from "../../Assets/Images/contact-us1.png";
import ContactUs2 from "../../Assets/Images/Contact-us2.png";
import Map from "../../Assets/Images/Map.png";
import Location from "../../Assets/Images/Location.png";
import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class Home extends Component {
  constructor() {
    super();
    this.state = {
      news: [],
      video: [],
      photo: [],
      events: [],
      highlight: [],
      sponsor: [],
      activeStep: 0,
      time: new Date("Nov 15, 2021 20:20:00").getTime(),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      loadingHighlight: true,
      loadingNews:true,
      loadingVideo:true,
      loadingPhoto:true,
      loadingSponsor:true,
      Value: ''
    };

  
 
    this.countdown = setInterval(() => {
      var _time = this.state.time;
      this.setState({ time: _time - 1 });
      var now = new Date().getTime();
      var distance = _time - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.setState({
        days,
        hours,
        minutes,
        seconds
      });

      // console.log('Interval triggered',_time, days,hours,minutes,seconds);
    }, 1000);
  }
  componentDidMount() {
    this.fetchData();
    this.getIconEvent();
  }

  getIconEvent(){
    for(var i = 1; i<12; i++){
      this.state.events.push({event_icon:"cabor"+i+".jpeg"})
    }
  }


  async fetchData() {
    await  Api.getHighlightRequest().then(res => {
      const result = _.filter(res.data, ["status", "Publish"]);
      this.setState({ highlight: result });
      this.setState({ loadingHighlight: false })
    }).catch(err => {
      console.log("error", err)
      this.setState({ loadingHighlight: false })
    })

    await Api.getNewsRequest().then(res => {
      const result = _.take(res.data, 8);
      this.setState({ news: result });
      this.setState({ loadingNews: false })
    }).catch(err => {
      console.log("error", err)
      this.setState({ loadingNews: false })
    })

    await Api.getVideoRequest().then(res => {
      const result = _.take(res.data, 2);
      this.setState({ video: result });
      this.setState({ loadingVideo: false })
    }).catch(err => {
      console.log("error", err)
      this.setState({ loadingVideo: false })
    })

    
    await Api.getSponsorRequest().then(res => {
      this.setState({ sponsor: res.data });
      this.setState({ loadingSponsor: false })
    }).catch(err => {
      console.log("error", err)
      this.setState({ loadingSponsor: false })
    })

    await Api.getPhotoRequest().then(res => {
      const result = _.take(res.data, 6);
      this.setState({ photo: result });
      this.setState({ loadingPhoto: false })
    }).catch(err => {
      console.log("error", err)
      this.setState({ loadingPhoto: false })
    })

    // await Axios.get(StaticVar.URL_GAMES + '/events').then(res => {
    //   this.setState({ events: res.data });
    // })
  }
  handleChange(event) {    
      this.setState({value: event.target.value});  }
 
      componentWillUnmount() {
    clearInterval(this.countdown);
  }

  handleNext() {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  }

  handleBack() {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
  }

  handleStepChange(step) {
    this.setState({ activeStep: step });
  }

  render() {
    const { classes } = this.props;
    const maxSteps = this.state.highlight.length;

    return (
      <Fragment>
        <CssBaseline />
        {/* <Header /> */}

        {/* menu unit */}
        <main style={{backgroundImage: "linear-gradient(to right, #ffffff , #c4c4c4 )", height: "100%"}}>
          <div className={classes.container}>
           
            <Grid container style={{marginBottom: 20, marginTop: 72}}>
            <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
          width: '100%'
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              Program Kerja
            </Typography>
          </Grid>
         
          <Grid item md={5} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
                
                    
            
                <Grid container style={{paddingLeft: '3%', paddingTop: '6%', paddingRight: '3%'}} >
                  <Grid item md={12} style={{justifyContent: "center"}}>
                    <Typography style={{marginTop: 10,  fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 'bold', fontSize: 30}}>Program Kerja Pusat PB Lemkari Tahun 2016 - 2017</Typography>
                  </Grid>
                </Grid>  
                <Grid container style={{marginBottom: 20}}>
                 {/* sub judul 1*/}
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%', marginTop: 30}} >   
                  <Grid item md={3}>
                    <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 'bold', fontSize: 24}}>1. Pengembangan Kapasitas </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <div style={{height: 2, backgroundColor: '#f2c94c', marginTop: 19, width:'100%'}}/>
                  </Grid>
                </Grid>     
                        
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}} >       
                  <Grid item md={12} style={{justifyContent: "center"}} > 
                    
                    <Accordion style={{marginTop: 10}}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: 'white', backgroundColor: '#f58634', borderRadius: 15}}/>}
                        aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 400, fontSize: 19}}>1.1. Pemantapan dan penguatan Organisasi</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer component={Paper}>
                          <Table  aria-label="simple table">
                            <TableHead>
                              <TableRow style={{backgroundColor:'#d6261d', color:'white', fontFamily: 'Barlow', fontStyle: 'normal',fontWeight: 600, fontSize: 18}}>
                                  <TableCell  className={classes.th}>Code</TableCell>
                                  <TableCell  className={classes.th}>Time</TableCell>
                                  <TableCell  className={classes.th}>Aktivitas Strategis</TableCell>
                                  <TableCell  className={classes.th}>Output</TableCell>
                                  <TableCell  className={classes.th}>Outcome</TableCell>
                                  <TableCell  className={classes.th}>PIC</TableCell>
                                  <TableCell  className={classes.th}>Support</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{fontFamily: 'Barlow', fontStyle: 'normal', fontWeight: 'normal', fontSize: 18}}>
                                  
                                <TableRow >
                                  <TableCell className={classes.tc}>A</TableCell>
                                  <TableCell className={classes.tc}>TW4 2016</TableCell>
                                  <TableCell className={classes.tc}>Penguatan Legalitas & Administrasi PB Lemkari</TableCell>
                                  <TableCell className={classes.tc}>Semakin mantapnya legal & administrasi</TableCell>
                                  <TableCell className={classes.tc}><Typography>- Menjamin aspek legal organisasi PB Lemkari</Typography> <Typography>- Konsolidasi PengProv</Typography></TableCell>
                                  <TableCell className={classes.tc}>Sekum, Bidang Orgakum</TableCell>
                                  <TableCell >-</TableCell>
                                </TableRow>

                                <TableRow style={{backgroundColor: '#f2f2f2'}} >
                                  <TableCell className={classes.tc}>B</TableCell>
                                  <TableCell className={classes.tc}>TW4 2016</TableCell>
                                  <TableCell className={classes.tc}>Pemantapan kesekretariatan PB, PengProv dan PengKab/PengKot</TableCell>
                                  <TableCell className={classes.tc}>Lengkapnya aspek kesekretariatan PB, PengProv dan PengKab/Pengkot</TableCell>
                                  <TableCell className={classes.tc}>Meningkatkan kinerja PB Lemkari melalui tertib administrasi</TableCell>
                                  <TableCell className={classes.tc}>Sekum, Bidang Orgakum</TableCell>
                                  <TableCell className={classes.tc}>-</TableCell>
                                </TableRow>

                                <TableRow >
                                  <TableCell className={classes.tc}>C</TableCell>
                                  <TableCell className={classes.tc}>TW4 2016</TableCell>
                                  <TableCell className={classes.tc}>Revisi & restrukturisasi organisasi</TableCell>
                                  <TableCell className={classes.tc}>Terbentuknya struktur organisasi </TableCell>
                                  <TableCell className={classes.tc}>Meningkatkan kinerja PB Lemkari melalui efisiensi struktur</TableCell>
                                  <TableCell className={classes.tc}>Sekum, Bidang Orgakum</TableCell>
                                  <TableCell >-</TableCell>
                                </TableRow>

                                <TableRow style={{backgroundColor: '#f2f2f2'}}>
                                  <TableCell className={classes.tc}>D</TableCell>
                                  <TableCell className={classes.tc}>TW1/TW2 - 2017</TableCell>
                                  <TableCell className={classes.tc}>Pembentukkan komisi etik</TableCell>
                                  <TableCell className={classes.tc}>Adanya komisi etik</TableCell>
                                  <TableCell className={classes.tc}>Menjamin aspek etika sesuai dengan AD/ART Lemkari</TableCell>
                                  <TableCell className={classes.tc}>Sekum, Bidang Orgakum dan Perwasitan</TableCell>
                                  <TableCell >-</TableCell>
                                </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                    
                    <Accordion style={{marginTop: 10}}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: 'white', backgroundColor: '#f58634', borderRadius: 15}}/>}
                        aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 400, fontSize: 19}}>1.2. Pendanaan dan Kelangsungan Organisasi</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis egt.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                  </Grid>
               
                </Grid>
               
               {/* sub judul 2 */}

               <Grid container style={{paddingLeft: '3%', paddingRight: '3%', marginTop: 20}} >   
                  <Grid item md={3}>
                    <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 'bold', fontSize: 24}}>2. Pencapaian Prestasi </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <div style={{height: 2, backgroundColor: '#f2c94c', marginTop: 19, width:'100%'}}/>
                  </Grid>
                </Grid>     
                        
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}} >       
                  <Grid item md={12} style={{justifyContent: "center"}}> 
                    
                    <Accordion style={{marginTop: 10}}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: 'white', backgroundColor: '#f58634', borderRadius: 15}}/>}
                        aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 400, fontSize: 19}}>2.1. Revitalisasi Dewan Guru Untuk Mendukung Akselerasi Pencapaian Prestasi</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis egt.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    
                    <Accordion style={{marginTop: 10}}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: 'white', backgroundColor: '#f58634', borderRadius: 15}}/>}
                        aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 400, fontSize: 19}}>2.2. Merancang, Menyelenggarakan, Mengawasi, Memonitor dan Mengevaluasi Kompetisi Karate Yang Benar - benar Profesional</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis egt.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion style={{marginTop: 10}}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: 'white', backgroundColor: '#f58634', borderRadius: 15}}/>}
                        aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 400, fontSize: 19}}>2.3. Penerapan Aplikasi Teknologi Informasi Untuk Peningkatan Kualitas Pelatihan dan Pertandingan</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis egt.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion style={{marginTop: 10}}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: 'white', backgroundColor: '#f58634', borderRadius: 15}}/>}
                        aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 400, fontSize: 19}}>2.4. Mengakselerasi Pencapaian Prestasi Melalui Standarisasi, Pendidikan dan Pelatihan Pelatih dan Wasit</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis egt.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                  </Grid>
               
                </Grid>
                {/* sub judul 3 */}
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%', marginTop: 20}} >   
                  <Grid item md={3}>
                    <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 'bold', fontSize: 24}}>3. Hubungan dan Layanan Anggota </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <div style={{height: 2, backgroundColor: '#f2c94c', marginTop: 19, width:'100%'}}/>
                  </Grid>
                </Grid>     
                        
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}} >       
                  <Grid item md={12} style={{justifyContent: "center"}} > 
                    
                    <Accordion style={{marginTop: 10, }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: 'white', backgroundColor: '#f58634', borderRadius: 15}}/>}
                        aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 400, fontSize: 19}}>3.1. Pemantapan serta Penguatan Standar dan Regulasi</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis egt.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    
                    <Accordion style={{marginTop: 10}}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: 'white', backgroundColor: '#f58634', borderRadius: 15}} />}
                        aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography style={{fontFamily: "Barlow", fontStyle: 'normal', fontWeight: 400, fontSize: 19}}>3.2. Komunikasi dan Harmonisasi Internal Maupun Eksternal PB Lemkari</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis egt.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                  </Grid>
               
                </Grid>
                </Grid>
            </Grid>
            <Hidden smUp>
              <AutoPlaySwipeableViews
                style={{ marginTop: 80 }}
                enableMouseEvents
              >
                {this.state.highlight.map((item, index) => {
                  // var str = item.content;
                  // var slc = str.slice(0,160);
                  // var content = slc.concat(' ');
                  return (
                    <div key={index}>
                      {
                        <div
                          style={{
                            height: 320,
                            backgroundImage: `url("${StaticVar.URL_PORTAL +
                              "/repo/" +
                              item.picture}")`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%"
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#363636",
                              position: "absolute",
                              bottom: 0,
                              opacity: 0.7,
                              zIndex: 10,
                              paddingLeft: 11,
                              paddingRight: 11,
                              paddingTop: 9,
                              paddingBottom: 9
                            }}
                          >
                            <Typography
                              rowsMax={1}
                              style={{
                                fontSize: 16,
                                fontWeight: 300,
                                margin: 0,
                                padding: 0,
                                lineHeight: 1,
                                color: "#ffffff"
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 13,
                                fontWeight: 200,
                                marginTop: 5,
                                color: "#ffffff",
                                lineHeight: 1.1
                              }}
                            >
                              {item.content}
                            </Typography>
                            {/* <Button component={NavLink} to="/news" style={{ width: 100, height: 30, padding: 0, backgroundColor: '#f58634', float: 'right',marginRight:7,marginTop:3 }}><Typography style={{ fontSize:15,textTransform: 'none', color: '#ffffff', fontWeight: 200 }}>Selengkapnya</Typography></Button> */}
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </AutoPlaySwipeableViews>
            </Hidden>
              
            
           
            
          </div>
        </main>
        {/* Footer */}
        
        {/* End footer */}
      </Fragment>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

PosterImage.propTypes = {
  poster: PropTypes.string
};

const useStyles = theme => ({

  container: {
    [theme.breakpoints.down("sm")]: {
      background: "none"
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%"
    }
  },
  th:{
    color:'white', fontFamily: 'Barlow', fontStyle: 'normal',fontWeight: 600, fontSize: 18
  },
  tc:{
     fontFamily: 'normal', fontStyle: 'normal',fontWeight: 'normal', fontSize: 18
  },
  containerPhoto: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0
      // width: '90%',
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 10
      // width: '95%',
    }
  },
  containerImgBerita: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      height: 260
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 15,
      height: 255
    },
    marginBottom: 15,
    border: "1px solid #b5b5b5",
    borderRadius: 4
  },
  imgEventIcon: {
    [theme.breakpoints.down("sm")]: {
      width: 30
    },
    [theme.breakpoints.up("md")]: {
      width: 50
    }
  },
 
  containerImgSponsor: {
    marginBottom: 10
  },
  containerPik: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 10
    }
  },
  backgroundTxtPhoto: {
    height: "100%",
    background: "rgba(0, 0, 0, 0.3)",
    zIndex: 2,
    width: "100%",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingTop: 5,
    paddingBottom: 5
  },
  sponsorImgStyle: {
    [theme.breakpoints.down("sm")]: {
      height: 70
    },
    [theme.breakpoints.up("sm")]: {
      height: 100
    },
    [theme.breakpoints.up("md")]: {
      height: 110
    }
  },
  containerStatus: {
    [theme.breakpoints.down("sm")]: {
      height: 296
    },
    [theme.breakpoints.up("sm")]: {
      height: 190
    },
    [theme.breakpoints.up("md")]: {
      height: 120
    },
    backgroundImage: "linear-gradient(to right, #bf272b , #601416 )"
  },

  containerTime: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      paddingTop: 10,
      border: "none",
      borderTop: "1px solid #ffffff",
      borderBottom: "1px solid #ffffff",
      height: 80
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 25,
      height: 117
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 10,
      height: 117
    },
    paddingBottom: 10,
    borderLeft: "2px solid #ffffff"
  },
  containerMaskot: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 50
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 20
    }
  },

  imgBeritaResponsive: {
    // [theme.breakpoints.down("sm")]: {
    //   height: 150
    // },
    // [theme.breakpoints.up("md")]: {
    //   height: 175
    // },
    height: 180,
    width: "100%"
  },

  imgSponsorResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: 200
    },
    [theme.breakpoints.up("md")]: {
      height: 200
    },
    width: "95%"
  },
  dataMap:{
    height: 107,
    borderRadius: 8,
    
    
  },
  dataMapcomponent:{
    height: 107,
    borderRadius: 8,
    backgroundColor: "#d6261d",
    color: 'White',
    marginLeft: '1%',
    marginRight: '1%',
    
    
    
  },
  iconFollowUs: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 25,
    },
    color: 'white',
    marginRight: 16,
    
  },
  listTitle:{
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 24
  },
  listText:{
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 9
  },
  buttonKirim:{
    backgroundColor: '#f58634',
    borderRadius: 8,

  },
 
  spacingTop: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 20
    }
  },

  imgPhotoResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: 110,
      width: "97%"
    },
    [theme.breakpoints.up("md")]: {
      height: 150,
      width: "95%"
    }
  },
  
  containerResponsive: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 10
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
      paddingRight: 40
    },
    paddingTop: 20
  },
  txtTime: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 17
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 22
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 36
    },
    color: "white",
    textAlign: "center"
  },
  containerSponsor: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 10
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
      paddingRight: 40
    },
    paddingTop: 40,
    paddingBottom: 40
  },

  boxKategori1: {
    [theme.breakpoints.down("sm")]: {
      height: 70,
      borderTop: "1px solid #ffffff",
      borderRight: "1px solid #ffffff"
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
      borderLeft: "1px solid #ffffff",
      borderBottom: "1px solid #ffffff"
    },
    paddingTop: 10
  },
  boxKategori4: {
    borderLeft: "2px solid #ffffff",
    
  },
  boxKategori2: {
    [theme.breakpoints.down("sm")]: {
      height: 70,
      borderTop: "1px solid #ffffff",
      borderRight: "1px solid #ffffff"
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
      borderLeft: "1px solid #ffffff"
    },
    paddingTop: 10
  },

  boxKategori3: {
    [theme.breakpoints.down("sm")]: {
      height: 70,
      borderTop: "1px solid #ffffff"
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
      borderLeft: "1px solid #ffffff"
    },
    paddingTop: 10
  },

  containerVideo: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "98%"
    },

    marginRight: 10
  },

  txtTitleStatus: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 17
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 22
    },
    color: "white",
    textAlign: "center"
  },

  imgMaskot: {
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
      height: 220
    },
    [theme.breakpoints.up("md")]: {
      // width: '80%',
      height: 265
    }
  },
  txtBerita: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      lineHeight: 1,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 15
    },
    color: "#000000"
  },
  txtPhoto: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 13
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14
    },
    color: "#ffffff",
    lineHeight: 1.1,
    textAlign: "center"
  },
  taglineImg: {
    [theme.breakpoints.up("sm")]: {
      width: 150,
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    height: 65,
    alignSelf:'center',
    marginTop: 25
  },

  paralympicImg: {
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.up("md")]: {
      width: "95%",
    },
    height: 100,
    marginTop: 5
  },

  statusTitleTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      marginTop: 15,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      marginTop: 35
    },
    textAlign: "center",
    color: "#ffffff",
    fontWeight: 300
  },
  statusTitleTxt1: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 13
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14
    },
    textAlign: "center",
    color: "#ffffff",
    marginTop:10,
    fontWeight: 300
  },
  eventTxt: {
    fontSize: 8,
    textAlign: "center"
  },
  txtVideo: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 10
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 12
    },
    color: "#ffffff",
    fontWeight: 200
  },

  txtTitleVideo: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 16
    },
    color: "#ffffff",
    fontWeight: 200
  },
  titleTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 15
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 18
    },
    fontWeight: 800
  },
  imgSponsor: {
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 70
    },
    [theme.breakpoints.up("md")]: {
      width: 140,
      height: 130
    },
    width: 140,
    height: 130
  }
});

const statusNumberTxt = {
  fontSize: 17,
  textAlign: "center",
  color: "#ffffff",
  fontWeight: 200
};

export default withStyles(useStyles)(Home);
