import React from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Input,
  Card,
  Link,
  Button,
  CardContent,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import FormControl from '@material-ui/core/FormControl';

import Gambars from '../Component/listCard/400Foto';

export default function TerimaKasih() {
  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundColor: '#d6261d',
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              <Link href='/Event' color='inherit'>
                Event
              </Link>{' '}
              /{' '}
              <Link href='/Event Selesai' color='inherit'>
                Event yang akan datang
              </Link>{' '}
              / Daftar
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid style={{ paddingTop: 30, paddingLeft: 50 }}>
          <Card
            style={{
              width: 770,
              height: 343,
              textAlign: 'center',
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Typography
              style={{ fontSize: 36, fontWeight: 'bold', paddingTop: 20 }}
            >
              Terima kasih Amel sudah melakukan Pendaftaran
            </Typography>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Nama Anda sudah terdaftar di Seminar Karate
            </Typography>
            <Typography style={{ fontSize: 18, paddingTop: 10 }}>
              Untuk tahap selanjutnya Pembayaran. Silahkan klik tombol
              Pembayaran untuk menyelesaikan pendaftaran
            </Typography>
            <Button
              variant='contained'
              color='primary'
              style={{ width: 328, height: 48, marginTop: 40 }}
            >
              <Typography>Pembayaran</Typography>
            </Button>
          </Card>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
    </div>
  );
}
