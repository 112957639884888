import React from 'react';

import {
  Typography,
  Grid,
  Link,
  Avatar,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import images from '../../Assets/Images/man.png';

import { makeStyles } from '@material-ui/core/styles';

function CardProfile(props) {
  const classes = useStyles();
  return (
    <div>
      <div style={{ paddingLeft: 50 }}>
        <Avatar className={classes.normal}>
          <img src={images} />
        </Avatar>
      </div>
      <Card style={{ width: 270, height: 323, marginTop: -80 }}>
        <CardHeader
          style={{
            height: 20,
            width: '100%',
            backgroundColor: '#D6261D',
          }}
        />
        <CardContent>
          <div style={{ textAlign: 'center', paddingTop: 50 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              {props.nama}
            </Typography>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
              Tingkat DAN :
            </Typography>
            <Typography style={{ fontSize: 16, paddingTop: 5 }}>
              {props.sabuk}
            </Typography>
          </div>
          <div style={{ paddingTop: 10 }}>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
              Jabatan :
            </Typography>
            <Typography style={{ fontSize: 16, paddingTop: 5 }}>
              {props.jabatan}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  normal: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

export default CardProfile;
