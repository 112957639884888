import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import ContentLoader from 'react-content-loader';

import {
  Button,
  Hidden,
  Fab,
  CssBaseline,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';

import PropTypes from 'prop-types';

import Axios from 'axios';

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Paper from '@material-ui/core/Paper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// Assest Image and Icon
import ponTaglineImg from '../../Assets/Images/tag-line-peparnas.png';
import nationalParalympicImg from '../../Assets/Images/national-paralympic.png';
import haraImg from '../../Assets/Images/HARA.png';
import waraImg from '../../Assets/Images/WARA.png';
import AbstractImg from '../../Assets/Images/background_abstract.png';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import '../../../node_modules/video-react/dist/video-react.css';

import { Player, PosterImage } from 'video-react';
import { NavLink } from 'react-router-dom';

import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Api from '../../Services/Api';
import StaticVar from '../../Config/StaticVar';
import IMaps from './Maps';
import MiniBerita from '../Component/allCard/150Card';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class Home extends Component {
  constructor() {
    super();
    this.state = {
      news: [],
      video: [],
      photo: [],
      events: [],
      highlight: [],
      sponsor: [],
      activeStep: 0,
      time: new Date('Nov 15, 2021 20:20:00').getTime(),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      loadingHighlight: true,
      loadingNews: true,
      loadingVideo: true,
      loadingPhoto: true,
      loadingSponsor: true,
    };

    this.countdown = setInterval(() => {
      var _time = this.state.time;
      this.setState({ time: _time - 1 });
      var now = new Date().getTime();
      var distance = _time - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.setState({
        days,
        hours,
        minutes,
        seconds,
      });

      // console.log('Interval triggered',_time, days,hours,minutes,seconds);
    }, 1000);
  }
  componentDidMount() {
    this.fetchData();
    this.getIconEvent();
  }

  getIconEvent() {
    for (var i = 1; i < 12; i++) {
      this.state.events.push({ event_icon: 'cabor' + i + '.jpeg' });
    }
  }

  async fetchData() {
    await Api.getHighlightRequest()
      .then(res => {
        const result = _.filter(res.data, ['status', 'Publish']);
        this.setState({ highlight: result });
        this.setState({ loadingHighlight: false });
      })
      .catch(err => {
        console.log('error', err);
        this.setState({ loadingHighlight: false });
      });

    await Api.getNewsRequest()
      .then(res => {
        const result = _.take(res.data, 8);
        this.setState({ news: result });
        this.setState({ loadingNews: false });
      })
      .catch(err => {
        console.log('error', err);
        this.setState({ loadingNews: false });
      });

    await Api.getVideoRequest()
      .then(res => {
        const result = _.take(res.data, 2);
        this.setState({ video: result });
        this.setState({ loadingVideo: false });
      })
      .catch(err => {
        console.log('error', err);
        this.setState({ loadingVideo: false });
      });

    await Api.getSponsorRequest()
      .then(res => {
        this.setState({ sponsor: res.data });
        this.setState({ loadingSponsor: false });
      })
      .catch(err => {
        console.log('error', err);
        this.setState({ loadingSponsor: false });
      });

    await Api.getPhotoRequest()
      .then(res => {
        const result = _.take(res.data, 6);
        this.setState({ photo: result });
        this.setState({ loadingPhoto: false });
      })
      .catch(err => {
        console.log('error', err);
        this.setState({ loadingPhoto: false });
      });

    // await Axios.get(StaticVar.URL_GAMES + '/events').then(res => {
    //   this.setState({ events: res.data });
    // })
  }

  componentWillUnmount() {
    clearInterval(this.countdown);
  }

  handleNext() {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  }

  handleBack() {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
  }

  handleStepChange(step) {
    this.setState({ activeStep: step });
  }

  render() {
    const { classes } = this.props;
    const maxSteps = this.state.highlight.length;

    return (
      <Fragment>
        <CssBaseline />
        {/* <Header /> */}

        {/* menu unit */}
        <main
          style={{
            backgroundImage: 'linear-gradient(to right, #ffffff , #c4c4c4 )',
            height: '100%',
          }}
        >
          <div className={classes.container}>
            <Hidden xsDown>
              {this.state.loadingHighlight ? (
                <div
                  style={{
                    backgroundColor: '#fff',
                    height: 580,
                    paddingTop: 170,
                  }}
                >
                  <ContentLoader
                    speed={2}
                    width={'100%'}
                    height={500}
                    viewBox='0 0 400 160'
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                  >
                    <rect x='48' y='8' rx='3' ry='3' width='88' height='6' />
                    <rect x='48' y='26' rx='3' ry='3' width='52' height='6' />
                    <rect x='0' y='56' rx='3' ry='3' width='410' height='6' />
                    <rect x='0' y='72' rx='3' ry='3' width='380' height='6' />
                    <rect x='0' y='88' rx='3' ry='3' width='178' height='6' />
                    <circle cx='20' cy='20' r='20' />
                  </ContentLoader>
                </div>
              ) : (
                <div style={{ paddingTop: 83 }}></div>
              )}
            </Hidden>

            <div
              style={{
                paddingBottom: 50,
                // marginTop: 80,
                backgroundColor: 'white',
                // height: 630,
                paddingBottom: '5%',
              }}
            >
              <IMaps />
              <div
                style={{ textAlign: 'left', paddingTop: 70, paddingBottom: 15 }}
              >
                <Typography
                  style={{ fontSize: 14, paddingTop: 5, paddingLeft: 40 }}
                >
                  * INFO : Klik Blok Maps Untuk Melihat Isi Detail Provinsi
                </Typography>
                <div style={{ width: '100%' }}>
                  <Divider
                    style={{
                      paddingBottom: 1,
                      backgroundColor: 'black',
                      width: '28%',
                      marginLeft: 30,
                    }}
                  />
                </div>
              </div>
            </div>

            {/* msh dojo karateka */}
            <Grid
              container
              className={classes.dataMap}
              justify='center'
              direction='row'
              style={{ marginTop: -30 }}
            >
              <Grid item xs={3} className={classes.dataMapcomponent}>
                <Button
                  component={NavLink}
                  to={'/news'}
                  style={{
                    backgroundColor: 'Transparent',
                    textTransform: 'none',
                    minHeight: 0,
                    height: 107,
                    marginLeft: '10%',
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Barlow',
                      fontSize: 22,
                      fontWeight: '600',
                      fontStyle: 'normal',
                      letterSpacing: 0,
                      color: '#ffffff',
                      marginTop: '2%',
                    }}
                  >
                    Dojo
                  </p>
                </Button>{' '}
                <Button
                  component={NavLink}
                  to={'/news'}
                  style={{
                    backgroundColor: 'Transparent',
                    textTransform: 'none',
                    minHeight: 0,
                    height: 107,
                    marginLeft: '18%',
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Barlow',
                      fontSize: 40,
                      fontWeight: 'bold',
                      fontStyle: 'normal',
                      letterSpacing: 0,
                      color: '#ffffff',
                      marginBottom: '50%',
                    }}
                  >
                    350
                  </p>
                </Button>{' '}
              </Grid>

              <Grid item xs={3} className={classes.dataMapcomponent}>
                {' '}
                <Button
                  component={NavLink}
                  to={'/news'}
                  style={{
                    backgroundColor: 'Transparent',
                    textTransform: 'none',
                    minHeight: 0,
                    height: 107,
                    marginLeft: '10%',
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Barlow',
                      fontSize: 22,
                      fontWeight: '600',
                      fontStyle: 'normal',
                      letterSpacing: 0,
                      color: '#ffffff',
                      marginTop: '2%',
                    }}
                  >
                    MSH
                  </p>
                </Button>{' '}
                <Button
                  component={NavLink}
                  to={'/news'}
                  style={{
                    backgroundColor: 'Transparent',
                    textTransform: 'none',
                    minHeight: 0,
                    height: 107,
                    marginLeft: '18%',
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Barlow',
                      fontSize: 40,
                      fontWeight: 'bold',
                      fontStyle: 'normal',
                      letterSpacing: 0,
                      color: '#ffffff',
                      marginBottom: '50%',
                    }}
                  >
                    1.092
                  </p>
                </Button>
              </Grid>

              <Grid item xs={3} className={classes.dataMapcomponent}>
                {' '}
                <Button
                  component={NavLink}
                  to={'/news'}
                  style={{
                    backgroundColor: 'Transparent',
                    textTransform: 'none',
                    minHeight: 0,
                    height: 107,
                    marginLeft: '10%',
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Barlow',
                      fontSize: 22,
                      fontWeight: '600',
                      fontStyle: 'normal',
                      letterSpacing: 0,
                      color: '#ffffff',
                      marginTop: '2%',
                    }}
                  >
                    Karateka
                  </p>
                </Button>{' '}
                <Button
                  component={NavLink}
                  to={'/news'}
                  style={{
                    backgroundColor: 'Transparent',
                    textTransform: 'none',
                    minHeight: 0,
                    height: 107,
                    marginLeft: '18%',
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Barlow',
                      fontSize: 40,
                      fontWeight: 'bold',
                      fontStyle: 'normal',
                      letterSpacing: 0,
                      color: '#ffffff',
                      marginBottom: '50%',
                    }}
                  >
                    350
                  </p>
                </Button>
              </Grid>
            </Grid>

            {/* Section Berita */}

            <Grid container className={classes.containerResponsive}>
              <Grid item xs={12}>
                <Typography className={classes.titleTxt}>BERITA</Typography>
                <Grid container spacing={2} className={classes.spacingTop}>
                  {this.state.news.map((item, index) => {
                    return (
                      <Grid item xs={6} sm={3} key={index}>
                        <MiniBerita
                          links={item.link + '/' + item.title}
                          gambar={
                            StaticVar.URL_PORTAL + '/repo/' + item.picture
                          }
                          tanggal={moment(item.create_date).format(
                            'DD MMMM YYYY'
                          )}
                          penerbit={item.creator}
                          judul={item.title}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      component={NavLink}
                      to={'/news'}
                      style={{
                        backgroundColor: '#f58634',
                        padding: 5,
                        textTransform: 'none',
                        float: 'right',
                        minHeight: 0,
                        height: 45,
                        width: 300,
                        marginTop: 120,
                      }}
                    >
                      <p
                        style={{
                          color: '#fff',
                          fontSize: 13,
                          marginLeft: '38%',
                        }}
                      >
                        Lainnya
                      </p>
                      <NavigateNextIcon
                        style={{
                          color: '#fff',
                          fontSize: 19,
                          marginLeft: '40%',
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Section Video and Photo*/}

            <Grid
              container
              style={{ paddingTop: 0 }}
              className={classes.containerResponsive}
            >
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container spacing={2} className={classes.containerVideo}>
                  <Grid item xs={12}>
                    <Typography className={classes.titleTxt}>VIDEO</Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                      {this.state.video.map((item, index) => {
                        return (
                          <Grid
                            item
                            key={index}
                            md={3}
                            // component={NavLink}
                            // to={
                            //   '/video/video-detail/' +
                            //   item.category +
                            //   '/' +
                            //   item.title +
                            //   '/#'
                            // }
                          >
                            <MiniBerita
                              links={item.link + '/' + item.title}
                              gambar={StaticVar.IMAGE_PORTAL + item.thumbnail}
                              judul={item.title}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      component={NavLink}
                      to={'/news'}
                      style={{
                        backgroundColor: '#f58634',
                        padding: 5,
                        textTransform: 'none',
                        float: 'right',
                        minHeight: 0,
                        height: 45,
                        width: 300,
                        marginTop: 120,
                      }}
                    >
                      <p
                        style={{
                          color: '#fff',
                          fontSize: 13,
                          marginLeft: '38%',
                        }}
                      >
                        Lainnya
                      </p>
                      <NavigateNextIcon
                        style={{
                          color: '#fff',
                          fontSize: 19,
                          marginLeft: '40%',
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Section Maskot dan Piktogram */}

            {/* Section Sponsor */}

            <Grid container className={classes.containerSponsor}>
              <Grid item xs={3} sm={1}>
                <Typography className={classes.titleTxt}>SPONSOR</Typography>
              </Grid>
              <Grid item xs={9} sm={11}>
                <div
                  style={{
                    marginTop: 10,
                    width: '100%',
                    height: 2,
                    backgroundColor: '#7a7a7a',
                  }}
                ></div>
              </Grid>

              {/* <Grid item xs={12} sm={12} md={12}> */}
              <Grid container>
                {/* <center> */}
                {/* <img alt="img" src={kemenPemudaOlahragaImg} className={classes.imgSponsor} /> */}
                {/* <img alt="img" src={NationalSportsCommitteeOfIndonesiaKoniImg} className={classes.imgSponsor} />
                  <img alt="img" src={LogoPapuaIcon} className={classes.imgSponsor} /> */}
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    {this.state.sponsor.map((item, index) => {
                      if (item.link == '') {
                        return (
                          <Grid
                            item
                            component={NavLink}
                            to={item.base_URL}
                            key={index}
                            className={classes.containerImgSponsor}
                          >
                            <img
                              src={StaticVar.URL_PORTAL + '/repo/' + item.logo}
                              className={classes.sponsorImgStyle}
                            />
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid
                            item
                            key={index}
                            className={classes.containerImgSponsor}
                          >
                            <a href={item.link}>
                              <img
                                src={
                                  StaticVar.URL_PORTAL + '/repo/' + item.logo
                                }
                                className={classes.sponsorImgStyle}
                              />
                            </a>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={3} />
                {/* </center> */}
              </Grid>
              {/* </Grid> */}
            </Grid>
          </div>
        </main>
        {/* Footer */}

        {/* End footer */}
      </Fragment>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

PosterImage.propTypes = {
  poster: PropTypes.string,
};

const useStyles = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      background: 'none',
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
    },
  },
  containerPhoto: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      // width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 10,
      // width: '95%',
    },
  },
  containerImgBerita: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      height: 260,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 15,
      height: 255,
    },
    marginBottom: 15,
    border: '1px solid #b5b5b5',
    borderRadius: 4,
  },
  imgEventIcon: {
    [theme.breakpoints.down('sm')]: {
      width: 30,
    },
    [theme.breakpoints.up('md')]: {
      width: 50,
    },
  },

  containerImgSponsor: {
    marginBottom: 10,
  },
  containerPik: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 10,
    },
  },
  backgroundTxtPhoto: {
    height: '100%',
    background: 'rgba(0, 0, 0, 0.3)',
    zIndex: 2,
    width: '100%',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingTop: 5,
    paddingBottom: 5,
  },
  sponsorImgStyle: {
    [theme.breakpoints.down('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('sm')]: {
      height: 100,
    },
    [theme.breakpoints.up('md')]: {
      height: 110,
    },
  },
  containerStatus: {
    [theme.breakpoints.down('sm')]: {
      height: 296,
    },
    [theme.breakpoints.up('sm')]: {
      height: 190,
    },
    [theme.breakpoints.up('md')]: {
      height: 120,
    },
    backgroundImage: 'linear-gradient(to right, #bf272b , #601416 )',
  },

  containerTime: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
      paddingTop: 10,
      border: 'none',
      borderTop: '1px solid #ffffff',
      borderBottom: '1px solid #ffffff',
      height: 80,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 25,
      height: 117,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 10,
      height: 117,
    },
    paddingBottom: 10,
    borderLeft: '2px solid #ffffff',
  },
  containerMaskot: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 50,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 20,
    },
  },

  imgBeritaResponsive: {
    // [theme.breakpoints.down("sm")]: {
    //   height: 150
    // },
    // [theme.breakpoints.up("md")]: {
    //   height: 175
    // },
    height: 180,
    width: '100%',
  },

  imgSponsorResponsive: {
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
    [theme.breakpoints.up('md')]: {
      height: 200,
    },
    width: '95%',
  },
  dataMap: {
    height: 107,
    borderRadius: 8,
  },
  dataMapcomponent: {
    height: 107,
    borderRadius: 8,
    backgroundColor: '#d6261d',
    color: 'White',
    marginLeft: '1%',
    marginRight: '1%',
  },

  spacingTop: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
    },
  },

  imgPhotoResponsive: {
    [theme.breakpoints.down('sm')]: {
      height: 110,
      width: '97%',
    },
    [theme.breakpoints.up('md')]: {
      height: 150,
      width: '95%',
    },
  },

  containerResponsive: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 10,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 40,
    },
    paddingTop: 20,
  },
  txtTime: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    color: 'white',
    textAlign: 'center',
  },
  containerSponsor: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 10,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 40,
    },
    paddingTop: 40,
    paddingBottom: 40,
  },

  boxKategori1: {
    [theme.breakpoints.down('sm')]: {
      height: 70,
      borderTop: '1px solid #ffffff',
      borderRight: '1px solid #ffffff',
    },
    [theme.breakpoints.up('md')]: {
      height: 60,
      borderLeft: '1px solid #ffffff',
      borderBottom: '1px solid #ffffff',
    },
    paddingTop: 10,
  },
  boxKategori4: {
    borderLeft: '2px solid #ffffff',
  },
  boxKategori2: {
    [theme.breakpoints.down('sm')]: {
      height: 70,
      borderTop: '1px solid #ffffff',
      borderRight: '1px solid #ffffff',
    },
    [theme.breakpoints.up('md')]: {
      height: 60,
      borderLeft: '1px solid #ffffff',
    },
    paddingTop: 10,
  },

  boxKategori3: {
    [theme.breakpoints.down('sm')]: {
      height: 70,
      borderTop: '1px solid #ffffff',
    },
    [theme.breakpoints.up('md')]: {
      height: 60,
      borderLeft: '1px solid #ffffff',
    },
    paddingTop: 10,
  },

  containerVideo: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '98%',
    },

    marginRight: 10,
  },

  txtTitleStatus: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 22,
    },
    color: 'white',
    textAlign: 'center',
  },

  imgMaskot: {
    [theme.breakpoints.down('sm')]: {
      // width: '90%',
      height: 220,
    },
    [theme.breakpoints.up('md')]: {
      // width: '80%',
      height: 265,
    },
  },
  txtBerita: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      lineHeight: 1,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 15,
    },
    color: '#000000',
  },
  txtPhoto: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
    color: '#ffffff',
    lineHeight: 1.1,
    textAlign: 'center',
  },
  taglineImg: {
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    height: 65,
    alignSelf: 'center',
    marginTop: 25,
  },

  paralympicImg: {
    [theme.breakpoints.up('sm')]: {
      width: '95%',
    },
    [theme.breakpoints.up('md')]: {
      width: '95%',
    },
    height: 100,
    marginTop: 5,
  },

  statusTitleTxt: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      marginTop: 15,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
      marginTop: 35,
    },
    textAlign: 'center',
    color: '#ffffff',
    fontWeight: 300,
  },
  statusTitleTxt1: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
    textAlign: 'center',
    color: '#ffffff',
    marginTop: 10,
    fontWeight: 300,
  },
  eventTxt: {
    fontSize: 8,
    textAlign: 'center',
  },
  txtVideo: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 12,
    },
    color: '#ffffff',
    fontWeight: 200,
  },

  txtTitleVideo: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
    color: '#ffffff',
    fontWeight: 200,
  },
  titleTxt: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    fontWeight: 800,
  },
  imgSponsor: {
    [theme.breakpoints.down('sm')]: {
      width: 80,
      height: 70,
    },
    [theme.breakpoints.up('md')]: {
      width: 140,
      height: 130,
    },
    width: 140,
    height: 130,
  },
});

const statusNumberTxt = {
  fontSize: 17,
  textAlign: 'center',
  color: '#ffffff',
  fontWeight: 200,
};

export default withStyles(useStyles)(Home);
