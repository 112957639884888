import React from 'react';

import { Typography, Grid, Card, Button } from '@material-ui/core';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Egambar from '../../../Assets/Images/123.png';

export default function Card400(props) {
  return (
    <div style={{ paddingBottom: 10 }}>
      <Grid container style={{ paddingRight: 20 }}>
        <Grid item md={12}>
          <img src={Egambar} style={{ width: '100%', height: 400 }} />
        </Grid>
      </Grid>
    </div>
  );
}
