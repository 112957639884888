import React, { useState, useEffect } from 'react';

import { Bar, Doughnut, Line } from 'react-chartjs-2';
import _ from 'lodash';
import datapengProv from './dataPengProv.json';
import datajumlahKarate from './jumlahKarate.json';
import datajenisKelamin from './jenisKelamin.json';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Link,
  Button,
  Card,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import TablePagination from '@material-ui/core/TablePagination';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Background from '../../Assets/Images/bg_KK_PK.png';

export default function Statistik(props) {
  const [pengProv, setPengProv] = useState({});
  const [jumlahKarate, setJumlahKarate] = useState({});
  const [jenisKelamin, setJenisKelamin] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const chart = () => {
    var dataPengProv = {
      labels: _.map(datapengProv, 'name'),
      datasets: [
        {
          label: 'Cabang',
          data: _.map(datapengProv, 'cabang'),
          backgroundColor: '#D6261D',
        },
        {
          label: 'MSH',
          data: _.map(datapengProv, 'MSH'),
          backgroundColor: '#219653',
        },
        {
          label: 'Karate',
          data: _.map(datapengProv, 'karate'),
          backgroundColor: '#2F80ED',
        },
      ],
    };
    setPengProv(dataPengProv);
    var dataJumlahKarate = {
      labels: _.map(datajumlahKarate, 'name'),
      datasets: [
        {
          label: 'Usia Dini(-9 Tahun)',
          data: _.map(datajumlahKarate, 'usiaDini'),
          borderColor: '#D6261D',
          backgroundColor: 'transparent',
        },
        {
          label: 'Pemula(12-13 Tahun)',
          data: _.map(datajumlahKarate, 'pemula'),
          borderColor: '#6FCF97',
          backgroundColor: 'transparent',
        },
        {
          label: 'Junior(16-17 Tahun)',
          data: _.map(datajumlahKarate, 'junior'),
          borderColor: '#2F80ED',
          backgroundColor: 'transparent',
        },
        {
          label: 'Senior(+21 Tahun)',
          data: _.map(datajumlahKarate, 'senior'),
          borderColor: '#000000',
          backgroundColor: 'transparent',
        },
        {
          label: 'Pra Pemula(10-11 Tahun)',
          data: _.map(datajumlahKarate, 'praPemula'),
          borderColor: '#9B51E0',
          backgroundColor: 'transparent',
        },
        {
          label: 'Kadet(14-15 Tahun)',
          data: _.map(datajumlahKarate, 'kadet'),
          borderColor: '#F2994A',
          backgroundColor: 'transparent',
        },
        {
          label: 'Under(18-21 Tahun)',
          data: _.map(datajumlahKarate, 'under'),
          borderColor: '#828282',
          backgroundColor: 'transparent',
        },
      ],
    };
    setJumlahKarate(dataJumlahKarate);
    var dataJenisKelamin = {
      type: 'pie',
      labels: ['Laki- laki', 'Perempuan'],
      datasets: [
        {
          label: 'Points',
          data: [200, 100],
          backgroundColor: ['#2F80ED', '#FB5CEB'],
        },
        // {
        //   label: 'Perempuan',
        //   data: _.map(datajenisKelamin, 'persen'),
        //   backgroundColor: '#FB5CEB',
        // },
      ],
    };
    setJenisKelamin(dataJenisKelamin);
    // setChartData({

    //   labels: [
    //     'Boston',
    //     'Worcester',
    //     'Springfield',
    //     'Lowell',
    //     'Cambridge',
    //     'New Bedford',
    //   ],

    //   datasets: [
    //     {
    //       label: 'Population',
    //       data: [1000, 3000, 6000, 9000, 12000, 15000],
    //       backgroundColor: [
    //         'rgba(255, 99, 132, 0.6)',
    //         'rgba(54, 162, 235 , 0.6)',
    //         'rgba(255, 206, 86, 0.6)',
    //         'rgba(75, 192, 192, 0.6)',
    //         'rgba(153, 102, 225, 0.6)',
    //         'rgba(255, 159, 64, 0.6)',
    //         // 'rgba(255, 99, 132, 0.6)',
    //       ],
    //     },
    //   ],
    // });
  };

  useEffect(() => {
    chart();
  }, []);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div
      style={{ paddingBottom: 50, marginTop: 80, backgroundColor: '#E5E5E5' }}
    >
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              Statistik
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingLeft: 40, paddingTop: 20 }}>
        <Grid container>
          <Grid item md={2}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Pilih Provinsi
            </Typography>
          </Grid>
          <Grid item md={2} style={{ paddingRight: 30 }}>
            <TextField
              variant='outlined'
              size='small'
              select
              SelectProps={{
                native: true,
              }}
              style={{ backgroundColor: 'white', width: 324 }}
            >
              <option value='Semua Provinsi'>Semua Provinsi</option>
            </TextField>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingLeft: 40, paddingTop: 20, paddingRight: 40 }}>
        <Card
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            // height: 500,
            position: 'relative',
            paddingBottom: 20,
          }}
        >
          <Typography
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              paddingTop: 20,
              paddingBottom: 10,
            }}
          >
            Pengurus Provinsi (PengProv) Lemkari di Indonesia
          </Typography>
          <div>
            <Bar
              data={pengProv}
              height={100}
              options={{
                legend: { display: true, position: 'top' },
                responsive: true,
              }}
            />
          </div>
        </Card>
        <div style={{ paddingTop: 20 }}>
          <Grid container spacing={5}>
            <Grid item md={8}>
              <Card
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  // height: 500,
                  position: 'relative',
                  paddingBottom: 20,
                }}
              >
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  Trend Jumlah Karate Aktif Semua Provinsi
                </Typography>
                <div>
                  <Line
                    data={jumlahKarate}
                    height={100}
                    options={{
                      legend: {
                        display: true,
                        position: 'bottom',
                      },
                      responsive: true,
                    }}
                  />
                </div>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  // height: 500,
                  position: 'relative',
                  paddingBottom: 20,
                }}
              >
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  Jumlah Karateka Jawa Barat
                </Typography>
                <div>
                  <Doughnut
                    data={jenisKelamin}
                    height={223}
                    options={{
                      legend: {
                        display: true,
                        position: 'bottom',
                      },
                      responsive: true,
                    }}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ paddingLeft: 40, paddingTop: 20 }}>
        <Grid container>
          <Grid item md={3}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Pilih Cabang Jawa Barat
            </Typography>
          </Grid>
          <Grid item md={2} style={{ paddingRight: 30 }}>
            <TextField
              variant='outlined'
              size='small'
              select
              SelectProps={{
                native: true,
              }}
              style={{ backgroundColor: 'white', width: 324 }}
            >
              <option value='Semua Provinsi'>Kota Bekasi</option>
            </TextField>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: 20, paddingLeft: 40, paddingRight: 40 }}>
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead style={{ backgroundColor: '#D6261D' }}>
              <TableRow>
                <StyledTableCell rowSpan='3' align='center'>
                  Nama Ranting
                </StyledTableCell>
                <StyledTableCell colSpan='12' align='center'>
                  Jumlah Sabuk Karate
                </StyledTableCell>
                <StyledTableCell colSpan='2' rowSpan='2' align='center'>
                  Total Karate
                </StyledTableCell>
                <StyledTableCell rowSpan='3'></StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan='2' align='center'>
                  Putih
                </StyledTableCell>
                <StyledTableCell colSpan='2' align='center'>
                  Kuning
                </StyledTableCell>
                <StyledTableCell colSpan='2' align='center'>
                  Hijau
                </StyledTableCell>
                <StyledTableCell colSpan='2' align='center'>
                  Biru
                </StyledTableCell>
                <StyledTableCell colSpan='2' align='center'>
                  Coklat
                </StyledTableCell>
                <StyledTableCell colSpan='2' align='center'>
                  Hitam
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Lk</StyledTableCell>
                <StyledTableCell>Pr</StyledTableCell>
                <StyledTableCell>Lk</StyledTableCell>
                <StyledTableCell>Pr</StyledTableCell>
                <StyledTableCell>Lk</StyledTableCell>
                <StyledTableCell>Pr</StyledTableCell>
                <StyledTableCell>Lk</StyledTableCell>
                <StyledTableCell>Pr</StyledTableCell>
                <StyledTableCell>Lk</StyledTableCell>
                <StyledTableCell>Pr</StyledTableCell>
                <StyledTableCell>Lk</StyledTableCell>
                <StyledTableCell>Pr</StyledTableCell>
                <StyledTableCell>Lk</StyledTableCell>
                <StyledTableCell>Pr</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>
                  Dojo SKI(Sekolah Karate Intelektika)
                </StyledTableCell>
                <StyledTableCell>40</StyledTableCell>
                <StyledTableCell>30</StyledTableCell>
                <StyledTableCell>35</StyledTableCell>
                <StyledTableCell>31</StyledTableCell>
                <StyledTableCell>40</StyledTableCell>
                <StyledTableCell>30</StyledTableCell>
                <StyledTableCell>20</StyledTableCell>
                <StyledTableCell>15</StyledTableCell>
                <StyledTableCell>9</StyledTableCell>
                <StyledTableCell>10</StyledTableCell>
                <StyledTableCell>4</StyledTableCell>
                <StyledTableCell>3</StyledTableCell>
                <StyledTableCell>132</StyledTableCell>
                <StyledTableCell>123</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<KeyboardArrowRightIcon />}
                    href='/Statistik Ranting'
                  >
                    Lihat
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Dojo Wijaya Kusuma</StyledTableCell>
                <StyledTableCell>40</StyledTableCell>
                <StyledTableCell>30</StyledTableCell>
                <StyledTableCell>35</StyledTableCell>
                <StyledTableCell>31</StyledTableCell>
                <StyledTableCell>40</StyledTableCell>
                <StyledTableCell>30</StyledTableCell>
                <StyledTableCell>20</StyledTableCell>
                <StyledTableCell>15</StyledTableCell>
                <StyledTableCell>9</StyledTableCell>
                <StyledTableCell>10</StyledTableCell>
                <StyledTableCell>4</StyledTableCell>
                <StyledTableCell>3</StyledTableCell>
                <StyledTableCell>132</StyledTableCell>
                <StyledTableCell>123</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<KeyboardArrowRightIcon />}
                  >
                    Lihat
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Dojo Menara Kuwait</StyledTableCell>
                <StyledTableCell>40</StyledTableCell>
                <StyledTableCell>30</StyledTableCell>
                <StyledTableCell>35</StyledTableCell>
                <StyledTableCell>31</StyledTableCell>
                <StyledTableCell>40</StyledTableCell>
                <StyledTableCell>30</StyledTableCell>
                <StyledTableCell>20</StyledTableCell>
                <StyledTableCell>15</StyledTableCell>
                <StyledTableCell>9</StyledTableCell>
                <StyledTableCell>10</StyledTableCell>
                <StyledTableCell>4</StyledTableCell>
                <StyledTableCell>3</StyledTableCell>
                <StyledTableCell>132</StyledTableCell>
                <StyledTableCell>123</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<KeyboardArrowRightIcon />}
                  >
                    Lihat
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            //   count={hotel.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  );
}

const StyledTableCell = withStyles(theme => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
