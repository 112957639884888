import React, { useState, useEffect } from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Link,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';

import Pagination from '@material-ui/lab/Pagination';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Berita from '../Component/allCard/230Card';
import MiniBerita from '../Component/allCard/150Card';

import Api from '../../Services/Api';
import StaticVar from '../../Config/StaticVar';
import moment from 'moment';

import Background from "../../Assets/Images/bg_KK_PK.png";

function SemuaBerita() {
  const [topBerita, setTopBerita] = useState([]);
  const [allBerita, setAllBerita] = useState([]);

  const [IDTopBerita, setIDTopBerita] = useState('');
  const [topPicture, setTopPicture] = useState('');
  const [topTanggal, setTopTanggal] = useState('');
  const [topJudul, setTopJudul] = useState('');
  const [topKontent, setTopKontent] = useState('');

  useEffect(() => {
    Api.getHighlightRequest().then(res => {
      setTopBerita(res.data);
      console.log('Top Berita', res.data);
    });
    Api.getNewsRequest().then(res => {
      setAllBerita(res.data);
      console.log('All Berita', res.data);
    });
  }, []);

  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              Semua Berita
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}
        >
          <Grid item md={9} style={{ paddingRight: 50 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Berita Umum
            </Typography>

            <div style={{ paddingTop: 10 }}>
              <Grid container spacing={3}>
                {topBerita.map(item => (
                  <Grid item md={6}>
                    <Link
                      href={item.link + '/' + item.title}
                      color='inherit'
                      style={{ textAlign: 'left', textDecoration: 'none' }}
                    >
                      <Berita
                        // links={item.link}
                        gambar={StaticVar.URL_PORTAL + '/repo/' + item.picture}
                        tanggal={moment(item.created.create_date).format(
                          'DD MMMM YYYY'
                        )}
                        // penerbit='Lemkari Post'
                        judul={item.title}
                      />
                    </Link>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={3} style={{ paddingTop: 20 }}>
                {allBerita.map(item => {
                  return (
                    <Grid item md={4}>
                      <MiniBerita
                        // style={{ textAlign: 'left', textDecoration: 'none' }}
                        // links={item.link}
                        gambar={StaticVar.URL_PORTAL + '/repo/' + item.picture}
                        tanggal={moment(item.create_date).format(
                          'DD MMMM YYYY'
                        )}
                        penerbit={item.creator}
                        judul={item.title}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container style={{ paddingTop: 40 }}>
                <Grid item md={3} />
                <Grid item md={6} style={{ textAlign: 'center' }}>
                  <div>
                    <Pagination
                      count={10}
                      // variant='outlined'
                      shape='rounded'
                      color='primary'
                    />
                  </div>
                </Grid>
                <Grid item md={3} />
              </Grid>
            </div>
          </Grid>
          <Grid item md={3}>
            <div>
              <List style={{ marginTop: -10 }}>
                <div>
                  <Typography
                    style={{
                      fontSize: 24,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    Kategori Berita
                  </Typography>
                </div>
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Lemkari' color='inherit'>
                      <Typography>Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='' color='inherit'>
                      <Typography>Karate</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='' color='inherit'>
                      <Typography>Umum</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
              </List>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SemuaBerita;
