import React from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Divider,
  Card,
  Button,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';

import Background from "../../Assets/Images/bg_KK_PK.png";

import ListCard from '../Component/listCard/150Card';

export default function Event() {
  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              Event
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingLeft: 40, paddingTop: 20 }}>
        <Grid container>
          <Grid item md={1} style={{ paddingTop: 40 }}>
            <Typography>Filter:</Typography>
          </Grid>
          <Grid item md={2} style={{ paddingRight: 30 }}>
            <Typography>Waktu Event</Typography>
            <TextField
              variant='outlined'
              label='September'
              size='small'
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ paddingRight: 30 }}>
            <Typography>Kategori Event</Typography>
            <TextField
              variant='outlined'
              label='Semua Kotegori'
              size='small'
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2}>
            <Typography>Status Event</Typography>
            <TextField
              variant='outlined'
              label='Semua Status'
              size='small'
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingLeft: 40, paddingTop: 20, paddingRight: 40 }}>
        <Grid container>
          <Grid item md={1}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              September
            </Typography>
          </Grid>
          <Grid item md={11} style={{ paddingTop: 20, paddingLeft: 30 }}>
            <Divider style={{ backgroundColor: '#F2C94C', paddingBottom: 2 }} />
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingLeft: 40, paddingTop: 20, paddingRight: 40 }}>
        <ListCard
          judul='Pelatian berbasis sport - science untuk pelatih'
          tanggal='Selesa - Minggu, 03-08 Sep 2019'
          tempat='Gedung Sarbini Wiladatika-Cibubur, Depok-Jawa Barat'
          alamat='Jl.Pusdika Raya No.6B Harjamukti Kec.Cimanggis kota Depok Jawa
        Barat 16454'
          status='Selesai'
        />
        <ListCard
          judul='Pelatian berbasis sport - science untuk pelatih'
          tanggal='Selesa - Minggu, 03-08 Sep 2019'
          tempat='Gedung Sarbini Wiladatika-Cibubur, Depok-Jawa Barat'
          alamat='Jl.Pusdika Raya No.6B Harjamukti Kec.Cimanggis kota Depok Jawa
        Barat 16454'
          status='Selesai'
        />
        <ListCard
          judul='Pelatian berbasis sport - science untuk pelatih'
          tanggal='Selesa - Minggu, 03-08 Sep 2019'
          tempat='Gedung Sarbini Wiladatika-Cibubur, Depok-Jawa Barat'
          alamat='Jl.Pusdika Raya No.6B Harjamukti Kec.Cimanggis kota Depok Jawa
        Barat 16454'
          status='Selesai'
        />
      </div>
    </div>
  );
}
