import React, { useState } from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Link,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import GBesar from '../Component/listCard/498Foto';
import GKecil from '../Component/allCard/150Card';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import PersonIcon from '@material-ui/icons/Person';
import CallIcon from '@material-ui/icons/Call';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import TablePagination from '@material-ui/core/TablePagination';

import logo from '../../Assets/Images/logo_papua.png';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function Rangking() {
  const [value, setValue] = React.useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div
      style={{ paddingBottom: 50, marginTop: 80, backgroundColor: '#E5E5E5' }}
    >
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundColor: '#d6261d',
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              <Link href='/Statistik' color='inherit'>
                Statistik
              </Link>{' '}
              / Dojo SKI
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ width: '100%', backgroundColor: 'white' }}>
        <Grid
          container
          spacing={3}
          style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}
        >
          <Grid item md={9}>
            <GBesar />
          </Grid>
          <Grid item md={3}>
            <div>
              <GKecil />
            </div>
            <div style={{ paddingTop: 10 }}>
              <GKecil />
            </div>
            <div style={{ paddingTop: 10 }}>
              <GKecil />
            </div>
          </Grid>
        </Grid>
        <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 10 }}>
          <Typography style={{ fontSize: 36, fontWeight: 'bold' }}>
            Dojo SKI (Sekolah Karate Intelektika)
          </Typography>
          <Grid container>
            <Grid item md={1} style={{ marginRight: -70 }}>
              <div style={{ paddingTop: 10 }}>
                <LocationOnIcon color='secondary' />
              </div>
            </Grid>
            <Grid item md={11}>
              <Typography style={{ fontSize: 18, paddingTop: 10 }}>
                Aula Ibrahim Baitul Jihad
              </Typography>
              <Typography
                style={{ fontSize: 16, paddingTop: 5, color: '#828282' }}
              >
                Jl.Kemang Dahlia Raya Blok ZZ No.1, RT.007/RW.036, Bojong
                Rawalumbu, Kec.Rawalumbu, Kota Bks, Jawa Barat 17116
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}>
          <Tabs
            value={value}
            indicatorColor='primary'
            textColor='primary'
            onChange={handleChange}
            variant='fullWidth'
          >
            <Tab label='Profil' {...a11yProps(0)} />
            <Tab label='MSH' {...a11yProps(1)} />
            <Tab label='Karateka' {...a11yProps(2)} />
            <Tab label='Pengurus' {...a11yProps(3)} />
          </Tabs>
        </div>
      </div>
      <div style={{ paddingLeft: 40, paddingRight: 40 }}>
        <TabPanel value={value} index={0}>
          <div style={{ paddingTop: 30 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Struktur Kepengurusan
            </Typography>
            <Grid container style={{ paddingTop: 15 }}>
              <Grid item md={2}>
                <Typography style={{ fontSize: 16 }}>Ketua</Typography>
                <Typography style={{ fontSize: 16 }}>Sekretaris</Typography>
                <Typography style={{ fontSize: 16 }}>Bendahara</Typography>
                <Typography style={{ fontSize: 16 }}>Koordinator</Typography>
                <Typography style={{ fontSize: 16 }}>Pelatih</Typography>
              </Grid>
              <Grid item md={10}>
                <Typography style={{ fontSize: 16 }}>: Hanafi</Typography>
                <Typography style={{ fontSize: 16 }}>
                  : Endang Suryati
                </Typography>
                <Typography style={{ fontSize: 16 }}>: Siti Afifah</Typography>
                <Typography style={{ fontSize: 16 }}>: Widiani</Typography>
                <Typography style={{ fontSize: 16 }}>: Eni</Typography>
                <Typography style={{ fontSize: 16 }}>
                  : 1. Senpai Carkim (DAN II)
                </Typography>
                <Typography style={{ fontSize: 16, paddingLeft: 10 }}>
                  2. Senpai Lucky (DAN I)
                </Typography>
                <Typography style={{ fontSize: 16, paddingLeft: 10 }}>
                  3. Senpai Dwi Murti (DAN I)
                </Typography>
                <Typography style={{ fontSize: 16, paddingLeft: 10 }}>
                  4. Senpai Ardrian (DAN I)
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Visi & Misi
            </Typography>
            <Typography
              style={{ fontSize: 18, fontWeight: 'bold', paddingTop: 15 }}
            >
              Visi
            </Typography>
            <Typography style={{ fontSize: 16, paddingTop: 5 }}>
              "Karateka Berprestasi yang dilandasi semangat Bushido dan berjiwa
              Pancasila"
            </Typography>
            <Typography
              style={{ fontSize: 18, fontWeight: 'bold', paddingTop: 15 }}
            >
              Misi
            </Typography>
            <Typography style={{ fontSize: 16, paddingTop: 5 }}>
              1. Membentuk karakter Karateka yang berdisiplin, bertanggungjawab,
              berani, pantang menyerah dan bisa menerima kekalahan{' '}
            </Typography>
            <Typography style={{ fontSize: 16 }}>
              2. Membentuk Atlit Karate yang berprestasi baik di Tingkat Cabang,
              Daerah maupun di Tingkat Nasional.
            </Typography>
            <Typography style={{ fontSize: 16 }}>
              3. Menyelenggarakan kepelatihan dengan teknik-teknik dan
              perlengkapan pendukung yang disesuaikan dengan kebutuhan
            </Typography>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Jumlah Karateka, MSH, dan Pengurus
            </Typography>
            <Grid container style={{ paddingTop: 15 }}>
              <Grid item md={2}>
                <Typography style={{ fontSize: 16 }}>
                  Jumlah Karateka
                </Typography>
                <Typography style={{ fontSize: 16 }}>Jumlah MSH</Typography>
                <Typography style={{ fontSize: 16 }}>
                  Jumlah Pengurus
                </Typography>
              </Grid>
              <Grid item md={10}>
                <Typography style={{ fontSize: 16 }}>: 123</Typography>
                <Typography style={{ fontSize: 16 }}>: 123</Typography>
                <Typography style={{ fontSize: 16 }}>: 123</Typography>
              </Grid>
            </Grid>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Fasilitas
            </Typography>
            <Grid container style={{ paddingTop: 15 }}>
              <Grid item md={2}>
                <Typography style={{ fontSize: 16 }}>
                  1. Ruang Tertutup
                </Typography>
                <Typography style={{ fontSize: 16 }}>2. Matras</Typography>
                <Typography style={{ fontSize: 16 }}>3. Samsak</Typography>
                <Typography style={{ fontSize: 16 }}>
                  4. Body Protektor
                </Typography>
                <Typography style={{ fontSize: 16 }}>
                  5. Hand Protektor
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 16, paddingTop: 25 }}>
                  8 Buah
                </Typography>
                <Typography style={{ fontSize: 16 }}>20 Buah</Typography>
                <Typography style={{ fontSize: 16 }}>20 Buah</Typography>
                <Typography style={{ fontSize: 16 }}>20 Buah</Typography>
              </Grid>
              <Grid item md={3}>
                <Typography style={{ fontSize: 16 }}>
                  6. Footwear Protection
                </Typography>
                <Typography style={{ fontSize: 16 }}>
                  7. Face Maker Karate
                </Typography>
                <Typography style={{ fontSize: 16 }}>8. Sabuk</Typography>
                <Typography style={{ fontSize: 16 }}>
                  9. Pelatihan yang berkualitas
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 16 }}>20 Buah</Typography>
                <Typography style={{ fontSize: 16 }}>20 Buah</Typography>
                <Typography style={{ fontSize: 16 }}>200 Buah</Typography>
              </Grid>
            </Grid>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Biaya Pendidikan
            </Typography>
            <Grid container style={{ paddingTop: 15 }}>
              <Grid item md={3}>
                <Typography style={{ fontSize: 16 }}>Pendaftaran</Typography>
                <Typography style={{ fontSize: 16 }}>
                  SPP PErsemester
                </Typography>
                <Typography style={{ fontSize: 16 }}>Seragam</Typography>
                <Typography style={{ fontSize: 16 }}>
                  Ujian Sabuk Tiap Semester
                </Typography>
                <Typography style={{ fontSize: 16 }}>
                  Total Biaya Semester Pertama
                </Typography>
              </Grid>
              <Grid item md={9}>
                <Typography style={{ fontSize: 16 }}>: Rp 200.000,-</Typography>
                <Typography style={{ fontSize: 16 }}>
                  : Rp 2.000.000,-
                </Typography>
                <Typography style={{ fontSize: 16 }}>
                  : Rp 1.000.000,-
                </Typography>
                <Typography style={{ fontSize: 16 }}>: Rp 500.000,-</Typography>
                <Typography style={{ fontSize: 16 }}>
                  : Rp 3.700.000,-
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Kontak Kami
            </Typography>
            <Grid container style={{ paddingTop: 15 }}>
              <Grid item md={1} style={{ marginRight: -60 }}>
                <div>
                  <PersonIcon />
                </div>
                <div style={{ paddingTop: 10 }}>
                  <CallIcon />
                </div>
                <div style={{ paddingTop: 10 }}>
                  <WhatsAppIcon />
                </div>
                <div style={{ paddingTop: 10 }}>
                  <EmailIcon />
                </div>
              </Grid>
              <Grid item md={11}>
                <Typography style={{ fontSize: 16 }}>Hanafi</Typography>
                <Typography style={{ fontSize: 16, paddingTop: 15 }}>
                  (021)85454545
                </Typography>
                <Typography style={{ fontSize: 16, paddingTop: 20 }}>
                  085725252
                </Typography>
                <Typography style={{ fontSize: 16, paddingTop: 15 }}>
                  dojoski@gmail.com
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              Info Lokasi
            </Typography>
            <Grid container>
              <Grid item md={1} style={{ marginRight: -70 }}>
                <div style={{ paddingTop: 10 }}>
                  <LocationOnIcon color='secondary' />
                </div>
              </Grid>
              <Grid item md={11}>
                <Typography style={{ fontSize: 18, paddingTop: 10 }}>
                  Aula Ibrahim Baitul Jihad
                </Typography>
                <Typography
                  style={{ fontSize: 16, paddingTop: 5, color: '#828282' }}
                >
                  Jl.Kemang Dahlia Raya Blok ZZ No.1, RT.007/RW.036, Bojong
                  Rawalumbu, Kec.Rawalumbu, Kota Bks, Jawa Barat 17116
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              width: '100%',
              height: 300,
              paddingTop: 30,
              textAlign: 'center',
              backgroundColor: 'white',
              marginTop: 20,
            }}
          >
            <Typography>Google Maps Disini</Typography>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div style={{ paddingTop: 20 }}>
            <Grid container>
              <Grid item md={1} style={{ paddingTop: 40 }}>
                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                  Filter:
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Jenis Kelamin</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    backgroundColor: 'white',
                    width: 193,
                  }}
                >
                  <option value='Semua Jenis Kelamin'>
                    Semua Jenis Kelamin
                  </option>
                </TextField>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Jabatan</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    backgroundColor: 'white',
                    width: 193,
                  }}
                >
                  <option value='Jabatan'>Jabatan</option>
                </TextField>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>DAN</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{ backgroundColor: 'white', width: 193 }}
                >
                  <option value='Semua DAN'>Semua DAN</option>
                </TextField>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Tanggal Member</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{ backgroundColor: 'white', width: 193 }}
                >
                  <option value='Semua Tanggal'>Semua Tanggal</option>
                </TextField>
              </Grid>
              <Grid item={3}>
                <TextField
                  variant='outlined'
                  label='Searching'
                  size='small'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    backgroundColor: 'white',
                    width: '100%',
                    marginTop: 18,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ paddingTop: 20 }}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead style={{ backgroundColor: '#D6261D' }}>
                  <TableRow style={{ height: 60 }}>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell>Nama</StyledTableCell>
                    <StyledTableCell>Jenis Kelamin</StyledTableCell>
                    <StyledTableCell>Tgl Lahir</StyledTableCell>
                    <StyledTableCell>Jabatan</StyledTableCell>
                    <StyledTableCell>DAN</StyledTableCell>
                    <StyledTableCell>Lemkari ID</StyledTableCell>
                    <StyledTableCell>Tgl Member</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>
                      <Grid container>
                        <Grid item md={1} style={{ paddingRight: 50 }}>
                          <Avatar>A</Avatar>
                        </Grid>
                        <Grid item md={8} style={{ paddingTop: 7 }}>
                          <Typography> Cantika Putri Amelia Farasya</Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>Perempuan</StyledTableCell>
                    <StyledTableCell>04/08/1997</StyledTableCell>
                    <StyledTableCell>Pelatih</StyledTableCell>
                    <StyledTableCell>I</StyledTableCell>
                    <StyledTableCell>19-30-123-22</StyledTableCell>
                    <StyledTableCell>12/03/2020</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>
                      <Grid container>
                        <Grid item md={1} style={{ paddingRight: 50 }}>
                          <Avatar>A</Avatar>
                        </Grid>
                        <Grid item md={8} style={{ paddingTop: 7 }}>
                          <Typography> Cantika Putri Amelia Farasya</Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>Perempuan</StyledTableCell>
                    <StyledTableCell>04/08/1997</StyledTableCell>
                    <StyledTableCell>Pelatih</StyledTableCell>
                    <StyledTableCell>I</StyledTableCell>
                    <StyledTableCell>19-30-123-22</StyledTableCell>
                    <StyledTableCell>12/03/2020</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>
                      <Grid container>
                        <Grid item md={1} style={{ paddingRight: 50 }}>
                          <Avatar>A</Avatar>
                        </Grid>
                        <Grid item md={8} style={{ paddingTop: 7 }}>
                          <Typography> Cantika Putri Amelia Farasya</Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>Perempuan</StyledTableCell>
                    <StyledTableCell>04/08/1997</StyledTableCell>
                    <StyledTableCell>Pelatih</StyledTableCell>
                    <StyledTableCell>I</StyledTableCell>
                    <StyledTableCell>19-30-123-22</StyledTableCell>
                    <StyledTableCell>12/03/2020</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                //   count={hotel.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div style={{ paddingTop: 20 }}>
            <Grid container>
              <Grid item md={1} style={{ paddingTop: 40 }}>
                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                  Filter:
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Jenis Kelamin</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    backgroundColor: 'white',
                    width: 193,
                  }}
                >
                  <option value='Semua Jenis Kelamin'>
                    Semua Jenis Kelamin
                  </option>
                </TextField>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Kategori Usia</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    backgroundColor: 'white',
                    width: 193,
                  }}
                >
                  <option value='Kategori Usia'>Kategori Usia</option>
                </TextField>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Sabuk</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{ backgroundColor: 'white', width: 193 }}
                >
                  <option value='Semua Sabuk'>Semua Sabuk</option>
                </TextField>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Tanggal Member</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{ backgroundColor: 'white', width: 193 }}
                >
                  <option value='Semua Tanggal'>Semua Tanggal</option>
                </TextField>
              </Grid>
              <Grid item={3}>
                <TextField
                  variant='outlined'
                  label='Searching'
                  size='small'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    backgroundColor: 'white',
                    width: '100%',
                    marginTop: 18,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ paddingTop: 20 }}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead style={{ backgroundColor: '#D6261D' }}>
                  <TableRow style={{ height: 60 }}>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell>Nama</StyledTableCell>
                    <StyledTableCell>Jenis Kelamin</StyledTableCell>
                    <StyledTableCell>Tgl Lahir</StyledTableCell>
                    <StyledTableCell>Kategori Usia</StyledTableCell>
                    <StyledTableCell>Sabuk-Kyu</StyledTableCell>
                    <StyledTableCell>Lemkari ID</StyledTableCell>
                    <StyledTableCell>Tgl Member</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>
                      <Grid container>
                        <Grid item md={1} style={{ paddingRight: 50 }}>
                          <Avatar>A</Avatar>
                        </Grid>
                        <Grid item md={8} style={{ paddingTop: 7 }}>
                          <Typography> Cantika Putri Amelia Farasya</Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>Perempuan</StyledTableCell>
                    <StyledTableCell>04/08/1997</StyledTableCell>
                    <StyledTableCell>Usia Dini</StyledTableCell>
                    <StyledTableCell>Putih-10</StyledTableCell>
                    <StyledTableCell>19-30-123-22</StyledTableCell>
                    <StyledTableCell>12/03/2020</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>
                      <Grid container>
                        <Grid item md={1} style={{ paddingRight: 50 }}>
                          <Avatar>A</Avatar>
                        </Grid>
                        <Grid item md={8} style={{ paddingTop: 7 }}>
                          <Typography> Cantika Putri Amelia Farasya</Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>Perempuan</StyledTableCell>
                    <StyledTableCell>04/08/1997</StyledTableCell>
                    <StyledTableCell>Usia Dini</StyledTableCell>
                    <StyledTableCell>Putih-10</StyledTableCell>
                    <StyledTableCell>19-30-123-22</StyledTableCell>
                    <StyledTableCell>12/03/2020</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>
                      <Grid container>
                        <Grid item md={1} style={{ paddingRight: 50 }}>
                          <Avatar>A</Avatar>
                        </Grid>
                        <Grid item md={8} style={{ paddingTop: 7 }}>
                          <Typography> Cantika Putri Amelia Farasya</Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>Perempuan</StyledTableCell>
                    <StyledTableCell>04/08/1997</StyledTableCell>
                    <StyledTableCell>Usia Dini</StyledTableCell>
                    <StyledTableCell>Putih-10</StyledTableCell>
                    <StyledTableCell>19-30-123-22</StyledTableCell>
                    <StyledTableCell>12/03/2020</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                //   count={hotel.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div style={{ paddingTop: 20 }}>
            <Grid container>
              <Grid item md={1} style={{ paddingTop: 40 }}>
                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                  Filter:
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Jenis Kelamin</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    backgroundColor: 'white',
                    width: 193,
                  }}
                >
                  <option value='Semua Jenis Kelamin'>
                    Semua Jenis Kelamin
                  </option>
                </TextField>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Jabatan</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    backgroundColor: 'white',
                    width: 193,
                  }}
                >
                  <option value='Semua Jabatan'>Semua Jabatan</option>
                </TextField>
              </Grid>
              <Grid item md={2}>
                <Typography style={{ fontSize: 12 }}>Tanggal Member</Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  select
                  SelectProps={{
                    native: true,
                  }}
                  style={{ backgroundColor: 'white', width: 193 }}
                >
                  <option value='Semua Tanggal'>Semua Tanggal</option>
                </TextField>
              </Grid>
              <Grid item={3}>
                <TextField
                  variant='outlined'
                  label='Searching'
                  size='small'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    backgroundColor: 'white',
                    width: '100%',
                    marginTop: 18,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ paddingTop: 20 }}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead style={{ backgroundColor: '#D6261D' }}>
                  <TableRow style={{ height: 60 }}>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell>Nama</StyledTableCell>
                    <StyledTableCell>Jenis Kelamin</StyledTableCell>
                    <StyledTableCell>Tgl Lahir</StyledTableCell>
                    <StyledTableCell>Jabatan</StyledTableCell>
                    <StyledTableCell>Lemkari ID</StyledTableCell>
                    <StyledTableCell>Tgl</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>
                      <Grid container>
                        <Grid item md={1} style={{ paddingRight: 50 }}>
                          <Avatar>A</Avatar>
                        </Grid>
                        <Grid item md={8} style={{ paddingTop: 7 }}>
                          <Typography> Cantika Putri Amelia Farasya</Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>Perempuan</StyledTableCell>
                    <StyledTableCell>04/08/1997</StyledTableCell>
                    <StyledTableCell>Ketua Yayassan</StyledTableCell>
                    <StyledTableCell>19-30-123-22</StyledTableCell>
                    <StyledTableCell>12/03/2020</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>
                      <Grid container>
                        <Grid item md={1} style={{ paddingRight: 50 }}>
                          <Avatar>A</Avatar>
                        </Grid>
                        <Grid item md={8} style={{ paddingTop: 7 }}>
                          <Typography> Cantika Putri Amelia Farasya</Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>Perempuan</StyledTableCell>
                    <StyledTableCell>04/08/1997</StyledTableCell>
                    <StyledTableCell>Ketua Yayassan</StyledTableCell>
                    <StyledTableCell>19-30-123-22</StyledTableCell>
                    <StyledTableCell>12/03/2020</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>
                      <Grid container>
                        <Grid item md={1} style={{ paddingRight: 50 }}>
                          <Avatar>A</Avatar>
                        </Grid>
                        <Grid item md={8} style={{ paddingTop: 7 }}>
                          <Typography> Cantika Putri Amelia Farasya</Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>Perempuan</StyledTableCell>
                    <StyledTableCell>04/08/1997</StyledTableCell>
                    <StyledTableCell>Ketua Yayassan</StyledTableCell>
                    <StyledTableCell>19-30-123-22</StyledTableCell>
                    <StyledTableCell>12/03/2020</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                //   count={hotel.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

const StyledTableCell = withStyles(theme => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default Rangking;
