import React, { Component, Fragment } from 'react';
import _ from 'lodash';
// * Material UI import
import { Typography, Grid, CssBaseline, Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

// Component
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Menu from '../Component/Menu';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

// Image
import AbstractImg from '../../Assets/Images/background_abstract.png';

import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { withStyles } from '@material-ui/core/styles';
import PhotoAlbumOutlinedIcon from '@material-ui/icons/PhotoAlbumOutlined';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import VideoCallOutlinedIcon from '@material-ui/icons/VideoCallOutlined';

import PropTypes from 'prop-types';

import Api from '../../Services/Api';
import StaticVar from '../../Config/StaticVar';

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      pages: [],
      fullWidth: false,
      loading: false,
      params: '',
    };
  }

  getDataPages() {
    setTimeout(() => {
      this.setState({ loading: true });
      Api.getPagesByNameRequest(localStorage.pages)
        .then(res => {
          this.setState({
            content: res.data,
            fullWidth: res.data[0].fullwidth,
            loading: false,
          });
        })
        .catch(err => {
          console.log('error', err);
          this.setState({ loading: false });
        });
    }, 1000);
  }

  componentDidMount() {
    this.getData();
    this.getDataPages();
  }

  componentWillReceiveProps() {
    this.getDataPages();
  }

  getData() {
    Api.getPagesRequest()
      .then(res => {
        this.setState({ pages: res.data });
      })
      .catch(err => {
        console.log('error', err);
      });
  }

  render() {
    const { classes } = this.props;
    const { name } = this.props.match.params;
    const { content, pages, fullWidth, loading } = this.state;
    localStorage.setItem('pages', name);

    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Menu
          titleMenu={
            <div>
              <Typography className={classes.txtTitle}>{name}</Typography>
              <div style={{ marginBottom: 40 }} />
            </div>
          }
        />
        <main
          style={{
            backgroundImage: 'linear-gradient(to right, #ffffff , #c4c4c4 )',
            height: '100%',
          }}
        >
          <div className={classes.container}>
            {loading ? (
              <div
                style={{
                  backgroundColor: '#fff',
                  height: 520,
                  paddingTop: 100,
                }}
              >
                <ContentLoader
                  speed={2}
                  width={'100%'}
                  height={500}
                  viewBox='0 0 400 160'
                  backgroundColor='#f3f3f3'
                  foregroundColor='#ecebeb'
                >
                  <rect x='48' y='8' rx='3' ry='3' width='88' height='6' />
                  <rect x='48' y='26' rx='3' ry='3' width='52' height='6' />
                  <rect x='0' y='56' rx='3' ry='3' width='410' height='6' />
                  <rect x='0' y='72' rx='3' ry='3' width='380' height='6' />
                  <rect x='0' y='88' rx='3' ry='3' width='178' height='6' />
                  <circle cx='20' cy='20' r='20' />
                </ContentLoader>
              </div>
            ) : (
              <Grid container className={classes.containerResponsive}>
                {fullWidth ? (
                  <Grid container>
                    {content.length > 0
                      ? content.map((item, index) => {
                          return (
                            <Grid
                              container
                              key={index}
                              style={{ marginTop: 20 }}
                            >
                              <Grid item lg={12} md={12} xs={12}>
                                <div
                                  className={classes.contentBeritaTxt}
                                  dangerouslySetInnerHTML={{
                                    __html: item.content,
                                  }}
                                ></div>
                              </Grid>
                            </Grid>
                          );
                        })
                      : null}
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item lg={8} md={9} sm={12} xs={12}>
                      {content.length > 0
                        ? content.map((item, index) => {
                            return (
                              <Grid
                                container
                                key={index}
                                style={{ marginTop: 20 }}
                              >
                                <Grid item lg={12} md={12}>
                                  <div
                                    className={classes.contentBeritaTxt}
                                    dangerouslySetInnerHTML={{
                                      __html: item.content,
                                    }}
                                  ></div>
                                </Grid>
                              </Grid>
                            );
                          })
                        : null}
                    </Grid>
                    <Grid item lg={1} md={1} />
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                      style={{ marginTop: 20 }}
                    >
                      <Grid container>
                        {pages.map((item, index) => {
                          return item.parent === 'Tanpa Parent' ? (
                            <Grid
                              item
                              key={index}
                              xs={12}
                              style={{ marginBottom: 20 }}
                            >
                              <ExpansionPanel expanded>
                                <ExpansionPanelSummary
                                  // expandIcon={
                                  //   null
                                  // }
                                  aria-controls='panel1a-content'
                                  id='panel1a-header'
                                  component={NavLink}
                                  to={item.name}
                                >
                                  <Grid container>
                                    <Grid item xs={1}>
                                      <KeyboardArrowRightOutlinedIcon
                                        style={{ color: '#bf272b' }}
                                      />
                                    </Grid>
                                    <Grid item xs={11}>
                                      <Typography
                                        className={
                                          classes.txtMenuMobileExpandSummary
                                        }
                                      >
                                        {item.name}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Grid container>
                                    {pages.map((val, index) => {
                                      if (val.parent !== '') {
                                        if (val.parent === item._id) {
                                          return (
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ marginBottom: 5 }}
                                            >
                                              <Button
                                                key={index}
                                                style={btnMenuMobile}
                                                component={NavLink}
                                                to={val.name}
                                              >
                                                <Grid container>
                                                  <Grid item xs={2}>
                                                    <ArrowRightIcon
                                                      style={{
                                                        marginLeft: 5,
                                                        color: '#de701d',
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={10}>
                                                    <Typography
                                                      className={
                                                        classes.txtMenuMobileExpandDetail
                                                      }
                                                    >
                                                      {val.name}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </Button>
                                            </Grid>
                                          );
                                        }
                                      }
                                    })}
                                  </Grid>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </Grid>
                          ) : null;
                        })}
                      </Grid>
                      <Grid
                        container
                        component={NavLink}
                        to='/news'
                        style={{
                          borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <Grid item>
                          <AnnouncementOutlinedIcon
                            style={{ color: '#bf272b' }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography className={classes.txtmenu1}>
                            Berita Selengkapnya
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        component={NavLink}
                        to='/photos'
                        style={{
                          marginTop: 20,
                          borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <Grid item>
                          <PhotoAlbumOutlinedIcon
                            style={{ color: '#bf272b' }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography className={classes.txtmenu1}>
                            Galeri Foto Lainnya
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        component={NavLink}
                        to='/video'
                        style={{
                          marginTop: 20,
                          borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <Grid item>
                          <VideoCallOutlinedIcon style={{ color: '#bf272b' }} />
                        </Grid>
                        <Grid item>
                          <Typography className={classes.txtmenu1}>
                            Video dan Live Streaming Lainnya
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </main>
        {/* Footer */}
        <footer>
          <Footer />
        </footer>
        {/* End footer */}
      </Fragment>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      background: 'none',
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${AbstractImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
    },
  },
  containerResponsive: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 40,
      paddingRight: 50,
      paddingTop: 10,
    },
    paddingBottom: 40,
  },
  BeritaImg: {
    width: '100%',
    //height:400
  },
  titleBeritaTxt: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 16,
  },
  contentBeritaTxt: {
    fontSize: 14,
    fontWeight: 200,
    marginBottom: 20,
    width: '100%',
  },
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 27,
    },
  },
  btnMenuMobile: {
    [theme.breakpoints.down('xs')]: {
      height: 35,
    },
    [theme.breakpoints.up('sm')]: {
      height: 35,
    },
    width: '100%',
    zIndex: 10,
    borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
    borderRadius: 0,
    height: 47,
  },
  txtMenuMobile: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    position: 'absolute',
    left: 0,
    textTransform: 'none',
    fontWeight: 300,
  },

  txtMenuMobileExpandSummary: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    position: 'absolute',
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 300,
    bottom: 10,
  },

  txtmenu1: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    marginLeft: 10,
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 300,
    color: '#000000',
  },

  txtMenuMobileExpandDetail: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
    position: 'absolute',
    left: 48,
    top: 7,
    textTransform: 'none',
    fontWeight: 200,
  },

  logoFooterIconStyle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 130,
      width: 155,
      height: 270,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 65,
      width: 220,
      height: 360,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      width: 350,
      height: 465,
    },
    right: 0,
    zIndex: 1,
    position: 'absolute',
  },
  btnMenuMobile: {
    [theme.breakpoints.down('xs')]: {
      height: 35,
    },
    [theme.breakpoints.up('sm')]: {
      height: 35,
    },
    width: '100%',
    zIndex: 10,
    borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
    borderRadius: 0,
    height: 47,
  },
  iconFollowUs: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 25,
    },
    color: 'white',
    marginRight: 20,
    zIndex: 10,
  },
  containerFooter: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 25,
      paddingBottom: 25,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 80,
      paddingBottom: 50,
    },
    backgroundImage: 'linear-gradient(to right, #bf272b , #601416 )',
    paddingLeft: 24,
    paddingRight: 24,
  },
});

const ExpansionPanel = withStyles({
  root: {
    zIndex: 10,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:not(:last-child)': {
      borderBottom: 0,
      // marginBottom:10,
    },
    '&:before': {
      display: 'none',
      // marginBottom:10,
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
    height: 35,
    padding: 0,
    minHeight: 0,
    '&$expanded': {
      height: 35,
      minHeight: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

const btnMenuMobile = {
  width: '100%',
  height: 35,
};

export default withStyles(useStyles)(Pages);
