import React, { useState, useEffect } from 'react';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Link,
  Avatar,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import Api from '../../Services/Api';
import Background from '../../Assets/Images/bg_KK_PK.png';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function ADART() {
  const [value, setValue] = React.useState(0);

  const [content, setContent] = useState([]);
  const [judul, setJudul] = useState([]);

  useEffect(() => {
    Api.getPasalRequest().then(res => {
      setContent(res.data);
      console.log('Data Content', res.data);
    });
    // Api.getPasalRequest().then(res => {
    //   setJudul(res.data);
    //   console.log('Data Pages', res.data);
    // });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundImage: `url(${Background})`,
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              AD/ART Lemkari 2016
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingTop: 20, paddingLeft: 40, paddingRight: 40 }}>
        <Grid container>
          <Grid item md={8} style={{ paddingRight: 50 }}>
            <div>
              <Paper square style={{ borderRadius: 5 }}>
                <Tabs
                  value={value}
                  indicatorColor='primary'
                  textColor='primary'
                  onChange={handleChange}
                  variant='fullWidth'
                >
                  <Tab label='Anggaran Dasar' {...a11yProps(0)} />
                  <Tab label='Anggaran Rumah Tangga' {...a11yProps(1)} />
                </Tabs>
              </Paper>

              <TabPanel value={value} index={0}>
                <Typography
                  style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
                >
                  Anggaran Dasar
                </Typography>
                {content
                  .filter(x => x.type === 'Anggaran Dasar')
                  .map(item => (
                    <div style={{ paddingTop: 20 }}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <IconButton
                              style={{
                                backgroundColor: '#F58634',
                                color: 'white',
                              }}
                              size='small'
                            >
                              {' '}
                              <ArrowDropDownIcon />
                            </IconButton>
                          }
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                        >
                          <Typography
                            style={{ fontSize: 18, fontWeight: 'bold' }}
                          >
                            {item.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.content,
                            }}
                          ></div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Typography
                  style={{ fontSize: 24, fontWeight: 'bold', paddingTop: 20 }}
                >
                  Anggaran Rumah Tangga
                </Typography>
                {content
                  .filter(x => x.type === 'Anggaran Rumah Tangga')
                  .map(item => (
                    <div style={{ paddingTop: 20 }}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <IconButton
                              style={{
                                backgroundColor: '#F58634',
                                color: 'white',
                              }}
                              size='small'
                            >
                              {' '}
                              <ArrowDropDownIcon />
                            </IconButton>
                          }
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                        >
                          <Typography
                            style={{ fontSize: 18, fontWeight: 'bold' }}
                          >
                            {item.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{item.content}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
              </TabPanel>
            </div>
          </Grid>
          <Grid item md={4}>
            <div>
              <List style={{ marginTop: -20 }}>
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Sejarah Karate' color='inherit'>
                      <Typography>Sejarah Karate</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Sejarah Lemkari' color='inherit'>
                      <Typography>Sejarah Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Arti Lambang Lemkari' color='inherit'>
                      <Typography>Arti Lambang Lemkari</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/AD & ART' color='inherit'>
                      <Typography>AD/ART</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Visi & Misi' color='inherit'>
                      <Typography>Visi & Misi</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Pengurus Besar' color='inherit'>
                      <Typography>Pengurus Besar</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Dewan Guru' color='inherit'>
                      <Typography>Dewan Guru</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText>
                    <Link href='/Pengurus Provinsi' color='inherit'>
                      <Typography>Pengurus Provinsi</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Divider />
              </List>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ADART;
