import React from 'react';

import { Typography, Grid, Card, Button } from '@material-ui/core';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Egambar from '../../../Assets/Images/rapat.jpg';

export default function Card150(props) {
  return (
    <div style={{ paddingBottom: 10 }}>
      <Card>
        <Grid container style={{ paddingRight: 20 }}>
          <Grid item md={1}>
            <img src={Egambar} style={{ width: 150, height: 150 }} />
          </Grid>
          <Grid item md={10} style={{ paddingLeft: 65, paddingTop: 10 }}>
            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
              {props.judul}
            </Typography>
            <Grid container>
              <Grid item md={1} style={{ marginRight: -35 }}>
                <div style={{ paddingTop: 10 }}>
                  <DateRangeIcon />
                </div>
                <div style={{ paddingTop: 10 }}>
                  <LocationOnIcon color='secondary' />
                </div>
              </Grid>
              <Grid item md={11}>
                <Typography style={{ fontSize: 14, paddingTop: 10 }}>
                  {props.tanggal}
                </Typography>
                <Typography style={{ fontSize: 14, paddingTop: 15 }}>
                  {props.tempat}
                </Typography>
                <Typography
                  style={{ fontSize: 12, color: '#828282', paddingTop: 5 }}
                >
                  {props.alamat}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} style={{ paddingTop: 20 }}>
            <div
              style={{
                width: 94,
                height: 20,
                backgroundColor: '#51C273',
                textAlign: 'center',
                borderRadius: 4,
              }}
            >
              <Typography
                style={{ fontSize: 10.5, color: 'white', paddingTop: 2 }}
              >
                {props.status}
              </Typography>
            </div>
            <div style={{ paddingTop: 60 }}>
              <Button
                variant='contained'
                color='primary'
                endIcon={<KeyboardArrowRightIcon />}
                href='/Event Selesai'
              >
                Lihat
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
