import React, { useState } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Link,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import SearchIcon from '@material-ui/icons/Search';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import gambars from '../../Assets/Images/kerja.jpg';

export default function DetaiGaleriFoto() {
  const [dialogFoto, setDialogFoto] = useState(false);

  const dialogHandleFoto = () => {
    setDialogFoto(true);
  };
  const dialogHandleCloseFoto = () => {
    setDialogFoto(false);
  };

  return (
    <div style={{ paddingBottom: 50, marginTop: 80 }}>
      <div
        style={{
          //   width: '100%',
          height: 80,
          backgroundColor: '#d6261d',
          padding: 20,
        }}
      >
        <Grid container>
          <Grid item md={7}>
            <Typography
              style={{
                fontSize: 24,
                color: '#ffffff',
                fontWeight: 'bold',
              }}
            >
              <Link href='/Galery Foto' color='inherit'>
                Galeri Foto
              </Link>{' '}
              / PB Lemkari
            </Typography>
          </Grid>
          <Grid item={1}>
            <TextField
              variant='outlined'
              label='Searching'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <IconButton>
              <FacebookIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <InstagramIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
            <IconButton>
              <TwitterIcon style={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          spacing={3}
          container
          style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}
        >
          <Grid item md={2}>
            <Button onClick={dialogHandleFoto}>
              <img
                src={gambars}
                style={{ width: '100%', height: 200, borderRadius: 10 }}
              />
            </Button>
          </Grid>
          <Grid item md={3}>
            <img
              src={gambars}
              style={{ width: '100%', height: 200, borderRadius: 10 }}
            />
          </Grid>
          <Grid item md={4}>
            <img
              src={gambars}
              style={{ width: '100%', height: 200, borderRadius: 10 }}
            />
          </Grid>
          <Grid item md={3}>
            <img
              src={gambars}
              style={{ width: '100%', height: 200, borderRadius: 10 }}
            />
          </Grid>

          <Grid item md={3}>
            <img
              src={gambars}
              style={{ width: '100%', height: 200, borderRadius: 10 }}
            />
          </Grid>
          <Grid item md={4}>
            <img
              src={gambars}
              style={{ width: '100%', height: 200, borderRadius: 10 }}
            />
          </Grid>
          <Grid item md={3}>
            <img
              src={gambars}
              style={{ width: '100%', height: 200, borderRadius: 10 }}
            />
          </Grid>
          <Grid item md={2}>
            <img
              src={gambars}
              style={{ width: '100%', height: 200, borderRadius: 10 }}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: 'center', paddingTop: 20 }}>
        <Button variant='outlined' color='primary' style={{ width: 328 }}>
          <Typography>Lihat Lebih Banyak</Typography>
        </Button>
      </div>

      <Dialog
        open={dialogFoto}
        onClose={dialogHandleCloseFoto}
        fullWidth='true'
        maxWidth='md'
      >
        <div>
          <img src={gambars} style={{ width: '100%', height: 480 }} />
        </div>
        <div className='btn'>
          <IconButton size='small' style={{ backgroundColor: '#F58634' }}>
            <ArrowLeftIcon style={{ color: 'white', fontSize: 40 }} />
          </IconButton>
          <IconButton size='small' style={{ backgroundColor: '#F58634' }}>
            <ArrowRightIcon style={{ color: 'white', fontSize: 40 }} />
          </IconButton>
        </div>
      </Dialog>
    </div>
  );
}
