import axios from 'axios';
import StaticVar from '../Config/StaticVar';

// ===> api create
const api = axios.create({
  baseURL: StaticVar.URL_PORTAL,
  // timeout: 10000,
  headers: {},
});

// ===> api list function request

const getNewsRequest = () => api.get('/news');
const getNewsContentRequest = data =>
  api.get('/news/content/' + data.category + '/' + data.title);
const getVideoRequest = () => api.get('/videos');
const getVideoCategoryRequest = () => api.get('/videos_categories');
const getSponsorRequest = () => api.get('/sponsor');
const getHighlightRequest = () => api.get('/highlight');
const getPhotoRequest = () => api.get('/photos');
const getSitemapRequest = () => api.get('/sitemap');
const getPagesRequest = () => api.get('/pages');
const getPagesByNameRequest = data => api.get('/pages/' + data);
const getPagesParentRequest = data => api.get('/pages/parent/' + data);
const getPhotoCategoryRequest = () => api.get('/photos_categories');
const getPhotoCategoryByNameRequest = data =>
  api.get('/photos/categoryByName/' + data);
const getVideoCategoryByNameRequest = data =>
  api.get('/videos/categoryByName/' + data);
const getVideoContentRequest = data =>
  api.get('/videos/content/' + data.category + '/' + data.title);
const getPasalRequest = () => api.get('/pasal');

export const apis = {
  getNewsRequest,
  getNewsContentRequest,
  getVideoCategoryRequest,
  getVideoRequest,
  getSponsorRequest,
  getHighlightRequest,
  getSitemapRequest,
  getPhotoRequest,
  getPagesByNameRequest,
  getPagesRequest,
  getPagesParentRequest,
  getPhotoCategoryRequest,
  getVideoCategoryByNameRequest,
  getPhotoCategoryByNameRequest,
  getVideoContentRequest,

  getPasalRequest,
};

export default apis;
