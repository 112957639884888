import React, { Component,Fragment } from "react";

// * Material UI import
import {
  Typography,
  Grid,
  CssBaseline,
} from "@material-ui/core";

import { NavLink } from "react-router-dom";

// Component
import Header from '../Component/Header';
import Footer from '../Component/Footer';

import PropTypes from 'prop-types';

import AbstractImg from "../../Assets/Images/background_abstract.png";
import { withStyles } from '@material-ui/core/styles';

import Menu from '../Component/Menu'

import Api from "../../Services/Api"
import StaticVar from "../../Config/StaticVar"

class CategoryPhoto extends Component {

  constructor() {
    super();
    this.state = {
      dataFoto:[
      ],
    };
  }

  getDataCategoryPhoto(){
    Api.getPhotoCategoryRequest().then(res=>{
      this.setState({dataFoto:res.data })
    }).catch(err=>{
      console.log("error",err)
    })
  }

  componentDidMount(){
    this.getDataCategoryPhoto();
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
      <CssBaseline />
      <Header/>
      <Menu
          titleMenu={
            <div>
            <Typography className={classes.txtTitle}>Galeri Foto</Typography>
            <div style={{marginBottom:40}}/>
            </div>
          }
        />
      {/* menu unit */}
      <main style={{ backgroundImage:'linear-gradient(to right, #ffffff , #c4c4c4 )', height: "100%" }}>
      
        <div className={classes.container}>
          <div className={classes.containerResponsive}>
            <Grid container>
              <Grid container spacing={3} style={{marginBottom:50}}>
              {
                this.state.dataFoto.map((item)=>{
                  return(
                    <Grid item xs={12} sm={6} md={4} component={NavLink} to={"/photos/category/"+item.name} style={{height:240,marginBottom:20,textDecoration: 'none'}}>
                      <div style={{backgroundImage:`url(${StaticVar.URL_PORTAL+"/repo/"+item.picture})`,backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',width:'100%',height:240,borderRadius:8,paddingTop:190}}>
                        <div style={{background: 'rgba(0, 0, 0, 0.3)',zIndex:2,width:'100%',height:'100%',borderBottomLeftRadius:8,borderBottomRightRadius:8,paddingTop:10}}>
                        <Typography style={{color:'#ffffff',textAlign:'center',textShadow:'1px 2px 1px #1c1c1c'}}>{item.name}</Typography>
                        </div>
                      </div>
                    </Grid>
                  )
                })
              }
              </Grid>
            </Grid>
            </div>
        </div>
        </main>
        <Footer/>
      </Fragment>
    );
  }
}

CategoryPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = theme => ({
  container:{
    [theme.breakpoints.down('sm')]: {
      background:'none'
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage:`url(${AbstractImg})`,
      backgroundRepeat:'no-repeat',
      backgroundSize:'100% 100%'
    }
  },
  containerResponsive:{
    [theme.breakpoints.down('sm')]: {
      paddingLeft:20,
      paddingRight:10,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft:50,
      paddingRight:40,
    },
    paddingTop:30,
  },
  btnSearch: {
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
    [theme.breakpoints.up('md')]: {
      width: '15%',
    },
    backgroundColor: "#7d7d7d",
    height: 57
  },
  txtSearch: {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginBottom: 10
    },
    [theme.breakpoints.up('md')]: {
      width: '85%',
      marginBottom: 30
    },
    backgroundColor: '#bfbfbf',
  },
  txtTitle: {
    color: '#ffffff',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 27,
    },
}
})

export default withStyles(useStyles)(CategoryPhoto);