import React, { Component } from 'react';

// * Material UI import
import {
  Typography,
  Drawer,
  Grid,
  AppBar,
  Toolbar,
  Button,
  ClickAwayListener,
  Paper,
  Hidden,
  withStyles,
} from '@material-ui/core';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import CssBaseline from '@material-ui/core/CssBaseline';

import PropTypes from 'prop-types';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DehazeIcon from '@material-ui/icons/Dehaze';
import CloseIcon from '@material-ui/icons/Close';

import { NavLink } from 'react-router-dom';
import logo from '../../Assets/Images/Lemkari.png';
import iconpapua from '../../Assets/Images/icon-papua-2020.png';
import backgroundTopNavImg from '../../Assets/Images/warnaheader.png';
import belt from '../../Assets/Images/Belt.png';
import stadion from '../../Assets/Images/Stadion.png';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import _ from 'lodash';
import Api from '../../Services/Api';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setDropDownBerita: false,
      setDropDownLayanan: false,
      setDropDownPendaftaran: false,
      menuMobile: false,
      event_support: [],
      sitemap: [],
    };
  }

  getDataSiteMap() {
    Api.getSitemapRequest()
      .then(res => {
        this.setState({ sitemap: res.data });
        console.log('data header', res.data);
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  openDropDownLayanan() {
    this.setState(prevState => ({
      setDropDownLayanan: !prevState.setDropDownLayanan,
    }));
  }

  closeDropDownLayanan() {
    this.setState({
      setDropDownLayanan: false,
    });
  }

  openDropDownPendaftaran() {
    this.setState(prevState => ({
      setDropDownPendaftaran: !prevState.setDropDownPendaftaran,
    }));
  }

  closeDropDownPendaftaran() {
    this.setState({
      setDropDownPendaftaran: false,
    });
  }

  openMenuMobile() {
    this.setState(prevState => ({
      menuMobile: !prevState.menuMobile,
    }));
  }

  closeMenuMobile() {
    this.setState({ menuMobile: false });
  }

  componentDidMount() {
    this.getDataSiteMap();
  }

  render() {
    const { classes } = this.props;
    const { sitemap } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar className={classes.headerBox}>
          <Toolbar style={toolbar}>
            <Hidden smDown>
              <Grid container>
                <Grid
                  container
                  style={{ height: 40, backgroundColor: '#D6261D' }}
                >
                  <Grid item md={2} sm={2}>
                    <Button
                      component={NavLink}
                      to='/'
                      className={classes.lemkariStyle}
                    >
                      <img
                        src={logo}
                        alt='Logo'
                        style={{ width: "100%", backgroundSize: "cover", height: 55 }}
                        // style={{ width: 60, height: 60 }}
                      />
                    </Button>
                  </Grid>

                  <Grid item md={3} sm={4}>
                    <Typography
                      style={{
                        fontFamily: 'Barlow',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 24,
                        color: 'white',
                        marginLeft: -80,
                      }}
                    >
                      Lembaga Karate - Do Indonesia (Lemkari)
                    </Typography>
                  </Grid>

                  <Grid item md={5} sm={4}>
                    <div style={{ paddingLeft: 200 }}>
                      <Button>
                        <img
                          src={stadion}
                          alt='Logo'
                          style={{ width: 25, height: 25 }}
                        />
                      </Button>
                      <Button>
                        <img
                          src={belt}
                          alt='Logo'
                          style={{ width: 25, height: 25 }}
                        />
                      </Button>

                      <Button
                        style={{
                          minWidth: 0,
                          width: '50%',
                          height: 22,
                          padding: 0,
                          paddingTop: 1,
                          backgroundColor: 'white',
                          border: '1px solid #bababa',
                          marginTop: 0,
                        }}
                      >
                        <Typography className={classes.txtTiket}>
                          E-learning
                        </Typography>
                      </Button>
                    </div>
                  </Grid>
                  <Grid item md={2} style={{ position: 'relative' }}>
                    <ClickAwayListener
                      onClickAway={() => this.closeDropDownPendaftaran()}
                    >
                      <div>
                        <Button
                          style={{
                            width: '70%',
                            backgroundColor: '#F58634',
                            minWidth: 0,
                            height: 22,
                            padding: 0,
                            paddingTop: 1,
                            marginTop: 8,
                          }}
                          onClick={() => this.openDropDownPendaftaran()}
                        >
                          <Typography className={classes.txtPendaftaran}>
                            Lemkari ID
                          </Typography>
                          <ExpandMoreIcon style={{ color: '#ffffff' }} />
                        </Button>
                        {this.state.setDropDownPendaftaran ? (
                          <Paper style={dropDownStylePendaftaran}>
                            <div>
                              <Grid item>
                                {/* <a href="https://entries.ponxx2020papua.com/"> */}
                                <Button
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    width: '100%',
                                    alignItems: 'unset',
                                    justifyContent: 'unset',
                                  }}
                                >
                                  <Typography
                                    className={classes.txtNavbarDropDown}
                                  >
                                    Kontingen
                                  </Typography>
                                </Button>
                                {/* </a> */}
                              </Grid>
                              <Grid item>
                                {/* <a href="https://accreditation.ponxx2020papua.com/"> */}
                                <Button
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    width: '100%',
                                    alignItems: 'unset',
                                    justifyContent: 'unset',
                                  }}
                                >
                                  <Typography
                                    className={classes.txtNavbarDropDown}
                                  >
                                    Akreditasi
                                  </Typography>
                                </Button>
                                {/* </a> */}
                              </Grid>
                              <Grid item>
                                {/* <a href="https://sports.ponxx2020papua.com/"> */}
                                <Button
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    width: '100%',
                                    alignItems: 'unset',
                                    justifyContent: 'unset',
                                  }}
                                >
                                  <Typography
                                    className={classes.txtNavbarDropDown}
                                  >
                                    Disiplin Olahraga
                                  </Typography>
                                </Button>
                                {/* </a> */}
                              </Grid>
                              <Grid item>
                                {/* <a href="https://volunteer.ponxx2020papua.com/"> */}
                                <Button
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    width: '100%',
                                    alignItems: 'unset',
                                    justifyContent: 'unset',
                                  }}
                                >
                                  <Typography
                                    className={classes.txtNavbarDropDown}
                                  >
                                    Volunteer
                                  </Typography>
                                </Button>
                                {/* </a> */}
                              </Grid>
                            </div>
                          </Paper>
                        ) : (
                          false
                        )}
                      </div>
                    </ClickAwayListener>
                  </Grid>
                </Grid>

                <Grid container className={classes.containerMenu}>
                  <Grid container className={classes.containerMenu}>
                    {sitemap.map((item, index) =>
                      item.position === 'Header & Footer' ? (
                        item.type === 'Parent' ? (
                          item.category === 'Hanya Menu' ? (
                            <Grid
                              item
                              style={{ position: 'relative' }}
                              key={index}
                              className={classes.spacing}
                            >
                              <ClickAwayListener
                                onClickAway={() =>
                                  this.setState([(item.setDropDown = false)])
                                }
                              >
                                <div>
                                  <Button
                                    className={classes.btnStyle}
                                    onClick={() =>
                                      this.setState([
                                        (item.setDropDown = !item.setDropDown),
                                      ])
                                    }
                                    style={{ textDecoration: 'none' }}
                                  >
                                    {/* <img src={item.icon} alt="img" style={{width:17,height:17,marginRight:4}}/> */}
                                    <Typography
                                      className={classes.txtNavbar}
                                      style={{ textDecoration: 'none' }}
                                    >
                                      {item.title}
                                    </Typography>
                                    <ExpandMoreIcon
                                      style={{ color: '#1f313d' }}
                                    />
                                  </Button>

                                  <Paper style={dropDownStyle}>
                                    {item.setDropDown
                                      ? sitemap.map((val, index) =>
                                          val.type === 'Child' ? (
                                            val.parent === item._id ? (
                                              val.link === '' ? (
                                                <Grid
                                                  item
                                                  key={index}
                                                  onClick={() =>
                                                    this.setState([
                                                      (item.setDropDown = !item.setDropDown),
                                                    ])
                                                  }
                                                >
                                                  <Button
                                                    component={NavLink}
                                                    to={val.base_URL}
                                                    style={{
                                                      paddingTop: 10,
                                                      paddingBottom: 10,
                                                      width: '100%',
                                                      alignItems: 'unset',
                                                      justifyContent: 'unset',
                                                      textDecoration: 'none',
                                                    }}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.txtNavbarDropDown
                                                      }
                                                      style={{
                                                        textDecoration: 'none',
                                                      }}
                                                    >
                                                      {val.title}
                                                    </Typography>
                                                  </Button>
                                                </Grid>
                                              ) : (
                                                <Grid item key={index}>
                                                  <a
                                                    href={val.link}
                                                    style={{
                                                      textDecoration: 'none',
                                                    }}
                                                  >
                                                    <Button
                                                      style={{
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                        width: '100%',
                                                        alignItems: 'unset',
                                                        justifyContent: 'unset',
                                                        textDecoration: 'none',
                                                      }}
                                                    >
                                                      <Typography
                                                        className={
                                                          classes.txtNavbarDropDown
                                                        }
                                                        style={{
                                                          textDecoration:
                                                            'none',
                                                        }}
                                                      >
                                                        {val.title}
                                                      </Typography>
                                                    </Button>
                                                  </a>
                                                </Grid>
                                              )
                                            ) : null
                                          ) : null
                                        )
                                      : null}
                                  </Paper>
                                </div>
                              </ClickAwayListener>
                            </Grid>
                          ) : item.link === '' ? (
                            <Grid item className={classes.spacing}>
                              <Button
                                component={NavLink}
                                to={item.base_URL}
                                className={classes.btnStyle}
                                style={{ textDecoration: 'none' }}
                              >
                                {/* <img src={item.icon} alt="img" style={{width:17,height:17,marginRight:4}}/> */}
                                <Typography
                                  className={classes.txtNavbar}
                                  style={{ textDecoration: 'none' }}
                                >
                                  {item.title}
                                </Typography>
                              </Button>
                            </Grid>
                          ) : (
                            <Grid item className={classes.spacing}>
                              <a
                                href={item.link}
                                style={{ textDecoration: 'none' }}
                              >
                                <Button
                                  className={classes.btnStyle}
                                  style={{ textDecoration: 'none' }}
                                >
                                  {/* <img src={item.icon} alt="img" style={{width:17,height:17,marginRight:4}}/> */}
                                  <Typography
                                    className={classes.txtNavbar}
                                    style={{ textDecoration: 'none' }}
                                  >
                                    {item.title}
                                  </Typography>
                                </Button>
                              </a>
                            </Grid>
                          )
                        ) : null
                      ) : item.position === 'Header' ? (
                        item.type === 'Parent' ? (
                          item.category === 'Hanya Menu' ? (
                            <Grid
                              item
                              style={{ position: 'relative' }}
                              key={index}
                              className={classes.spacing}
                            >
                              <ClickAwayListener
                                onClickAway={() =>
                                  this.setState([(item.setDropDown = false)])
                                }
                              >
                                <div>
                                  <Button
                                    className={classes.btnStyle}
                                    onClick={() =>
                                      this.setState([
                                        (item.setDropDown = !item.setDropDown),
                                      ])
                                    }
                                    style={{ textDecoration: 'none' }}
                                  >
                                    {/* <img src={item.icon} alt="img" style={{width:17,height:17,marginRight:4}}/> */}
                                    <Typography
                                      className={classes.txtNavbar}
                                      style={{ textDecoration: 'none' }}
                                    >
                                      {item.title}
                                    </Typography>
                                    <ExpandMoreIcon
                                      style={{ color: '#1f313d' }}
                                    />
                                  </Button>
                                  <Paper style={dropDownStyle}>
                                    {item.setDropDown
                                      ? sitemap.map((val, index) =>
                                          val.type === 'Child' ? (
                                            val.parent === item._id ? (
                                              val.link === '' ? (
                                                <Grid item key={index}>
                                                  <Button
                                                    component={NavLink}
                                                    to={val.base_URL}
                                                    style={{
                                                      paddingTop: 10,
                                                      paddingBottom: 10,
                                                      width: '100%',
                                                      alignItems: 'unset',
                                                      justifyContent: 'unset',
                                                      textDecoration: 'none',
                                                    }}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.txtNavbarDropDown
                                                      }
                                                      style={{
                                                        textDecoration: 'none',
                                                      }}
                                                    >
                                                      {val.title}
                                                    </Typography>
                                                  </Button>
                                                </Grid>
                                              ) : (
                                                <Grid item>
                                                  <a
                                                    href={val.link}
                                                    style={{
                                                      textDecoration: 'none',
                                                    }}
                                                  >
                                                    <Button
                                                      style={{
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                        width: '100%',
                                                        alignItems: 'unset',
                                                        justifyContent: 'unset',
                                                        textDecoration: 'none',
                                                      }}
                                                    >
                                                      <Typography
                                                        className={
                                                          classes.txtNavbarDropDown
                                                        }
                                                        style={{
                                                          textDecoration:
                                                            'none',
                                                        }}
                                                      >
                                                        {val.title}
                                                      </Typography>
                                                    </Button>
                                                  </a>
                                                </Grid>
                                              )
                                            ) : null
                                          ) : null
                                        )
                                      : null}
                                  </Paper>
                                </div>
                              </ClickAwayListener>
                            </Grid>
                          ) : item.link === '' ? (
                            <Grid
                              item
                              className={classes.spacing}
                              key={index}
                              onClick={() =>
                                this.setState([
                                  (item.setDropDown = !item.setDropDown),
                                ])
                              }
                            >
                              <Button
                                component={NavLink}
                                to={item.base_URL}
                                className={classes.btnStyle}
                                style={{ textDecoration: 'none' }}
                              >
                                {/* <img src={item.icon} alt="img" style={{width:17,height:17,marginRight:4}}/> */}
                                <Typography
                                  className={classes.txtNavbar}
                                  style={{ textDecoration: 'none' }}
                                >
                                  {item.title}
                                </Typography>
                              </Button>
                            </Grid>
                          ) : (
                            <Grid item className={classes.spacing} key={index}>
                              <a
                                href={item.link}
                                style={{ textDecoration: 'none' }}
                              >
                                <Button
                                  className={classes.btnStyle}
                                  style={{ textDecoration: 'none' }}
                                >
                                  {/* <img src={item.icon} alt="img" style={{width:17,height:17,marginRight:4}}/> */}
                                  <Typography
                                    className={classes.txtNavbar}
                                    style={{ textDecoration: 'none' }}
                                  >
                                    {item.title}
                                  </Typography>
                                </Button>
                              </a>
                            </Grid>
                          )
                        ) : null
                      ) : null
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Toolbar>
        </AppBar>

        {/* Menu Mobile */}

        <Hidden mdUp>
          <Grid container style={{ background: '#D6261D' }}>
            <Grid item xs={3} sm={3} style={{ padding: 10 }}>
              <Button
                component={NavLink}
                to='/'
                className={classes.lemkariStyle2}
              >
                <img src={logo} alt='Logo' style={{ width: 60, height: 40 }} />
              </Button>
            </Grid>

            <Grid item xs={7} sm={7} md={5} lg={9} style={{ marginTop: 5 }}>
              <Typography
                style={{
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: 19,
                  color: 'white',
                }}
              >
                Lembaga Karate - Do Indonesia (Lemkari)
              </Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={5} lg={9} style={{ marginTop: 17 }}>
              <Button style={{}} onClick={() => this.openMenuMobile()}>
                <center>
                  <DehazeIcon style={{ fontSize: 28, color: 'white' }} />
                </center>
              </Button>
            </Grid>
            <Drawer
              anchor='top'
              open={this.state.menuMobile}
              onClose={() => this.closeMenuMobile()}
            >
              <div className={classes.fullList}>
                <div style={{ height: 60 }}>
                  <Button
                    component={NavLink}
                    to='/'
                    className={classes.btnlogoStyle}
                  >
                    <img
                      src={logo}
                      alt='Logo'
                      className={classes.imglogoStyle}
                    />
                  </Button>
                  <Grid container>
                    <img
                      src={backgroundTopNavImg}
                      alt='Logo'
                      style={{ width: '80%', height: 60 }}
                    />

                    <Button
                      onClick={() => this.closeMenuMobile()}
                      style={{ position: 'absolute', right: 10, top: 10 }}
                    >
                      <CloseIcon style={{ fontSize: 28 }} />
                    </Button>
                  </Grid>
                </div>

                {sitemap.map((item, index) =>
                  item.position === 'Header & Footer' ? (
                    item.type === 'Parent' ? (
                      item.category === 'Hanya Menu' ? (
                        <ExpansionPanel key={index}>
                          <ExpansionPanelSummary
                            expandIcon={
                              <KeyboardArrowRightIcon
                                style={{ color: 'black' }}
                              />
                            }
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Button style={btnMenuMobile}>
                              <Typography style={txtMenuMobile}>
                                {item.title}
                              </Typography>
                            </Button>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Grid container>
                              {sitemap.map((val, index) =>
                                val.type === 'Child' ? (
                                  val.parent === item._id ? (
                                    val.link === '' ? (
                                      <Button
                                        key={index}
                                        onClick={() => this.closeMenuMobile()}
                                        component={NavLink}
                                        to={val.base_URL}
                                        style={btnMenuMobile}
                                      >
                                        <Typography style={txtMenuMobileExpand}>
                                          {val.title}
                                        </Typography>
                                      </Button>
                                    ) : (
                                      <Button
                                        key={index}
                                        onClick={() => this.closeMenuMobile()}
                                        style={btnMenuMobile}
                                      >
                                        <a
                                          href={val.link}
                                          style={{ textDecoration: 'none' }}
                                        >
                                          <Typography
                                            style={txtMenuMobileExpand1}
                                          >
                                            {val.title}
                                          </Typography>
                                        </a>
                                      </Button>
                                    )
                                  ) : null
                                ) : null
                              )}
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      ) : item.link === '' ? (
                        <Button
                          onClick={() => this.closeMenuMobile()}
                          component={NavLink}
                          to={item.base_URL}
                          style={btnMenuMobile}
                        >
                          <Typography style={txtMenuMobile}>
                            {item.title}
                          </Typography>
                        </Button>
                      ) : (
                        <a href={item.link} style={{ textDecoration: 'none' }}>
                          <Button style={btnMenuMobile}>
                            <Typography style={txtMenuMobile}>
                              {item.title}
                            </Typography>
                          </Button>
                        </a>
                      )
                    ) : null
                  ) : item.position === 'Header' ? (
                    item.type === 'Parent' ? (
                      item.category === 'Hanya Menu' ? (
                        <ExpansionPanel>
                          <ExpansionPanelSummary
                            expandIcon={
                              <KeyboardArrowRightIcon
                                style={{ color: 'black' }}
                              />
                            }
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Button style={btnMenuMobile}>
                              <Typography style={txtMenuMobile}>
                                {item.title}
                              </Typography>
                            </Button>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Grid container>
                              {sitemap.map((val, index) =>
                                val.type === 'Child' ? (
                                  val.parent === item._id ? (
                                    val.link === '' ? (
                                      <Button
                                        key={index}
                                        onClick={() => this.closeMenuMobile()}
                                        component={NavLink}
                                        to={val.base_URL}
                                        style={btnMenuMobile}
                                      >
                                        <Typography style={txtMenuMobileExpand}>
                                          {val.title}
                                        </Typography>
                                      </Button>
                                    ) : (
                                      <Button key={index} style={btnMenuMobile}>
                                        <a
                                          href={val.link}
                                          style={{ textDecoration: 'none' }}
                                        >
                                          <Typography
                                            style={txtMenuMobileExpand1}
                                          >
                                            {val.title}
                                          </Typography>
                                        </a>
                                      </Button>
                                    )
                                  ) : null
                                ) : null
                              )}
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      ) : item.link === '' ? (
                        <Button
                          onClick={() => this.closeMenuMobile()}
                          component={NavLink}
                          to={item.base_URL}
                          style={btnMenuMobile}
                        >
                          <Typography style={txtMenuMobile}>
                            {item.title}
                          </Typography>
                        </Button>
                      ) : (
                        <a
                          href={item.link}
                          key={index}
                          style={{ textDecoration: 'none' }}
                        >
                          <Button style={btnMenuMobile}>
                            <Typography style={txtMenuMobile}>
                              {item.title}
                            </Typography>
                          </Button>
                        </a>
                      )
                    ) : null
                  ) : null
                )}
              </div>
            </Drawer>
          </Grid>
        </Hidden>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

// ===================> Style Mobile Responsive <==========================

const styles = theme => ({
  btnStyle: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    textDecoration: 'none',
  },

  containerMenu: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '3%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '12.3%',
    },
    flexDirection: 'row',
    height: 36,
    paddingTop: 3,
  },

  spacing: {
    [theme.breakpoints.down('md')]: {
      marginRight: 9,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 16,
    },
  },

  txtNavbar: {
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
    },
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    color: '#1f313d',
    color: '#1f313d',
  },

  txtNavbarDropDown: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 13,
    },
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'none',
    color: '#1f313d',
  },

  txtTiket: {
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
    },
    color: '#bf272b',
    textTransform: 'none',
  },

  txtPendaftaran: {
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
    },
    color: '#ffffff',
    textTransform: 'none',
    fontWeight: 200,
  },

  btnlogoStyle: {
    [theme.breakpoints.down('md')]: {
      width: 160,
      height: 70,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 80,
    },
    backgroundColor: '#fff',
    borderRadius: 0,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    position: 'absolute',
    left: 40,
  },

  lemkariStyle: {
    [theme.breakpoints.down('md')]: {
      width: 50,
      height: 90,
    },

    [theme.breakpoints.up('lg')]: {
      width: 80,
      height: 60,
    },
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 60,
    },

    backgroundColor: '#fff',
    borderRadius: 0,
    paddingLeft: 5,
    paddingTop: 11,
    paddingBottom: 10,
    paddingRight: 5,

    left: 40,
  },

  lemkariStyle2: {
    [theme.breakpoints.down('md')]: {
      width: 90,
      height: 70,
    },

    [theme.breakpoints.up('lg')]: {
      width: 90,
      height: 80,
    },
    [theme.breakpoints.down('sm')]: {
      width: 27,
      height: 50,
    },

    backgroundColor: '#fff',
    borderRadius: 0,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
  },

  imglogoStyle: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
    },
    [theme.breakpoints.up('md')]: {
      height: 70,
    },
    width: '90%',
    height: 70,
    position: 'absolute',
  },
  fullList: {
    height: '100%',
  },
  headerBox: {
    padding: 0,
    height: 'auto',
    boxShadow: '2px 2px 5px #888888',
    backgroundColor: '#ffffff',
    color: '#000000',
  },
});

const txtMenuMobile = {
  position: 'absolute',
  left: 30,
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 300,
};

const txtMenuMobileExpand = {
  position: 'absolute',
  left: 45,
  textTransform: 'none',
  fontSize: 13,
  fontWeight: 200,
};

const txtMenuMobileExpand1 = {
  position: 'absolute',
  left: 45,
  textTransform: 'none',
  fontSize: 13,
  bottom: 15,
  color: '#000000',
  fontWeight: 200,
};

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    borderBottom: 'none',
    marginBottom: -1,
    height: 30,
    paddingLeft: 0,
    '&$expanded': {
      height: 30,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
    paddingLeft: 0,
  },
}))(MuiExpansionPanelDetails);

const btnMenuMobile = {
  width: '100%',
  height: 51,
};

// =================================================================

const toolbar = {
  padding: 0,
  minHeight: 0,
  backgroundColor: '#ffffff',
  color: 'black',
};

const dropDownStylePendaftaran = {
  position: 'absolute',
  top: 37,
  right: 0,
  left: 0,
  zIndex: 10,
};

const dropDownStyle = {
  position: 'absolute',
  top: 37,
  right: 0,
  left: 0,
};

export default withStyles(styles)(Header);
